import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
export const protobufPackage = "snapchat.lenses";
export interface BitmojiUserInfo {
    avatarId: string;
    selfieId: string;
}
export interface FriendUserInfo {
    friendshipStart: Date | undefined;
    lastInteraction: Date | undefined;
    streak: number;
}
export interface UserData {
    userId: string;
    username: string;
    birthdate: string;
    displayName: string;
    countrycode: string;
    score: number;
    bitmojiInfo: BitmojiUserInfo | undefined;
    friendInfo: FriendUserInfo | undefined;
    zodiac: UserData_Zodiac;
}
export enum UserData_Zodiac {
    Invalid = "Invalid",
    Aquarius = "Aquarius",
    Aries = "Aries",
    Cancer = "Cancer",
    Capricorn = "Capricorn",
    Gemini = "Gemini",
    Leo = "Leo",
    Libra = "Libra",
    Pisces = "Pisces",
    Sagittarius = "Sagittarius",
    Scorpio = "Scorpio",
    Taurus = "Taurus",
    Virgo = "Virgo",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function userData_ZodiacFromJSON(object: any): UserData_Zodiac {
    switch (object) {
        case 0:
        case "Invalid":
            return UserData_Zodiac.Invalid;
        case 1:
        case "Aquarius":
            return UserData_Zodiac.Aquarius;
        case 2:
        case "Aries":
            return UserData_Zodiac.Aries;
        case 3:
        case "Cancer":
            return UserData_Zodiac.Cancer;
        case 4:
        case "Capricorn":
            return UserData_Zodiac.Capricorn;
        case 5:
        case "Gemini":
            return UserData_Zodiac.Gemini;
        case 6:
        case "Leo":
            return UserData_Zodiac.Leo;
        case 7:
        case "Libra":
            return UserData_Zodiac.Libra;
        case 8:
        case "Pisces":
            return UserData_Zodiac.Pisces;
        case 9:
        case "Sagittarius":
            return UserData_Zodiac.Sagittarius;
        case 10:
        case "Scorpio":
            return UserData_Zodiac.Scorpio;
        case 11:
        case "Taurus":
            return UserData_Zodiac.Taurus;
        case 12:
        case "Virgo":
            return UserData_Zodiac.Virgo;
        case -1:
        case "UNRECOGNIZED":
        default:
            return UserData_Zodiac.UNRECOGNIZED;
    }
}
export function userData_ZodiacToNumber(object: UserData_Zodiac): number {
    switch (object) {
        case UserData_Zodiac.Invalid:
            return 0;
        case UserData_Zodiac.Aquarius:
            return 1;
        case UserData_Zodiac.Aries:
            return 2;
        case UserData_Zodiac.Cancer:
            return 3;
        case UserData_Zodiac.Capricorn:
            return 4;
        case UserData_Zodiac.Gemini:
            return 5;
        case UserData_Zodiac.Leo:
            return 6;
        case UserData_Zodiac.Libra:
            return 7;
        case UserData_Zodiac.Pisces:
            return 8;
        case UserData_Zodiac.Sagittarius:
            return 9;
        case UserData_Zodiac.Scorpio:
            return 10;
        case UserData_Zodiac.Taurus:
            return 11;
        case UserData_Zodiac.Virgo:
            return 12;
        default:
            return 0;
    }
}
export interface UserDataList {
    userData: UserData[];
}
function createBaseBitmojiUserInfo(): BitmojiUserInfo {
    return { avatarId: "", selfieId: "" };
}
export const BitmojiUserInfo = {
    encode(message: BitmojiUserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.avatarId !== "") {
            writer.uint32(10).string(message.avatarId);
        }
        if (message.selfieId !== "") {
            writer.uint32(18).string(message.selfieId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BitmojiUserInfo {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBitmojiUserInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.avatarId = reader.string();
                    break;
                case 2:
                    message.selfieId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BitmojiUserInfo>, I>>(object: I): BitmojiUserInfo {
        const message = createBaseBitmojiUserInfo();
        message.avatarId = object.avatarId ?? "";
        message.selfieId = object.selfieId ?? "";
        return message;
    },
};
function createBaseFriendUserInfo(): FriendUserInfo {
    return { friendshipStart: undefined, lastInteraction: undefined, streak: 0 };
}
export const FriendUserInfo = {
    encode(message: FriendUserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.friendshipStart !== undefined) {
            Timestamp.encode(toTimestamp(message.friendshipStart), writer.uint32(10).fork()).ldelim();
        }
        if (message.lastInteraction !== undefined) {
            Timestamp.encode(toTimestamp(message.lastInteraction), writer.uint32(18).fork()).ldelim();
        }
        if (message.streak !== 0) {
            writer.uint32(24).uint32(message.streak);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): FriendUserInfo {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFriendUserInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.friendshipStart = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.lastInteraction = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.streak = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<FriendUserInfo>, I>>(object: I): FriendUserInfo {
        const message = createBaseFriendUserInfo();
        message.friendshipStart = object.friendshipStart ?? undefined;
        message.lastInteraction = object.lastInteraction ?? undefined;
        message.streak = object.streak ?? 0;
        return message;
    },
};
function createBaseUserData(): UserData {
    return {
        userId: "",
        username: "",
        birthdate: "",
        displayName: "",
        countrycode: "",
        score: 0,
        bitmojiInfo: undefined,
        friendInfo: undefined,
        zodiac: UserData_Zodiac.Invalid,
    };
}
export const UserData = {
    encode(message: UserData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.userId !== "") {
            writer.uint32(10).string(message.userId);
        }
        if (message.username !== "") {
            writer.uint32(18).string(message.username);
        }
        if (message.birthdate !== "") {
            writer.uint32(26).string(message.birthdate);
        }
        if (message.displayName !== "") {
            writer.uint32(34).string(message.displayName);
        }
        if (message.countrycode !== "") {
            writer.uint32(42).string(message.countrycode);
        }
        if (message.score !== 0) {
            writer.uint32(56).uint64(message.score);
        }
        if (message.bitmojiInfo !== undefined) {
            BitmojiUserInfo.encode(message.bitmojiInfo, writer.uint32(66).fork()).ldelim();
        }
        if (message.friendInfo !== undefined) {
            FriendUserInfo.encode(message.friendInfo, writer.uint32(74).fork()).ldelim();
        }
        if (message.zodiac !== UserData_Zodiac.Invalid) {
            writer.uint32(80).int32(userData_ZodiacToNumber(message.zodiac));
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): UserData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.birthdate = reader.string();
                    break;
                case 4:
                    message.displayName = reader.string();
                    break;
                case 5:
                    message.countrycode = reader.string();
                    break;
                case 7:
                    message.score = longToNumber(reader.uint64() as Long);
                    break;
                case 8:
                    message.bitmojiInfo = BitmojiUserInfo.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.friendInfo = FriendUserInfo.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.zodiac = userData_ZodiacFromJSON(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<UserData>, I>>(object: I): UserData {
        const message = createBaseUserData();
        message.userId = object.userId ?? "";
        message.username = object.username ?? "";
        message.birthdate = object.birthdate ?? "";
        message.displayName = object.displayName ?? "";
        message.countrycode = object.countrycode ?? "";
        message.score = object.score ?? 0;
        message.bitmojiInfo =
            object.bitmojiInfo !== undefined && object.bitmojiInfo !== null
                ? BitmojiUserInfo.fromPartial(object.bitmojiInfo)
                : undefined;
        message.friendInfo =
            object.friendInfo !== undefined && object.friendInfo !== null
                ? FriendUserInfo.fromPartial(object.friendInfo)
                : undefined;
        message.zodiac = object.zodiac ?? UserData_Zodiac.Invalid;
        return message;
    },
};
function createBaseUserDataList(): UserDataList {
    return { userData: [] };
}
export const UserDataList = {
    encode(message: UserDataList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.userData) {
            UserData.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): UserDataList {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserDataList();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userData.push(UserData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<UserDataList>, I>>(object: I): UserDataList {
        const message = createBaseUserDataList();
        message.userData = object.userData?.map((e) => UserData.fromPartial(e)) || [];
        return message;
    },
};
declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function toTimestamp(date: Date): Timestamp {
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t: Timestamp): Date {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
