import { memoize } from "../common/memoize";
import { isRecord } from "../common/typeguards";
import environment from "../environment.json";
import lensCoreWasm from "../lensCoreWasmVersions.json";

type BrandArray = Array<{ brand: string; version: string }>;

interface NavigatorUAData {
    brands: BrandArray;
    mobile: boolean;
    platform: string;
}

export type ConnectionType = "bluetooth" | "cellular" | "ethernet" | "none" | "wifi" | "wimax" | "other" | "unknown";

declare global {
    interface Navigator {
        userAgentData?: NavigatorUAData;
        connection?: {
            // This currently has extremely limited support in browsers.
            // https://wicg.github.io/netinfo/#dom-networkinformation-type
            type?: ConnectionType;
        };
    }
}

/**
 * Some user agents may not properly implement the NavigatorUAData interface, so we have to do our own validation here
 * to make sure we're dealing with a well-formed value.
 */
function isNavigatorUAData(value: unknown): value is NavigatorUAData {
    return (
        isRecord(value) &&
        Array.isArray(value["brands"]) &&
        value["brands"].every((brand) => {
            return isRecord(brand) && typeof brand["brand"] === "string" && typeof brand["version"] === "string";
        }) &&
        typeof value["mobile"] === "boolean" &&
        typeof value["platform"] === "string"
    );
}

/**
 * In the future, we may invest in more robust device-detection (e.g. a UA string database), but for now this will give
 * us some sense of device usage.
 */
function parseDeviceModel(userAgent: string) {
    // from user agent like "(Linux; Android 11; Pixel 2)" extact "Pixel 2"
    const userAgentWithModel = userAgent.match(/;[^;]+?;([^\)]+?)\)/);

    if (userAgentWithModel) {
        return userAgentWithModel[1].trim();
    }

    // from user agent like "... (iPad; CPU OS 15_1 like Mac OS X) ..." extract "iPad"
    const userAgentWithModel2 = userAgent.match(/\(([^;]+);/);

    if (userAgentWithModel2) {
        return userAgentWithModel2[1].trim();
    }

    return "unknown";
}

/**
 * The origin may be useful to identify the running application (e.g. to attribute metrics).
 *
 * We need to handle cases in which we run inside a child browsing context (e.g. an iframe), which may not have a
 * hostname – in this case we'll check each ancestor context until we find a valid hostname.
 */
function parseOrigin(): string {
    if (location.hostname !== "") return location.hostname;

    // Firefox does not implement ancestorOrigins, so we need a fallback.
    // Context here: https://github.com/whatwg/html/issues/1918
    const possibleOrigins =
        location.ancestorOrigins === undefined && typeof window !== "undefined"
            ? [window.parent.origin, window.top?.origin ?? ""]
            : location.ancestorOrigins ?? [];

    for (let origin of possibleOrigins) {
        try {
            origin = new URL(origin).hostname;
            if (origin) return origin;
        } catch (_) {}
    }

    return "unknown";
}

/* eslint-disable max-len */
/**
 * The backend defines the allowed list of known OSes which will pass their RegEx test when found in our custom
 * CameraKitWeb userAgent string.
 *
 * See https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
 */
/* eslint-enable */
type KnownPlatform = "macos" | "windows" | "linux" | "android" | "ios" | "ipados" | "unknown";
function parseOSName(userAgent: string): KnownPlatform {
    const knownPlatforms = new Map<string, KnownPlatform>([
        ["android", "android"],
        ["linux", "linux"],
        ["iphone os", "ios"],
        ["ipad", "ipados"],
        ["mac os", "macos"],
        ["macos", "macos"],
        ["windows", "windows"],
    ]);

    const normalizedUserAgent = userAgent.toLowerCase();
    for (const [match, platform] of knownPlatforms.entries()) {
        if (normalizedUserAgent.includes(match)) return platform;
    }
    return "unknown";
}

/**
 * Parse the OS (a.k.a. platform) version.
 *
 * From limited testing, this seems to often produce incorrect results – the userAgent string does not typically include
 * the actual OS version.
 *
 * Better results could be obtained from [NavigatorUAData.getHighEntropyValues]
 * (https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/getHighEntropyValues), but this presents two
 * problems: 1) it's currently only supported on Chrome and 2) browsers may prompt the user for permission to share
 * this information.
 *
 * So, at least for now, we'll be satisfied with the incorrect version number.
 */
function parseOSVersion(userAgent: string) {
    // possible platform version values inside of user agent string
    // " 11;"
    // " 10_15_7)"
    // " 13_5_1 "
    // " 10.0;"
    // " 15_1 "
    const versionMatch = userAgent.match(/\s([\d][\d_.]*[\d])(;|\)|\s)/);

    if (versionMatch != null) {
        return versionMatch[1].replace(/_/g, ".");
    }

    return "";
}

/**
 * Some browsers (e.g. Safari) do not support the `Navigator.userAgentData` API. We'll attempt a sort of polyfill by
 * parsing the data found in [NavigatorUAData](https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData) from
 * the raw user agent string.
 */
function parseUserAgentData(userAgent: string): NavigatorUAData {
    let brand: BrandArray[number];

    // Parse UA string for Chromium-based browsers (e.g. Chrome, Edge)
    if (/Chrome/.test(userAgent)) {
        const versionMatch = userAgent.match(/Chrome\/([\d.]+)/);
        brand = {
            brand: "Chrome",
            version: versionMatch !== null ? versionMatch[1] : "unknown",
        };
    }

    // Parse UA string for Safari (very important for this to only be done if Chrome is not found – Chrome userAgent
    // strings will contain "Safari")
    else if (/Safari/.test(userAgent)) {
        let versionMatch = userAgent.match(/Version\/([\d.]+)/);
        if (versionMatch === null) versionMatch = userAgent.match(/Safari\/([\d.]+)/);
        brand = {
            brand: "Safari",
            version: versionMatch !== null ? versionMatch[1] : "unknown",
        };
    }

    // Parse UA for unknown browser.
    // TODO: will be changed, default value support should be added on a COF server side.
    else {
        brand = {
            brand: "Firefox",
            version: "0",
        };
    }

    // We're not using `mobile` for anything, and we have no consistent way to determine this from the UA string.
    // We'll set it to false, but this should not be used – instead, we'll need to rely on more sophisticated methods
    // (e.g. a userAgent database) to determine actual device.
    const mobile = false;
    const platform = parseOSName(userAgent);

    return {
        brands: [brand],
        mobile,
        platform,
    };
}

/* eslint-disable max-len */
/**
 * The `brands` array found in [NavigatorUAData](https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData) is
 * intentionally designed to discourage standardized processing. This method of extracting brand information will be
 * inherently brittle, and it relies on us matching some well-known brands.
 *
 * For more detail from the spec:
 * See https://wicg.github.io/ua-client-hints/#monkeypatch-html-windoworworkerglobalscope
 * And https://wicg.github.io/ua-client-hints/#grease
 *
 * We also must match the list of known brands allowed by the backend, defined here:
 * https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
 */
/* eslint-enable */
type KnownBrand = "Chrome" | "Safari" | "Firefox";
function normalizeBrands(brands: BrandArray): BrandArray {
    const knownBrands = new Map<string, KnownBrand>([
        ["Google Chrome", "Chrome"],
        ["Chrome", "Chrome"],
        ["Chromium", "Chrome"],
        ["Firefox", "Firefox"],
        ["Microsoft Edge", "Chrome"],
        ["Safari", "Safari"],
    ]);

    const normalizedBrands = brands
        .filter(({ brand }) => knownBrands.has(brand))
        .map((brand) => {
            return {
                // Safety: we've filtered out brands which do not appear as keys in `knownBrands`, so this cannot return
                // undefined.
                brand: knownBrands.get(brand.brand)!,
                version: brand.version,
            };
        });

    // TODO: default "unknown" value should be added on COF server side. For now we'll use Firefox.
    if (normalizedBrands.length === 0) return [{ brand: "Firefox", version: "0" }];
    return normalizedBrands;
}

/* eslint-disable max-len */
/**
 * We must ensure the data we get from `navigator.userAgentData` is normalized to match what our backend expects to
 * see in our custom CameraKitWeb userAgent string.
 *
 * This string is defined here:
 * https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
 */
/* eslint-enable */
function normalizeUserAgentData(userAgentData: NavigatorUAData): NavigatorUAData {
    return {
        brands: normalizeBrands(userAgentData.brands),
        mobile: userAgentData.mobile,
        platform: parseOSName(userAgentData.platform),
    };
}

/** @internal */
export interface PlatformInfo {
    sdkShortVersion: string;
    sdkLongVersion: string;
    lensCore: {
        version: string;
        buildNumber: string;
        baseUrl: string;
    };
    browser: { brand: string; version: string };
    osName: string;
    osVersion: string;
    deviceModel: string;
    locale: string;
    fullLocale: string;
    origin: string;
    connectionType: ConnectionType;
}

/** @internal */
export const getPlatformInfo = memoize(function getPlatformIno(): PlatformInfo {
    // [NavigatorUAData](https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData) is currently only
    // available on Chromium-based browsers – it's nice because it gives us clear, well-documented information. But
    // we'll have to fallback to parsing the userAgent string when it's not available.
    const userAgent = navigator.userAgent;
    const userAgentData = isNavigatorUAData(navigator.userAgentData)
        ? normalizeUserAgentData(navigator.userAgentData)
        : parseUserAgentData(userAgent);

    const osVersion = parseOSVersion(userAgent);
    const deviceModel = parseDeviceModel(userAgent);

    // Remove any `-prerelease` or `+buildmetadata` portions from the semver string.
    const sdkShortVersion = environment.PACKAGE_VERSION.replace(/[-+]\S+$/, "");

    const locale = navigator.language;
    // The full locale string includes all the languages with qvalues -- this is needed for some API calls.
    // More on qvalues: https://developer.mozilla.org/en-US/docs/Glossary/Quality_values
    const fullLocale =
        (navigator.languages ?? [])
            .map((lang, index) => {
                const qvalue = Math.max(0, (10 - index) / 10);
                return `${lang};q=${qvalue.toFixed(1)}`;
            })
            .join(", ") || locale;

    return {
        sdkShortVersion,
        sdkLongVersion: environment.PACKAGE_VERSION,
        lensCore: lensCoreWasm,
        // In cases where we've parsed the userAgent string to find the brand, there will only ever be a single brand –
        // in browsers which support NavigatorUAData there could be more than one (e.g. Chrome and Chromium), but they
        // should be equivalent for our purposes -- either way we're okay just picking the first one.
        browser: userAgentData.brands[0],
        osName: userAgentData.platform,
        osVersion,
        deviceModel,
        locale,
        fullLocale,
        origin: parseOrigin(),
        connectionType: navigator.connection?.type ?? "unknown",
    };
});
