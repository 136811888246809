import { errorLoggingDecorator } from "../logger/errorLoggingDecorator";
import { LensCore } from "../lens-core-module";
import { getLogger } from "../logger/logger";
import { LensPerformanceMeasurement } from "./LensPerformanceMeasurement";

const logger = getLogger("RenderingMetrics");
const log = errorLoggingDecorator(logger);

/**
 * Use to measure lens rendering performance.
 *
 * The {@link LensPerformanceMetrics.beginMeasurement} method is used to start measuring rendering performance. After
 * a measurement has begun, performance metrics can be read using {@link LensPerformanceMeasurement}.
 *
 * @example
 * ```ts
 * const measurement = cameraKitSession.metrics.beginMeasurement()
 * // some time later
 * console.log(measurement.measure())
 * ```
 *
 * @category Rendering
 * @category Metrics
 */
export class LensPerformanceMetrics {
    private readonly measurementInstances: Set<LensPerformanceMeasurement>;

    /** @internal */
    constructor(private lensCore: LensCore) {
        this.measurementInstances = new Set();
        this.lensCore
            .setOnFrameProcessedCallback({
                onFrameProcessed: ({ processingTimeMs }) => {
                    try {
                        for (const measurement of this.measurementInstances.values()) {
                            measurement.update(processingTimeMs);
                        }
                    } catch (error) {
                        logger.error(error);
                    }
                },
            })
            .catch((error) =>
                logger.error(`Failed registering setOnFrameProcessedCallback with error: ${error.message}`)
            );
    }

    /**
     * Begin a measurement window, during which time rendering metrics will be gathered.
     *
     * @returns A {@link LensPerformanceMeasurement} representing an ongoing measurement of aggregated rendering
     * metrics, from which specific metrics can be obtained.
     */
    @log
    beginMeasurement(): LensPerformanceMeasurement {
        return new LensPerformanceMeasurement(this.measurementInstances);
    }
}
