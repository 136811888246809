// NOTE: All errors thrown in the CameraKit package have to be defined here.
// Error types are not infered from error factories for API doc purposes and consistency.

/**
 * Helper type to extract type generic parameter.
 */
type ExtractName<P> = P extends NamedError<infer T> ? T : never;

/**
 * All errors are expected to have "Error" suffix.
 */
type ErrorName = `${string}Error`;

type NamedError<Name extends ErrorName> = Error & { name: Name };

/**
 * Removes the top trace line from the stack.
 */
function cleanErrorStack(stack: string): string {
    const [first, _, ...rest] = stack.split("\n");
    return [first, ...rest].join("\n");
}

/**
 * Creates error factory that ensures Error.prototype.name field value.
 *
 * NOTE: exported only for unit tests.
 *
 * @param name Error name.
 * @returns Error factory function.
 * @internal
 */
export function namedError<
    // default to never to ensure the type argument is specified
    TError extends NamedError<TName> = never,
    // default to provided error name to make this type argument optional to reduce boilerplate
    TName extends ErrorName = ExtractName<TError>
>(name: TName) {
    return (message: string, cause?: unknown): TError => {
        const error = new Error(message, { cause });
        error.name = name;
        error.stack = error.stack && cleanErrorStack(error.stack);
        // Safety: we set name above and therefore sure the type of error is correct
        return error as TError;
    };
}

export type LegalError = NamedError<"LegalError">;
/** @internal */
export const legalError = namedError<LegalError>("LegalError");

export type LensContentValidationError = NamedError<"LensContentValidationError">;
/** @internal */
export const lensContentValidationError = namedError<LensContentValidationError>("LensContentValidationError");

export type LensError = NamedError<"LensError">;
/** @internal */
export const lensError = namedError<LensError>("LensError");

export type CameraKitSourceError = NamedError<"CameraKitSourceError">;
/** @internal */
export const cameraKitSourceError = namedError<CameraKitSourceError>("CameraKitSourceError");

/**
 * The error triggered when a lens prompts the user to select an image, but the image fails to be successfully delivered
 * to the lens.
 */
export type LensImagePickerError = NamedError<"LensImagePickerError">;
/** @internal */
export const lensImagePickerError = namedError<LensImagePickerError>("LensImagePickerError");

export type CacheKeyNotFoundError = NamedError<"CacheKeyNotFoundError">;
/** @internal */
export const cacheKeyNotFoundError = namedError<CacheKeyNotFoundError>("CacheKeyNotFoundError");

/**
 * Thrown by {@link bootstrapCameraKit} if provided configuration is invalid.
 *
 * @category Bootstrapping and Configuration
 */
export type ConfigurationError = NamedError<"ConfigurationError">;
/** @internal */
export const configurationError = namedError<ConfigurationError>("ConfigurationError");

export type WebGLError = NamedError<"WebGLError">;
/** @internal */
export const webGLError = namedError<WebGLError>("WebGLError");

export type BenchmarkError = NamedError<"BenchmarkError">;
/** @internal */
export const benchmarkError = namedError<BenchmarkError>("BenchmarkError");

/**
 * Thrown by {@link bootstrapCameraKit} when the current platform is not supported by CameraKit.
 *
 * This can happen if the browser doesn't support a required feature (e.g. WebGL).
 *
 * @category Bootstrapping and Configuration
 */
export type PlatformNotSupportedError = NamedError<"PlatformNotSupportedError">;
/** @internal */
export const platformNotSupportedError = namedError<PlatformNotSupportedError>("PlatformNotSupportedError");

/**
 * This error occurs if a Lens is unable to continue rendering.
 *
 * If this error occurs, Camera Kit automatically removes the Lens from the session.
 * It's always a good idea to handle this error and update the user experience accordingly.
 * For example, you could remove the faulty Lens from your Lens selection UI.
 *
 * ```ts
 * cameraKitSession.events.addEventListener('error', ({ detail }) => {
 *   if (detail.error.name === 'LensExecutionError') {
 *     console.log(`Lens ${detail.lens.name} encountered an error and was removed. Please pick a different lens.`)
 *   }
 * })
 * ```
 */
export type LensExecutionError = NamedError<"LensExecutionError">;
/** @internal */
export const lensExecutionError = namedError<LensExecutionError>("LensExecutionError");

/**
 * This error occurs when a session becomes inoperable.
 *
 * It's always a good idea to handle this error and update the user experience accordingly.
 * For example, you could show a message to a user.
 *
 * ```ts
 * cameraKitSession.events.addEventListener('error', ({ detail }) => {
 *   if (detail.error.name === 'LensAbortError') {
 *     console.log(`Camera Kit encountered an unrecoverable error and became inoperable. Please refresh the page.`)
 *   }
 * })
 * ```
 */
export type LensAbortError = NamedError<"LensAbortError">;
/** @internal */
export const lensAbortError = namedError<LensAbortError>("LensAbortError");

/**
 * Error thrown when LensCore asked to store lens data, but CameraKit failed storing that.
 */
export type PersistentStoreError = NamedError<"PersistentStoreError">;
/** @internal */
export const persistentStoreError = namedError<PersistentStoreError>("PersistentStoreError");

/**
 * Error thrown when LensCore asked to provide an asset, but CameraKit failed providing that.
 */
export type LensAssetError = NamedError<"LensAssetError">;
/** @internal */
export const lensAssetError = namedError<LensAssetError>("LensAssetError");

/**
 * Thrown by {@link bootstrapCameraKit} if an error occurs during SDK initializion or while downloading the render
 * engine WebAssembly.
 *
 * @category Bootstrapping and Configuration
 */
export type BootstrapError = NamedError<"BootstrapError">;
/** @internal */
export const bootstrapError = namedError<BootstrapError>("BootstrapError");
