import "reflect-metadata";
import { Guard } from "./typeguards";

const predicateMetadataKey = Symbol("validate");

// A map of primitive types accoring to
/* eslint-disable max-len */
// http://blog.wolksoftware.com/decorators-metadata-reflection-in-typescript-from-novice-to-expert-part-4#3-basic-type-serialization_1
const primitiveMap: Record<string, StringConstructor | NumberConstructor | BooleanConstructor> = {
    string: String,
    number: Number,
    boolean: Boolean,
};

const ordinalSuffixMap: Record<number, string> = {
    1: "st",
    2: "nd",
    3: "rd",
};

function getArgumentInfo(target: Object, methodName: string | symbol, argumentIndex: number, arg: unknown) {
    let argString;
    try {
        argString = JSON.stringify(arg);
    } catch {
        argString = String(arg);
    }
    return {
        argPosition: `${argumentIndex + 1}${ordinalSuffixMap[argumentIndex + 1] ?? "th"}`,
        methodPath: `${getTypeName(target)}.${String(methodName)}()`,
        argTypeName: getTypeName(arg),
        argString,
    };
}

export function guard<T>(predicate: Guard<T>) {
    return (target: Object, propertyKey: string | symbol, parameterIndex: number) => {
        let existingPredicates: Array<Guard<any>> =
            Reflect.getOwnMetadata(predicateMetadataKey, target, propertyKey) || [];
        existingPredicates[parameterIndex] = predicate;
        Reflect.defineMetadata(predicateMetadataKey, existingPredicates, target, propertyKey);
    };
}

export function validate(target: Object, key: string | symbol, descriptor: PropertyDescriptor): void {
    const method = descriptor.value!;
    const types = Reflect.getMetadata("design:paramtypes", target, key);
    const predicates = Reflect.getMetadata(predicateMetadataKey, target, key);
    descriptor.value = function (...args: unknown[]) {
        for (const [i, type] of types.entries()) {
            let arg = Array.isArray(args) && args[i];

            if (!!predicates && predicates[i] && !predicates[i](arg)) {
                const { methodPath, argPosition, argString } = getArgumentInfo(target, key, i, arg);
                throw new Error(
                    `The ${argPosition} argument to ${methodPath} method has an invalid value: ${argString}.`
                );
            }
            if (arg === undefined || arg === null) {
                // TODO: is there a way to check for nullable parameter?
                break;
            }
            if (!isValueOfType(arg, type)) {
                const { methodPath, argPosition, argTypeName } = getArgumentInfo(target, key, i, arg);
                throw new Error(
                    `The ${argPosition} argument to ${methodPath} method is of type ` +
                        `${argTypeName}, which is not assignable to parameter of type ${type.name}.`
                );
            }
        }
        return method.apply(this, arguments);
    };
}

/**
 * Checks whether given value is assignable to provided type.
 */
export function isValueOfType(value: unknown, type: any) {
    if (value instanceof type) {
        return true;
    }
    // test for primitive value
    const isPrimitive = value !== Object(value);
    return isPrimitive && primitiveMap[typeof value] === type;
}

/* eslint-disable max-len */
/**
 * Returns type string of a value. It mostly mimics the behavior of typeof, but for non-primitives
 * (i.e. objects and functions), it returns a more granular type name where possible. Source:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof#custom_method_that_gets_a_more_specific_type
 */
export function getTypeName(value: unknown): string {
    if (value === null) {
        return "null";
    }

    const baseType = typeof value;
    // Primitive types
    if (!["object", "function"].includes(baseType)) {
        return baseType;
    }

    // Safety: at this point null and undefined values are already handled
    const nonNullValue = value!;

    // Symbol.toStringTag often specifies the "display name" of the
    // object's class. It's used in Object.prototype.toString().
    // Safety: cast to an object with Symbol.toStringTag key in order to check for its existance.
    const tag = (nonNullValue as { [Symbol.toStringTag]?: string })[Symbol.toStringTag];
    if (typeof tag === "string") {
        return tag;
    }

    // If it's a function whose source code starts with the "class" keyword
    if (baseType === "function" && Function.prototype.toString.call(nonNullValue).startsWith("class")) {
        return "class";
    }

    // The name of the constructor; for example `Array`, `GeneratorFunction`,
    // `Number`, `String`, `Boolean` or `MyCustomClass`
    const className = nonNullValue.constructor.name;
    if (typeof className === "string" && className !== "") {
        return className;
    }

    // At this point there's no robust way to get the type of value,
    // so we use the base implementation.
    return baseType;
}
