import { isState } from "@snap/state-management";
import { filter, map, Observable, scan, Subject, takeUntil } from "rxjs";
import { entries } from "../../common/entries";
import { ensureError } from "../../common/errorHelpers";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { logEntriesFactory } from "../../logger/logEntries";
import { LogEntry, logLevelMap } from "../../logger/logger";
import { LensState } from "../../session/lensState";
import { MetricsEventTarget, metricsEventTargetFactory } from "../metricsEventTarget";
import {
    operationalMetricReporterFactory,
    OperationalMetricsReporter,
} from "../operational/operationalMetricsReporter";

const logMethods = entries(logLevelMap).map(([level]) => level);

// How many log entries to include as the error context
const maxBufferedEntries = 15;
const contextSeparator = "\n\n----------------- Context -----------------\n\n";
const methodLength = logMethods.reduce((max, method) => Math.max(max, method.length), 0);

interface RepeatableLogEntry {
    entry: LogEntry;
    count: number;
    lastTime: Date;
}

interface EntriesBuffer {
    /**
     * LogEntries grouped by their message.
     */
    entries: RepeatableLogEntry[];
    /**
     * The recent log entry.
     */
    recent: LogEntry;
}

export function getContextString(logEntries: RepeatableLogEntry[]) {
    const result = [];
    for (const { entry, count, lastTime } of logEntries) {
        const time = entry.time.toISOString();
        const method = entry.level.padStart(methodLength);
        const messages = entry.messages.map(prettyPrintMessage).join(" ");
        let dupSuffix =
            count > 1 ? ` (Repeated ${count} times with the last occurrence at ${lastTime.toISOString()})` : "";

        result.push(`${time} [${entry.module}] ${method}: ${messages}${dupSuffix}`);
    }
    return result.join("\n");
}

/**
 * Pretty print a log message.
 */
function prettyPrintMessage(message: unknown): string {
    if (message instanceof Error) return stringifyError(message);
    if (message instanceof Date) return message.toISOString();
    return message + "";
}

/**
 * Returns an error message for a given error, and also appends the error message of any nested error, if one exists.
 * @param error Error to stringify.
 * @returns Error message including nested error messages.
 */
function stringifyError(error: Error): string {
    const cause = error.cause ? `; Caused by ${stringifyError(ensureError(error.cause))}` : "";
    return `${error.name}: ${error.message}${cause}`;
}

export function reportExceptionToBlizzard(
    logEntries: Observable<LogEntry>,
    metricsEventTarget: MetricsEventTarget,
    reporter: OperationalMetricsReporter,
    lensState?: LensState
) {
    logEntries
        .pipe(
            scan<LogEntry, EntriesBuffer>(
                ({ entries }, newEntry) => {
                    const lastEntry = entries[entries.length - 1];
                    const isNewEntryRepeated =
                        lastEntry &&
                        lastEntry.entry.messages.join() === newEntry.messages.join() &&
                        lastEntry.entry.level === newEntry.level;
                    if (isNewEntryRepeated) {
                        lastEntry.count += 1;
                        lastEntry.lastTime = newEntry.time;
                    } else {
                        entries.push({
                            entry: newEntry,
                            count: 1,
                            lastTime: newEntry.time,
                        });
                    }
                    return {
                        entries: entries.slice(-maxBufferedEntries),
                        recent: newEntry,
                    };
                },
                // Start with a dummy recent entry -- it gets overridden each time we handle a log entry.
                { entries: [], recent: { time: new Date(), module: "any", level: "debug", messages: [] } }
            ),
            filter(({ recent }) => recent.level === "error"),
            map(({ entries, recent }) => ({
                context: entries,
                error: recent.messages.find((e) => e instanceof Error) as Error,
            })),
            filter(({ error }) => !!error)
        )
        .subscribe(({ error, context }) => {
            const currentLensState = lensState?.getState();
            const lensId =
                currentLensState && !isState(currentLensState, "noLensApplied") ? currentLensState.data.id : "none";
            metricsEventTarget.dispatchEvent(
                new TypedCustomEvent("exception", {
                    name: "exception",
                    lensId,
                    type: error.name,
                    reason: `${stringifyError(error)}${contextSeparator}${getContextString(context)}`,
                })
            );

            reporter.count("handled_exception", 1, new Map([["type", error.name]]));
        });
}

export interface GlobalExceptionReporter {
    attachLensContext: (lensState: LensState) => void;
}

/**
 * Reports log entries to Blizzard when there is no CameraKit session yet.
 *
 * @internal
 */
export const reportGlobalException = Injectable(
    "reportGlobalException",
    [logEntriesFactory.token, metricsEventTargetFactory.token, operationalMetricReporterFactory.token] as const,
    (
        logEntries: Observable<LogEntry>,
        metricsEventTarget: MetricsEventTarget,
        reporter: OperationalMetricsReporter
    ): GlobalExceptionReporter => {
        // Initially we log exceptions without any lens context
        const cancellationSubject = new Subject<void>();
        reportExceptionToBlizzard(logEntries.pipe(takeUntil(cancellationSubject)), metricsEventTarget, reporter);

        // Later session scope reporter triggers cancellation of the global one
        // and initiates exception reporting with a lens context
        return {
            attachLensContext: (lensState: LensState) => {
                cancellationSubject.next();
                reportExceptionToBlizzard(logEntries, metricsEventTarget, reporter, lensState);
            },
        };
    }
);
