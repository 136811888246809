import { Injectable } from "../dependency-injection/Injectable";
import { LensesDefinition } from "../generated-proto/pb_schema/camera_kit/v3/service";
import { GrpcServiceClient, createTsProtoClient } from "./createTsProtoClient";
import { GrpcHandler, gprcHandlerFactory } from "./grpcHandler";

export type LensesClient = GrpcServiceClient<typeof LensesDefinition>;

export const lensesClientFactory = Injectable(
    "lensesClient",
    [gprcHandlerFactory.token],
    (grpcHandler: GrpcHandler): LensesClient => createTsProtoClient(LensesDefinition, grpcHandler)
);
