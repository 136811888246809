import { Writer } from "protobufjs";
import { Lens } from "../generated-proto/pb_schema/camera_kit/v3/lens";
import { LensesClient, lensesClientFactory } from "../clients/lensesClient";
import { ConcatInjectable } from "../dependency-injection/Injectable";
import { LensSource, lensSourcesFactory } from "./LensSource";

/**
 * This LensSource loads lenses from the CameraKit backend service. It is meant to be used as the last LensSource in the
 * LensSource[] array used by LensRepository to load lenses.
 *
 * We ensure this is the case by providing cameraKitLensSourceFactory *after* the DI container has been modified by the
 * application during bootstrap -- this way we're guaranteed to place this LensSource after all other LensSources.
 *
 * @internal
 */
export const cameraKitLensSourceFactory = ConcatInjectable(
    lensSourcesFactory.token,
    [lensesClientFactory.token],
    (lensesClient: LensesClient): LensSource => ({
        // This LensSource will claim ownership of all lens groups -- it should be used as the last element in a
        // list of LensSources, as a catch-all to load any lens groups not claimed by other LensSources.
        isGroupOwner(): boolean {
            return true;
        },

        async loadLens(lensId: string, groupId: string): Promise<ArrayBuffer> {
            const result = await lensesClient.getGroupLens({ lensId, groupId });
            if (!result.ok) {
                const error = result.unwrapErr();
                throw new Error(
                    `Cannot load lens lens ${lensId} from group ${groupId}. An error occured in the ` +
                        `gRPC client:\n\t[${error.status}] ${error.statusMessage}`
                );
            }
            const response = result.unwrap();
            if (!response.message?.lens) {
                throw new Error(
                    `Cannot load lens ${lensId} from group ${groupId}. The response did not contain ` +
                        `a lens.\n\t${JSON.stringify(result)} for requestId ${response.headers.get("x-request-id")}`
                );
            }
            return Lens.encode(response.message.lens, Writer.create().uint32(10).fork()).ldelim().finish();
        },

        async loadLensGroup(groupId: string): Promise<ArrayBuffer> {
            const result = await lensesClient.getGroup({ id: groupId });
            if (!result.ok) {
                const error = result.unwrapErr();
                throw new Error(
                    `Cannot load lens group ${groupId}. An error occured in the gRPC client:\n` +
                        `\t[${error.status}] ${error.statusMessage}`
                );
            }
            const response = result.unwrap();
            if (!response.message?.lenses) {
                throw new Error(
                    `Cannot load lens group ${groupId}. The response contained no lenses ` +
                        `\n\t${JSON.stringify(response)} for requestId ${response.headers.get("x-request-id")}`
                );
            }
            const writer = Writer.create();
            response.message.lenses.forEach((lens) => Lens.encode(lens, writer.uint32(10).fork()).ldelim());
            return writer.finish();
        },
    })
);
