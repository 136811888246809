import { CameraKitSource, CameraKitSourceOptions } from "./CameraKitSource";

const defaultOptions: VideoSourceOptions = {
    trackingData: new ArrayBuffer(0),
};

/** @category Rendering */
export interface VideoSourceOptions {
    trackingData: ArrayBuffer;
}

/**
 * Create a {@link CameraKitSource} from an
 * [HTMLVideoElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement).
 *
 * @param video CameraKit performs best when the source video is 720p – arbitrary resolutions are supported, but very
 * high resolutions may not perform well.
 * @param options
 * @param options.trackingData If pre-computed tracking data exists for this video, it may be provided in order to boost
 * rendering performance (it is not typical or expected for this to be provided).
 * @param options.cameraType By default we set this to 'user', which is the camera type most Lenses expect.
 * @param options.fpsLimit By default we set no limit on FPS – if the video has a known FPS setting this limit
 * may prevent CameraKit from using more compute resources than strictly necessary.
 *
 * @category Rendering
 */
export function createVideoSource(
    video: HTMLVideoElement,
    options: CameraKitSourceOptions<VideoSourceOptions> = {}
): CameraKitSource {
    const { trackingData } = { ...defaultOptions, ...options };
    const replayTrackingData = trackingData.byteLength > 0 ? { buffer: trackingData } : undefined;

    return new CameraKitSource(
        {
            media: video,
            replayTrackingData,
        },
        {},
        options
    );
}
