import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "com.snap.camerakit.v3";
export interface RankingData {
    sessionId: string;
    locale: string;
    osType: RankingData_OSType;
    connectivityType: RankingData_ConnectivityType;
}
export enum RankingData_OSType {
    OS_TYPE_UNSET = 0,
    OS_TYPE_ANDROID = 1,
    OS_TYPE_IOS = 2,
    OS_TYPE_IPAD_OS = 3,
    OS_TYPE_MAC_OS = 4,
    OS_TYPE_WINDOWS = 5,
    OS_TYPE_LINUX = 6,
    UNRECOGNIZED = -1
}
export function rankingData_OSTypeFromJSON(object: any): RankingData_OSType {
    switch (object) {
        case 0:
        case "OS_TYPE_UNSET":
            return RankingData_OSType.OS_TYPE_UNSET;
        case 1:
        case "OS_TYPE_ANDROID":
            return RankingData_OSType.OS_TYPE_ANDROID;
        case 2:
        case "OS_TYPE_IOS":
            return RankingData_OSType.OS_TYPE_IOS;
        case 3:
        case "OS_TYPE_IPAD_OS":
            return RankingData_OSType.OS_TYPE_IPAD_OS;
        case 4:
        case "OS_TYPE_MAC_OS":
            return RankingData_OSType.OS_TYPE_MAC_OS;
        case 5:
        case "OS_TYPE_WINDOWS":
            return RankingData_OSType.OS_TYPE_WINDOWS;
        case 6:
        case "OS_TYPE_LINUX":
            return RankingData_OSType.OS_TYPE_LINUX;
        case -1:
        case "UNRECOGNIZED":
        default:
            return RankingData_OSType.UNRECOGNIZED;
    }
}
export function rankingData_OSTypeToJSON(object: RankingData_OSType): string {
    switch (object) {
        case RankingData_OSType.OS_TYPE_UNSET:
            return "OS_TYPE_UNSET";
        case RankingData_OSType.OS_TYPE_ANDROID:
            return "OS_TYPE_ANDROID";
        case RankingData_OSType.OS_TYPE_IOS:
            return "OS_TYPE_IOS";
        case RankingData_OSType.OS_TYPE_IPAD_OS:
            return "OS_TYPE_IPAD_OS";
        case RankingData_OSType.OS_TYPE_MAC_OS:
            return "OS_TYPE_MAC_OS";
        case RankingData_OSType.OS_TYPE_WINDOWS:
            return "OS_TYPE_WINDOWS";
        case RankingData_OSType.OS_TYPE_LINUX:
            return "OS_TYPE_LINUX";
        default:
            return "UNKNOWN";
    }
}
export enum RankingData_ConnectivityType {
    CONNECTIVITY_TYPE_UNSET = 0,
    CONNECTIVITY_TYPE_WIFI = 1,
    CONNECTIVITY_TYPE_MOBILE = 2,
    CONNECTIVITY_TYPE_UNREACHABLE = 3,
    CONNECTIVITY_TYPE_BLUETOOTH = 4,
    UNRECOGNIZED = -1
}
export function rankingData_ConnectivityTypeFromJSON(object: any): RankingData_ConnectivityType {
    switch (object) {
        case 0:
        case "CONNECTIVITY_TYPE_UNSET":
            return RankingData_ConnectivityType.CONNECTIVITY_TYPE_UNSET;
        case 1:
        case "CONNECTIVITY_TYPE_WIFI":
            return RankingData_ConnectivityType.CONNECTIVITY_TYPE_WIFI;
        case 2:
        case "CONNECTIVITY_TYPE_MOBILE":
            return RankingData_ConnectivityType.CONNECTIVITY_TYPE_MOBILE;
        case 3:
        case "CONNECTIVITY_TYPE_UNREACHABLE":
            return RankingData_ConnectivityType.CONNECTIVITY_TYPE_UNREACHABLE;
        case 4:
        case "CONNECTIVITY_TYPE_BLUETOOTH":
            return RankingData_ConnectivityType.CONNECTIVITY_TYPE_BLUETOOTH;
        case -1:
        case "UNRECOGNIZED":
        default:
            return RankingData_ConnectivityType.UNRECOGNIZED;
    }
}
export function rankingData_ConnectivityTypeToJSON(object: RankingData_ConnectivityType): string {
    switch (object) {
        case RankingData_ConnectivityType.CONNECTIVITY_TYPE_UNSET:
            return "CONNECTIVITY_TYPE_UNSET";
        case RankingData_ConnectivityType.CONNECTIVITY_TYPE_WIFI:
            return "CONNECTIVITY_TYPE_WIFI";
        case RankingData_ConnectivityType.CONNECTIVITY_TYPE_MOBILE:
            return "CONNECTIVITY_TYPE_MOBILE";
        case RankingData_ConnectivityType.CONNECTIVITY_TYPE_UNREACHABLE:
            return "CONNECTIVITY_TYPE_UNREACHABLE";
        case RankingData_ConnectivityType.CONNECTIVITY_TYPE_BLUETOOTH:
            return "CONNECTIVITY_TYPE_BLUETOOTH";
        default:
            return "UNKNOWN";
    }
}
function createBaseRankingData(): RankingData {
    return { sessionId: "", locale: "", osType: 0, connectivityType: 0 };
}
export const RankingData = {
    encode(message: RankingData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sessionId !== "") {
            writer.uint32(10).string(message.sessionId);
        }
        if (message.locale !== "") {
            writer.uint32(18).string(message.locale);
        }
        if (message.osType !== 0) {
            writer.uint32(24).int32(message.osType);
        }
        if (message.connectivityType !== 0) {
            writer.uint32(32).int32(message.connectivityType);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): RankingData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRankingData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionId = reader.string();
                    break;
                case 2:
                    message.locale = reader.string();
                    break;
                case 3:
                    message.osType = reader.int32() as any;
                    break;
                case 4:
                    message.connectivityType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): RankingData {
        return {
            sessionId: isSet(object.sessionId) ? String(object.sessionId) : "",
            locale: isSet(object.locale) ? String(object.locale) : "",
            osType: isSet(object.osType) ? rankingData_OSTypeFromJSON(object.osType) : 0,
            connectivityType: isSet(object.connectivityType)
                ? rankingData_ConnectivityTypeFromJSON(object.connectivityType)
                : 0,
        };
    },
    toJSON(message: RankingData): unknown {
        const obj: any = {};
        message.sessionId !== undefined && (obj.sessionId = message.sessionId);
        message.locale !== undefined && (obj.locale = message.locale);
        message.osType !== undefined && (obj.osType = rankingData_OSTypeToJSON(message.osType));
        message.connectivityType !== undefined &&
            (obj.connectivityType = rankingData_ConnectivityTypeToJSON(message.connectivityType));
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<RankingData>, I>>(object: I): RankingData {
        const message = createBaseRankingData();
        message.sessionId = object.sessionId ?? "";
        message.locale = object.locale ?? "";
        message.osType = object.osType ?? 0;
        message.connectivityType = object.connectivityType ?? 0;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
