import type { CameraKitDeviceOptions } from "./CameraKitSource";
import { CameraKitSource } from "./CameraKitSource";

/**
 * Create a {@link CameraKitSource} from an
 * [HTMLImageElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement).
 *
 * @param image Image element.
 * @param options Options.
 *
 * @category Rendering
 */
export function createImageSource(
    image: HTMLImageElement,
    options: Partial<CameraKitDeviceOptions> = {}
): CameraKitSource {
    return new CameraKitSource(
        {
            media: image,
        },
        {},
        options
    );
}
