import { Injectable } from "../dependency-injection/Injectable";
import { FetchHandler } from "../handlers/defaultFetchHandler";
import { cameraKitServiceFetchHandlerFactory } from "../handlers/cameraKitServiceFetchHandlerFactory";
import { HandlerChainBuilder } from "../handlers/HandlerChainBuilder";
import { createRateLimitingHandler } from "../handlers/rateLimitingHandler";
import { PageVisibility, pageVisibilityFactory } from "../common/pageVisibility";

const METRIC_REQUEST_RATE_LIMIT_MS = 1000; // send at most one metric request per second.

/**
 * @internal
 */
export const metricsHandlerFactory = Injectable(
    "metricsHandler",
    [cameraKitServiceFetchHandlerFactory.token, pageVisibilityFactory.token] as const,
    (fetchHandler: FetchHandler, pageVisibility: PageVisibility) => {
        return new HandlerChainBuilder(fetchHandler).map(
            createRateLimitingHandler(METRIC_REQUEST_RATE_LIMIT_MS, pageVisibility)
        ).handler;
    }
);
