import { from, map, mergeMap, Observable, shareReplay, take } from "rxjs";
import { CameraKitApiHostname, CameraKitConfiguration, configurationToken } from "../configuration";
import { Injectable } from "../dependency-injection/Injectable";
import { ConfigTargetingRequest } from "../generated-proto/pb_schema/cdp/cof/config_request";
import { ConfigResult } from "../generated-proto/pb_schema/cdp/cof/config_result";
import { Namespace } from "../generated-proto/pb_schema/cdp/cof/namespace";
import { GetInitializationConfigResponse } from "../generated-proto/pb_schema/camera_kit/v3/service";
import { cameraKitServiceFetchHandlerFactory, FetchHandler } from "../handlers";
import { cofHandlerFactory } from "./cofHandler";

const defaultTargetingRequest: Partial<ConfigTargetingRequest> = {
    namespaces: [Namespace.LENS_CORE, Namespace.CAMERA_KIT_CORE, Namespace.LENS_CORE_CONFIG],
};

const initializeConfigRelativePath = "/com.snap.camerakit.v3.Metrics/metrics/initialization_config";

type SupportedNamespaces = Namespace.LENS_CORE | Namespace.CAMERA_KIT_CORE | Namespace.LENS_CORE_CONFIG;

export type InitializationConfig = GetInitializationConfigResponse;

export class RemoteConfiguration {
    private readonly configById: Observable<Map<string, ConfigResult[]>>;
    private readonly initializationConfig: Observable<InitializationConfig>;

    constructor(
        lensPerformance: CameraKitConfiguration["lensPerformance"],
        apiHostname: CameraKitApiHostname,
        cofHandler: ReturnType<typeof cofHandlerFactory>,
        fetchHandler: FetchHandler
    ) {
        const lensCluster = Promise.resolve(lensPerformance).then((lensPerformance) => {
            // `0` means no cluster could be determined. For COF, we'll omit a value in that case.
            return lensPerformance?.cluster === 0 ? undefined : lensPerformance?.cluster;
        });

        this.configById = from(lensCluster).pipe(
            // Note: we don't catch errors here, purposefully letting them propagate to subscribers outside this class.
            // Subscribers, having more context about the config use-case, will know better how to handle an error than
            // we do here (e.g. their logging / reporting will have more context, and they can use the error they get
            // from this Observable as a cause).
            mergeMap((lensClusterOrig4) =>
                from(
                    cofHandler({
                        ...defaultTargetingRequest,
                        lensClusterOrig4,
                    })
                )
            ),
            map((result) => {
                const configById = new Map<string, ConfigResult[]>();
                result.configResults.forEach((config) => {
                    const configsWithId = configById.get(config.configId) ?? [];
                    configsWithId.push(config);
                    configById.set(config.configId, configsWithId);
                });
                return configById;
            }),
            shareReplay(1)
        );

        this.initializationConfig = new Observable<InitializationConfig>((observer) => {
            fetchHandler(`https://${apiHostname}${initializeConfigRelativePath}`)
                .then((response) => response.json())
                .then((data) => {
                    observer.next(data);
                    observer.complete();
                })
                .catch((err) => observer.error(err));
        }).pipe(shareReplay(1));
    }

    /**
     * COF configuration.
     */
    get(configId: string): Observable<ConfigResult[]> {
        return this.configById.pipe(map((config) => config.get(configId) ?? []));
    }

    /**
     * Configuration that is provided by Camera Kit backend.
     */
    getInitializationConfig(): Observable<InitializationConfig> {
        return this.initializationConfig;
    }

    getNamespace(namespace: SupportedNamespaces): Observable<ConfigResult[]> {
        return this.configById.pipe(
            map((configs) => {
                const namespaceConfigs = Array.from(configs.values())
                    .filter((values) => values.some((c) => c.namespace === namespace))
                    .flatMap((results) => results);

                return namespaceConfigs;
            })
        );
    }
}

export const remoteConfigurationFactory = Injectable(
    "remoteConfiguration",
    [configurationToken, cofHandlerFactory.token, cameraKitServiceFetchHandlerFactory.token] as const,
    (
        config: CameraKitConfiguration,
        cofHandler: ReturnType<typeof cofHandlerFactory>,
        fetchHandler: FetchHandler
    ): RemoteConfiguration => {
        const remoteConfig = new RemoteConfiguration(
            config.lensPerformance,
            config.apiHostname,
            cofHandler,
            fetchHandler
        );

        // We'll kick off remote configuration loading by subscribing (and then unsubscribing) to a dummy config value.
        // Subsequent requests for config will use the shared Observable, benefitting from this eager loading.
        remoteConfig.get("").pipe(take(1)).subscribe();

        return remoteConfig;
    }
);
