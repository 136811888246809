import { LaunchData } from "../generated-proto/pb_schema/lenses/launchdata";
import { isNumber, isRecord, isString } from "../common/typeguards";
import { LaunchParams } from "../generated-proto/pb_schema/lenses/launch_params";
import { UserData_Zodiac } from "../generated-proto/pb_schema/lenses/user_data";

const isValidNumber = (value: unknown): value is number => {
    return isNumber(value) && !Number.isNaN(value) && Number.isFinite(value);
};
const isValidLaunchParam = (value: unknown): boolean => {
    if (Array.isArray(value)) return value.every(isString) || value.every(isValidNumber);
    return isString(value) || isValidNumber(value);
};

type LensLaunchParams = Record<string, string | number | string[] | number[]>;

/**
 * Some Lenses may accept (or require) certain data provided to them when the Lens is applied.
 *
 * This data may include things like user info (to render the user's name, for example, or perform some task based on
 * their birth date), or arbitrary `launchParams` defined by the Lens.
 *
 * @category Lenses
 */
export interface LensLaunchData {
    userId?: string;
    userData?: {
        userId: string;
        username: string;
        birthdate: string;
        displayName: string;
        countrycode: string;
        score: number;
        bitmojiInfo?: {
            avatarId: string;
            selfieId: string;
        };
        friendInfo?: {
            friendshipStart?: Date;
            lastInteraction?: Date;
            streak: number;
        };
        zodiac: UserData_Zodiac;
    };
    launchParams?: LensLaunchParams;
}

/**
 * @internal
 */
export const encodeLensLaunchData = (launchData: LensLaunchData, persistentStore: ArrayBuffer): Uint8Array => {
    // finish() protobufjs method returns UInt8Array with shared ArrayBuffer
    // to avoid of detached buffer error when passing data to Lens Core
    // data should be copied using slice() method
    return LaunchData.encode(
        LaunchData.fromPartial({
            ...launchData,
            launchParams: launchData.launchParams ? encodeLensLaunchParams(launchData.launchParams) : undefined,
            persistentStore: { store: new Uint8Array(persistentStore) },
        })
    )
        .finish()
        .slice();
};

function encodeLensLaunchParams(launchParams?: LensLaunchParams): LaunchParams {
    const newError = (message: string) => new Error(`Failed to encode lens launchParams. ${message}`);

    if (!isRecord(launchParams) || launchParams instanceof Date) {
        throw newError(`Expected a plain object, got ${typeof launchParams} instead.`);
    }

    for (const [key, value] of Object.entries(launchParams)) {
        if (!isValidLaunchParam(value)) {
            throw newError(
                `Values must be strings, numbers, or arrays of strings or numbers. Field ${key} is ` +
                    `a ${typeof value} instead, with value: ${JSON.stringify(value)}`
            );
        }
    }

    return { data: new TextEncoder().encode(JSON.stringify(launchParams)) };
}
