import { fileOpen, FileWithHandle } from "browser-fs-access";
import { ClientInterfaceData, LensCore } from "../lens-core-module";
import { getLogger } from "../logger/logger";
import { extractJpegOrientationTag, Orientation } from "./exif";

const logger = getLogger("lensClientInterfaceImagePicker");

// Common MIME types supported by all browsers as per:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const mimeTypes = {
    image: [
        "image/avif",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/tiff",
        "image/webp",
    ],
    video: [
        "video/3gpp",
        "video/3gpp2",
        "video/mp2t",
        "video/mp4",
        "video/mpeg",
        "video/ogg",
        "video/quicktime",
        "video/webm",
        "video/x-msvideo",
    ],
} as const;

function* enumerateSupportedVideoTypes(types: typeof mimeTypes.video) {
    // test video element to perform MIME types support check
    const testVideoElement = typeof document !== "undefined" ? document.createElement("video") : undefined;
    for (const type of types) {
        if (testVideoElement?.canPlayType(type) || false) {
            yield type;
        }
    }
}

function readFileAsArrayBuffer(file: FileWithHandle): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
            // Safety: target.result is always an ArrayBuffer because we read file using readAsArrayBuffer()
            resolve(event.target!.result as ArrayBuffer);
        });
        reader.addEventListener("error", (event) => {
            reject(event.target!.error);
        });
        reader.readAsArrayBuffer(file);
    });
}

function getMimeType({ ImageEnabled, VideoEnabled }: ClientInterfaceData) {
    const types = [];
    if (ImageEnabled === "1") types.push(...mimeTypes.image);
    if (VideoEnabled === "1") types.push(...enumerateSupportedVideoTypes(mimeTypes.video));
    if (types.length === 0) {
        throw new Error("Unknown media type requested.");
    }
    return types;
}

function getOrientation(data: ArrayBuffer, lensCore: LensCore) {
    const orientationMap = {
        [Orientation.TopLeft]: lensCore.ExternalMediaOrientation.CW0,
        [Orientation.TopRight]: lensCore.ExternalMediaOrientation.CW0,
        [Orientation.BottomRight]: lensCore.ExternalMediaOrientation.CW180,
        [Orientation.BottomLeft]: lensCore.ExternalMediaOrientation.CW180,
        [Orientation.LeftTop]: lensCore.ExternalMediaOrientation.CW90,
        [Orientation.RightTop]: lensCore.ExternalMediaOrientation.CW90,
        [Orientation.RightBottom]: lensCore.ExternalMediaOrientation.CW270,
        [Orientation.LeftBottom]: lensCore.ExternalMediaOrientation.CW270,
    };
    try {
        return orientationMap[extractJpegOrientationTag(data) ?? Orientation.TopLeft];
    } catch (error) {
        logger.info("Error occured while reading EXIF orientation tag.", error);
        return lensCore.ExternalMediaOrientation.CW0;
    }
}

/**
 * Shows file open dialog to allow user to select image/video and provides the selection to LensCore.
 *
 * @internal
 */
export async function pickClientImage(clientInterfaceData: ClientInterfaceData, lensCore: LensCore) {
    const mimeTypes = getMimeType(clientInterfaceData);
    logger.debug(`Opening file dialog for MIME types: ${mimeTypes}`);

    const file = await fileOpen({ mimeTypes });
    logger.debug(`Selected file MIME type: ${file.type}`);

    const data = await readFileAsArrayBuffer(file);
    if (file.type.startsWith("image/")) {
        lensCore.provideExternalImage({
            data,
            orientation: getOrientation(data, lensCore),
            // As per Corvyn: both iOS and Android clients have the ability to pick out individual faces
            // from an image to apply the effect on, using each of their native face detectors (not using LensCore).
            // That's what the faceRects is for. For now, we can just apply the effect to the whole image (so face rect
            // [[0,0][1,1]]). In future, in order to be able to pick out individual faces in the media picker, we could:
            // - hook up some external web face tracking library
            // - make LensCore to expose face tracker to external clients
            faceRects: [
                {
                    origin: {
                        x: 0,
                        y: 0,
                    },
                    size: {
                        width: 1,
                        height: 1,
                    },
                },
            ],
        });
    } else {
        lensCore.provideExternalVideo({
            data,
            orientation: lensCore.ExternalMediaOrientation.CW0,
        });
    }
}
