import {
    defineAction,
    defineActions,
    defineState,
    defineStates,
    forActions,
    StateMachine,
} from "@snap/state-management";
import { map, merge } from "rxjs";
import { Injectable } from "../dependency-injection/Injectable";
import { CameraKitSession } from "./CameraKitSession";

const createSessionState = () => {
    const actions = defineActions(
        defineAction("suspend")<CameraKitSession>(),
        defineAction("resume")<CameraKitSession>(),
        defineAction("destroy")()
    );

    const states = defineStates(
        defineState("inactive")(),
        defineState("active")<CameraKitSession>(),
        defineState("destroyed")()
    );

    return new StateMachine(actions, states, defineState("inactive")()(), (events) => {
        return merge(
            events.pipe(
                forActions("resume"),
                map(([a]) => states.active(a.data))
            ),
            events.pipe(
                forActions("suspend"),
                map(() => states.inactive())
            ),
            events.pipe(
                forActions("destroy"),
                map(() => states.destroyed())
            )
        );
    });
};

export type SessionState = ReturnType<typeof createSessionState>;

export const sessionStateFactory = Injectable("sessionState", (): SessionState => createSessionState());
