import { DefaultErrorHandler, DefaultEventHandler, LensCoreModule, PlayCanvasInput } from "./generated-types";
import { LensCoreError, wrapLensCoreError } from "./lensCoreError";

// Note: While this looks similar to Omit, Omit breaks discriminated unions:
// https://github.com/microsoft/TypeScript/issues/31501
// This is relevant in the case of setRenderMode().
type SafeOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

/**
 * LensCore methods that require additional handling.
 */
type LensCoreCustomMethods = {
    // initialize() param has exceptionHandler, which requires error mapping.
    initialize(input: PromisifiedParam<MapParamErrorCallback<"initialize", "exceptionHandler">>): Promise<void>;

    // provideRemoteAssetsResponse() param has onFailure, which requires error mapping.
    provideRemoteAssetsResponse(input: MapParamErrorCallback<"provideRemoteAssetsResponse", "onFailure">): void;

    // playCanvas() should resolve its Promise when the `onReady` callback is invoked, instead of the `onSuccess`.
    playCanvas(input: Omit<PlayCanvasInput, "onReady" | "onSuccess" | "onFailure">): Promise<void>;
};

// Ensure method names defined in CustomLensCoreMethods are the ones that exist in LensCoreModule.
type LensCoreCustomMethodNames = keyof LensCoreCustomMethods extends keyof LensCoreModule
    ? keyof LensCoreCustomMethods
    : never;

type PropertyKinds = "enum" | "sync method" | "promisifiable method" | "custom method";

// Create a mapping between property keys and the kind of property it is (enum, sync method, or async method)
type LensCorePropertyKinds = {
    [K in keyof LensCoreModule]: LensCoreModule[K] extends (...args: any[]) => any
        ? K extends LensCoreCustomMethodNames
            ? "custom method"
            : Parameters<LensCoreModule[K]>[0] extends {
                  onSuccess?: DefaultEventHandler;
                  onFailuer?: DefaultErrorHandler;
              }
            ? "promisifiable method"
            : "sync method"
        : "enum";
};

// Helper to select LensCoreModule properties of a particular kind.
type PropertiesOfKind<T extends PropertyKinds> = Exclude<
    {
        [K in keyof LensCorePropertyKinds]: LensCorePropertyKinds[K] extends T ? K : never;
    }[keyof LensCorePropertyKinds],
    undefined
>;
type FirstParameter<T> = T extends (first: infer P, ...rest: any[]) => any ? P : never;
type LensCoreEnums = Pick<LensCoreModule, PropertiesOfKind<"enum">>;
type LensCoreSyncMethods = Pick<LensCoreModule, PropertiesOfKind<"sync method">>;
type LensCorePromisifiedMethods = {
    [K in PropertiesOfKind<"promisifiable method">]: K extends keyof LensCoreModule
        ? keyof SafeOmit<Parameters<LensCoreModule[K]>[0], "onSuccess" | "onFailure"> extends never
            ? () => Promise<FirstParameter<Parameters<LensCoreModule[K]>[0]["onSuccess"]>>
            : (
                  input: SafeOmit<Parameters<LensCoreModule[K]>[0], "onSuccess" | "onFailure">
              ) => Promise<FirstParameter<Parameters<LensCoreModule[K]>[0]["onSuccess"]>>
        : never;
};
type PromisifiedParam<T extends { onSuccess?: DefaultEventHandler; onFailure?: DefaultErrorHandler }> = {
    [P in keyof T as P extends "onSuccess" | "onFailure" ? never : P]: T[P];
};
type MapParamErrorCallback<
    T extends PropertiesOfKind<"custom method">,
    U extends keyof LensCoreMethodFirstParam<T>
> = MapErrorCallback<LensCoreMethodFirstParam<T>, U>;
type LensCoreMethodFirstParam<T extends PropertiesOfKind<"custom method">> = Parameters<LensCoreModule[T]>[0];
type MapErrorCallback<T, U extends keyof T> = { [P in keyof T]: P extends U ? (err: LensCoreError) => void : T[P] };

// By using PropertiesOfKind, we can ensure a compile-time error if LensCoreModule adds a new async method,
// but we forget to update this list.
const promisifiableMethods: { [K in PropertiesOfKind<"promisifiable method">]: null } = {
    addLens: null,
    clearAllLenses: null,
    imageToYuvBuffer: null,
    pauseCanvas: null,
    processAudioSampleBuffer: null,
    processFrame: null,
    removeLens: null,
    replaceLenses: null,
    setAudioParameters: null,
    setDeviceClass: null,
    setFPSLimit: null,
    setInputTransform: null,
    setOnFrameProcessedCallback: null,
    setRenderLoopMode: null,
    setRenderSize: null,
    teardown: null,
    useMediaElement: null,
    yuvBufferToBitmap: null,
};

export type LensCore = LensCoreEnums & LensCoreSyncMethods & LensCorePromisifiedMethods & LensCoreCustomMethods;

export const createLensCore = (lensCoreModule: LensCoreModule): LensCore => {
    const customMethods: LensCoreCustomMethods = {
        initialize(input) {
            return new Promise((onSuccess, onFailure) =>
                lensCoreModule.initialize({
                    ...input,
                    exceptionHandler: input.exceptionHandler && wrapLensCoreError(input.exceptionHandler),
                    onSuccess,
                    onFailure: wrapLensCoreError(onFailure),
                })
            );
        },

        provideRemoteAssetsResponse(input) {
            return lensCoreModule.provideRemoteAssetsResponse({
                ...input,
                onFailure: input.onFailure && wrapLensCoreError(input.onFailure),
            });
        },

        playCanvas(input) {
            return new Promise((onReady, onFailure) => {
                lensCoreModule.playCanvas({
                    ...input,
                    onReady,
                    onFailure,
                });
            });
        },
    };

    return new Proxy(lensCoreModule, {
        get: (target, property, receiver) => {
            // Handle special methods with custom implementations
            if (property in customMethods) {
                // Safety: "in" operator above ensures that property is keyof LensCoreCustomMethods
                return customMethods[property as keyof LensCoreCustomMethods];
            }

            // All other async methods return Promises
            if (property in promisifiableMethods) {
                const method = Reflect.get(target, property, receiver);
                if (!method) method;
                return (input: Parameters<LensCorePromisifiedMethods[keyof LensCorePromisifiedMethods]>[0]) =>
                    new Promise((onSuccess, onFailure) =>
                        method({
                            ...input,
                            onSuccess,
                            onFailure: wrapLensCoreError(onFailure),
                        })
                    );
            }

            // All other kinds of properties (enums, sync methods) are unmodified.
            return Reflect.get(target, property, receiver);
        },
        // Safety: We ensured safety by defining types for both custom and promisifiable methods.
    }) as LensCore;
};
