import {
    Actions,
    defineAction,
    defineActions,
    defineState,
    defineStates,
    dispatch,
    forActions,
    inStates,
    StateMachine,
} from "@snap/state-management";
import {
    catchError,
    exhaustMap,
    from,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
} from "rxjs";
import { Injectable } from "../dependency-injection/Injectable";
import { Lens } from "../lens/Lens";
import { LensRepository, lensRepositoryFactory } from "../lens/LensRepository";
import { lensCoreFactory, LensCore } from "../lens-core-module";
import { AddLensInput } from "../lens-core-module/generated-types";
import { IndexedDBPersistence } from "../persistence/IndexedDBPersistence";
import { lensPersistenceStoreFactory } from "../lens/LensPersistenceStore";
import { encodeLensLaunchData, LensLaunchData } from "../lens/LensLaunchData";
import { LegalState, legalStateFactory } from "../legal/legalState";
import { LensAssetRepository, lensAssetRepositoryFactory } from "../lens/assets/LensAssetRepository";
import {
    legalError,
    LegalError,
    lensContentValidationError,
    LensContentValidationError,
    lensError,
    LensError,
} from "../namedErrors";
import { getLogger } from "../logger/logger";
import { Timer } from "../metrics/operational/Timer";
import {
    operationalMetricReporterFactory,
    OperationalMetricsReporter,
} from "../metrics/operational/operationalMetricsReporter";
import { unsubscribed } from "../observable-operators/unsubscribed";
import { assertUnreachable } from "../common/assertions";

const logger = getLogger("LensState");

const createLensState = () => {
    const actions = defineActions(
        defineAction("applyLens")<{ lens: Lens; launchData?: LensLaunchData }>(),
        defineAction("downloadComplete")<Lens>(),
        defineAction("turnedOn")<Lens>(),
        defineAction("resourcesLoaded")<Lens>(),
        defineAction("firstFrameProcessed")<Lens>(),
        defineAction("applyLensComplete")<Lens>(),
        defineAction("applyLensFailed")<{ error: LensErrors; lens: Lens }>(),
        defineAction("applyLensAborted")<Lens>(),

        defineAction("removeLens")(),
        defineAction("turnedOff")<Lens>(),
        defineAction("removeLensComplete")(),
        defineAction("removeLensFailed")<Error>()
    );

    const states = defineStates(
        defineState("noLensApplied")(),
        defineState("applyingLens")<Lens>(),
        defineState("lensApplied")<Lens>()
    );

    return new StateMachine(actions, states, states.noLensApplied(), (events) =>
        merge(
            events.pipe(
                // We allow a new lens to be applied at any time, no matter the state.
                inStates("noLensApplied", "applyingLens", "lensApplied"),
                forActions("applyLens"),
                map(([a]) => states.applyingLens(a.data.lens))
            ),
            events.pipe(
                inStates("applyingLens"),
                forActions("applyLensComplete"),
                map(([a]) => states.lensApplied(a.data))
            ),
            events.pipe(
                inStates("applyingLens"),
                forActions("applyLensFailed"),
                map(() => states.noLensApplied())
            ),
            events.pipe(
                inStates("lensApplied"),
                forActions("removeLensComplete"),
                map(() => states.noLensApplied())
            )
        )
    );
};

export type LensErrors = LegalError | LensContentValidationError | LensError;

export type LensState = ReturnType<typeof createLensState>;

export const lensStateFactory = Injectable(
    "lensState",
    [
        lensCoreFactory.token,
        lensRepositoryFactory.token,
        lensAssetRepositoryFactory.token,
        lensPersistenceStoreFactory.token,
        legalStateFactory.token,
        operationalMetricReporterFactory.token,
    ] as const,
    (
        lensCore: LensCore,
        lensRepository: LensRepository,
        lensAssetRepository: LensAssetRepository,
        lensPersistence: IndexedDBPersistence<ArrayBuffer>,
        legalState: LegalState,
        operationalMetricsReporter: OperationalMetricsReporter
    ): LensState => {
        const lensState = createLensState();
        let firstLensApply = true;

        /**
         * Apply lens
         */
        lensState.events
            .pipe(
                forActions("applyLens"),

                // Determine the legal state (e.g. terms have been accepted). Using exhaustMap means while we are
                // ascertaining legal status (which may include prompting the end user to accept terms), we will ignore
                // any new applyLens actions.
                exhaustMap(([a]) =>
                    of(legalState.actions.requestLegalPrompt()).pipe(
                        dispatch(legalState),
                        inStates("accepted", "rejected"),
                        take(1),
                        map(([, { name }]) => {
                            if (name === "accepted") return a;
                            return lensState.actions.applyLensFailed({
                                error: legalError(
                                    `Failed to apply lens ${a.data.lens.id}. Required legal terms were not accepted.`
                                ),
                                lens: a.data.lens,
                            });
                        })
                    )
                ),

                // The use of switchMap is important so that if we get a new applyLens action while we're still
                // downloading lens content for a previously-requested lens, we can cancel those requests and ensure
                // that lenses are applied in the order they're requested.
                switchMap((a) => {
                    if (a.name === "applyLensFailed") return of(a);

                    const { lens } = a.data;
                    // Convenience method making dispatching an action with Lens data less verbose.
                    const dispatch = (action: Extract<Actions<LensState>, { data: Lens }>["name"]) => {
                        lensState.dispatch(action, lens);
                    };

                    // We record if this was the first lens apply for this page load, since there may be additional
                    // sources of latency (e.g. remote configuration that needs to be loaded) on the first apply that
                    // are not present for subsequent applies.
                    const applyTimer = new Timer("lens").mark("apply", { first: `${firstLensApply}` });
                    firstLensApply = false;

                    return of(a.data).pipe(
                        mergeMap(({ lens, launchData }) => {
                            // If retrieval throws an error, we still want to proceed with the lens
                            // because persisted data is not a necessity.
                            return from(lensPersistence.retrieve(lens.id).catch(() => undefined)).pipe(
                                map((persistentStore) => ({ lens, launchData, persistentStore }))
                            );
                        }),

                        map(({ lens, launchData, persistentStore }) => {
                            const lensDetails = lensRepository.getLensMetadata(lens.id);
                            if (!lensDetails) {
                                throw new Error(
                                    `Cannot apply lens ${lens.id}. It has not been loaded by the Lens ` +
                                        `repository. Use CameraKit.lensRepository.loadLens (or loadLensGroups) ` +
                                        `to load lens metadata before calling CameraKitSession.applyLens.`
                                );
                            }

                            const { content } = lensDetails;
                            if (!content) {
                                throw new Error(
                                    `Cannot apply lens ${lens.id}. Metadata retrieved for this lens does not ` +
                                        `include the lens content URL.`
                                );
                            }

                            return {
                                lens,
                                launchData: encodeLensLaunchData(
                                    launchData ?? {},
                                    persistentStore ?? new ArrayBuffer(0)
                                ),
                                content,
                            };
                        }),

                        // Load lens assets and the lens itself in parallel. Both count toward lens download time.
                        // TODO: use RxJS fetch utilities so that these requests can be cancelled on unsubscribe.
                        mergeMap(({ lens, launchData, content }) => {
                            const networkTimer = applyTimer.mark("network");

                            return from(
                                Promise.all([
                                    lensRepository.getLensContent(lens).finally(() => networkTimer.measure("lens")),
                                    content.assetManifest.length > 0
                                        ? lensAssetRepository
                                              .cacheAssets(content.assetManifest, lens)
                                              .finally(() => networkTimer.measure("assets"))
                                        : Promise.resolve(),
                                ])
                            ).pipe(
                                tap(() => {
                                    networkTimer.measure();
                                    lensState.dispatch("downloadComplete", lens);
                                }),
                                map(([{ lensBuffer, lensChecksum }]): AddLensInput => {
                                    // NOTE: cached array buffer has to be copied each time in order to be reused,
                                    // otherwise the original cached copy would be detached by LensCore
                                    // One optimization can be done here: do not copy the array if getLensContent()
                                    // returned uncached buffer
                                    const lensDataBuffer = lensBuffer.slice(0);
                                    return { lensId: lens.id, lensDataBuffer, lensChecksum, launchData };
                                })
                            );
                        }),

                        // If removeLens is dispatched while downloading, cancel download, don't apply the lens.
                        takeUntil(lensState.events.pipe(forActions("removeLens"))),

                        // Once the lens has downloaded, we can call replaceLenses. We're not concerned about
                        // waiting for prior in-progress calls to replaceLenses to complete, because LensCore
                        // guarantees that calls to replaceLenses will always be processed sequentially in the order
                        // they are received.
                        mergeMap(
                            (lensInput) =>
                                new Observable<Actions<LensState>>((subscriber) => {
                                    const coreTimer = applyTimer.mark("core");

                                    // replaceLenses has the property that if it fails, LensCore guarantees that no
                                    // lenses are active – so we can safely dispatch applyLensFailed and transition
                                    // to noLensApplied state.
                                    lensCore
                                        .replaceLenses({
                                            lenses: [
                                                {
                                                    ...lensInput,
                                                    onTurnOn: () => dispatch("turnedOn"),
                                                    onResourcesLoaded: () => dispatch("resourcesLoaded"),

                                                    // onFirstFrameProcessed marks the end of the lens application for
                                                    // the end-user -- this is when they see the newly applied lens
                                                    // begin to render. As such, this is where we stop our overall
                                                    // latency measurement and report latency metrics.
                                                    onFirstFrameProcessed: () => {
                                                        coreTimer.measure("first-frame");
                                                        applyTimer.measure("success");
                                                        applyTimer.stopAndReport(operationalMetricsReporter);
                                                        dispatch("firstFrameProcessed");
                                                    },
                                                    onTurnOff: () => dispatch("turnedOff"),
                                                },
                                            ],
                                        })
                                        .then(() => {
                                            coreTimer.measure("success");

                                            // We emit applyLensComplete (and applyLensFailed, below) on an
                                            // Observable, which is piped to `dispatch` – this allows `switchMap` to
                                            // properly cancel the dispatch of these actions if a new applyLens
                                            // arrives while we're waiting for onSuccess/onFailure.
                                            //
                                            // That's desirable behavior, because we don't want the applyingLens
                                            // state due to a *subsequent applyLens action* to be transitioned to
                                            // lensApplied by this action.
                                            subscriber.next(lensState.actions.applyLensComplete(lens));
                                            subscriber.complete();
                                        })
                                        .catch((lensCoreError) => {
                                            coreTimer.measure("failure");
                                            applyTimer.measure("failure");
                                            applyTimer.stopAndReport(operationalMetricsReporter);

                                            const message = `Failed to apply lens ${lensInput.lensId}.`;
                                            const error = /validation failed/.test(lensCoreError.message)
                                                ? lensContentValidationError(message, lensCoreError)
                                                : lensError(message, lensCoreError);

                                            subscriber.next(lensState.actions.applyLensFailed({ error, lens }));
                                            subscriber.complete();
                                        });
                                })
                        ),
                        catchError((error: LensErrors) => {
                            applyTimer.measure("failure");
                            applyTimer.stopAndReport(operationalMetricsReporter);
                            return of(lensState.actions.applyLensFailed({ error, lens }));
                        }),

                        // If a new applyLens is received, `switchMap` will unsubscribe from this inner observable,
                        // which stops the current lens application. When this happens we can record a separate metric
                        // to measure aborted lens applications.
                        unsubscribed(() => {
                            applyTimer.measure("abort");
                            applyTimer.stopAndReport(operationalMetricsReporter);
                        })
                    );
                }),
                dispatch(lensState)
            )
            .subscribe({
                error: logger.error,
            });

        /**
         * Remove lens
         */
        lensState.events
            .pipe(
                inStates("lensApplied", "noLensApplied"),
                forActions("removeLens"),
                mergeMap(
                    () =>
                        new Observable<Actions<LensState>>((subscriber) => {
                            lensCore
                                .clearAllLenses()
                                .then(() => {
                                    subscriber.next(lensState.actions.removeLensComplete());
                                    subscriber.complete();
                                })
                                .catch((lensCoreError) => {
                                    const error = new Error("Failed to remove lenses.", { cause: lensCoreError });
                                    subscriber.next(lensState.actions.removeLensFailed(error));
                                    subscriber.complete();
                                });
                        })
                ),
                dispatch(lensState)
            )
            .subscribe({
                error: logger.error,
            });

        lensState.events
            .pipe(
                inStates("applyingLens"),
                forActions("removeLens"),
                switchMap(([a]) =>
                    lensState.events.pipe(
                        // Wait to remove the lens until it has been applied.
                        inStates("lensApplied"),
                        // But cancel the removal if a new applyLens supercedes the current lens. The goal here is to
                        // make sure the latest apply/remove pre-empts any previous request to apply/remove.
                        takeUntil(lensState.events.pipe(forActions("applyLens"))),
                        map(() => a)
                    )
                ),
                dispatch(lensState)
            )
            .subscribe({
                error: logger.error,
            });

        // Log transitions
        lensState.events.subscribe(([a, s]) => {
            const data = extractLoggableData(a);
            logger.debug(`Action: "${a.name}", state: "${s.name}"${data ? ", data: " + JSON.stringify(data) : ""}`);
        });

        return lensState;
    }
);

function extractLoggableData(action: Actions<LensState>): Record<string, string> | undefined {
    switch (action.name) {
        case "applyLens":
            return { lensId: action.data.lens.id };
        case "applyLensFailed":
            return { lensId: action.data.lens.id, error: action.data.error.message };
        case "downloadComplete":
        case "turnedOn":
        case "resourcesLoaded":
        case "firstFrameProcessed":
        case "applyLensComplete":
        case "applyLensAborted":
        case "turnedOff":
            return { lensId: action.data.id };
        case "removeLens":
        case "removeLensComplete":
            return undefined;
        case "removeLensFailed":
            return { error: action.data.message };
        default:
            assertUnreachable(action);
    }
}
