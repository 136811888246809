import { OperationalMetric } from "../../generated-proto/pb_schema/camera_kit/v3/operational_metrics";
import { Metric, MetricDimensions, serializeMetricDimensions } from "./Metric";

/** @internal */
export type CountMetric = OperationalMetric & {
    metric: Extract<OperationalMetric["metric"], { $case: "count" }>;
};

/** @internal */
export class Count extends Metric {
    static count(name: string, n: number, dimensions: MetricDimensions = {}): Count {
        const count = new Count(name, dimensions);
        count.increment(n);
        return count;
    }

    private count = 0;

    constructor(readonly name: string, dimensions: MetricDimensions = {}) {
        super(name, dimensions);
    }

    increment(count: number): number {
        this.count += count;
        return this.count;
    }

    toOperationalMetric(): CountMetric[] {
        return [
            {
                name: `${this.name}${serializeMetricDimensions(this.dimensions)}`,
                timestamp: new Date(),
                metric: { $case: "count", count: this.count },
            },
        ];
    }
}
