import { CameraKitConfiguration } from "./configuration";

/**
 * Prefix of override key on window object.
 */
const windowFieldPrefix = "__snap_camkit_override__";

/**
 * Configuration overrides that are stored in session storage.
 */
type StoredOverrides = Pick<CameraKitConfiguration, "wasmEndpointOverride" | "logger" | "logLevel" | "userAgentFlavor">;

const configPropertiesToOverride: Array<keyof StoredOverrides> = [
    "wasmEndpointOverride",
    "logger",
    "logLevel",
    "userAgentFlavor",
];

configPropertiesToOverride.forEach((fieldToOverride) => {
    defineWindowField(fieldToOverride);
});

function defineWindowField(propertyToOverride: keyof StoredOverrides) {
    if (typeof window === "undefined") return;

    Object.defineProperty(window, `${windowFieldPrefix}${propertyToOverride}`, {
        get() {
            return getConfigurationOverrides()?.[propertyToOverride];
        },
        set(value: any) {
            const storedOverrides = { ...getConfigurationOverrides(), [propertyToOverride]: value };
            if (Object.values(storedOverrides).every((value) => typeof value === "undefined")) {
                sessionStorage.removeItem(windowFieldPrefix);
            } else {
                sessionStorage.setItem(windowFieldPrefix, JSON.stringify(storedOverrides));
            }
        },
        enumerable: false,
        configurable: true,
    });
}

/**
 * Checks whether there are configuration overrides stored, and if yes, returns them.
 *
 * @internal
 */
export function getConfigurationOverrides(): StoredOverrides | undefined {
    if (!sessionStorage) return undefined;
    const overridesString = sessionStorage.getItem(windowFieldPrefix);
    return overridesString && JSON.parse(overridesString);
}
