import { lensRepositoryFactory, LensRepository } from "./lens/LensRepository";
import { CONTAINER, Container } from "./dependency-injection/Container";
import { Injectable } from "./dependency-injection/Injectable";
import { LensCoreError } from "./lens-core-module/lensCoreError";
import { LensCore } from "./lens-core-module/lensCore";
import { CameraKitSession, cameraKitSessionFactory } from "./session/CameraKitSession";
import { registerLensAssetsProvider } from "./lens/assets/LensAssetsProvider";
import { lensCoreFactory } from "./lens-core-module/loader/lensCoreFactory";
import { configurationToken } from "./configuration";
import { RootServices } from "./dependency-injection/RootServices";
import { registerUriHandlers } from "./extensions/uriHandlersRegister";
import { MetricsEventTarget, metricsEventTargetFactory } from "./metrics/metricsEventTarget";
import { reportSessionScopedMetrics } from "./metrics/reporters/reporters";
import { lensStateFactory } from "./session/lensState";
import { lensKeyboardFactory } from "./session/LensKeyboard";
import { registerLensClientInterfaceHandler } from "./lens-client-interface/lensClientInterface";
import { sessionStateFactory } from "./session/sessionState";
import { lensExecutionError, lensAbortError } from "./namedErrors";
import { getLogger, resetLogger } from "./logger/logger";
import { errorLoggingDecorator } from "./logger/errorLoggingDecorator";
import { TypedEventTarget } from "./events/TypedEventTarget";
import { TypedCustomEvent } from "./events/TypedCustomEvent";
import { LensView } from "./metrics/reporters/reportLensView";
import { LensWait } from "./metrics/reporters/reportLensWait";
import { PageVisibility, pageVisibilityFactory } from "./common/pageVisibility";
import { setPreloadedConfiguration } from "./remote-configuration/preloadConfiguration";

const logger = getLogger("CameraKit");
const log = errorLoggingDecorator(logger);

/**
 * Metrics event names that are exposed to apps.
 */
const publicMetricsEventNames: Array<LensMetricsEvents["detail"]["name"]> = ["lensView", "lensWait"];

/**
 * Lens metrics events.
 *
 * These events are emitted by {@link CameraKit} to report lens usage, performance, apply latency, etc.
 *
 * @category Lenses
 * @category Metrics
 */
export type LensMetricsEvents =
    | TypedCustomEvent<LensView["name"], LensView>
    | TypedCustomEvent<LensWait["name"], LensWait>;

/**
 * Options available when creating a {@link CameraKitSession}.
 *
 * @category Rendering
 */
export interface CreateSessionOptions {
    /**
     * Optionally provide an existing canvas element, on which the Live RenderTarget will be rendered.
     *
     * If this is not provided, CameraKit will create a new canvas element which can be added to the DOM.
     */
    liveRenderTarget?: HTMLCanvasElement;

    /**
     * Browsers optimize tabs when they are hidden - for example, by pausing the execution of requestAnimationFrame
     * callbacks.
     *
     * If you need the CameraKitSession to continue rendering even when the tab is in the background, set this to true.
     * There is a small performance penalty, and it's a good practice to only render in the background if absolutely
     * necessary.
     */
    renderWhileTabHidden?: boolean;
}

/**
 * The entry point to the CameraKit SDK's API. Most of CameraKit's features are accessed via this class.
 *
 * Applications obtain an instance of CameraKit by calling {@link bootstrapCameraKit}.
 *
 * @example
 * ```ts
 * const cameraKit = await bootstrapCameraKit(config)
 * ```
 *
 * Then this class can be used to:
 * - Create a {@link CameraKitSession} instance, which provides the API for setting up media inputs, applying Lenses,
 * and obtaining rendered `<canvas>` outputs.
 * - Query for lenses using {@link LensRepository}.
 * - Listen for lens usage metrics events using {@link MetricsEventTarget}.
 *
 * @category Rendering
 * @category Lenses
 */
export class CameraKit {
    /** @deprecated Use {@link lensRepository} */
    readonly lenses: { repository: LensRepository };

    /**
     * Business metrics (e.g. each time a lens is viewed) are emitted here.
     */
    readonly metrics: TypedEventTarget<LensMetricsEvents> = new TypedEventTarget();

    private sessions: CameraKitSession[] = [];

    /** @internal */
    constructor(
        /**
         * Used to query for lenses and lens groups.
         */
        readonly lensRepository: LensRepository,

        private readonly lensCore: LensCore,
        private readonly pageVisibility: PageVisibility,
        private readonly container: Container<RootServices>,
        allMetrics: MetricsEventTarget
    ) {
        this.lenses = { repository: this.lensRepository };
        // Proxy only a subset of all metrics events to the public-facing emitter -- applications don't need to
        // know about most events.
        publicMetricsEventNames.forEach((eventName) => {
            allMetrics.addEventListener(eventName, (e) => this.metrics.dispatchEvent(e));
        });
    }

    /**
     * Create a CameraKitSession.
     *
     * This initializes the rendering engine and returns a {@link CameraKitSession} instance, which provides access
     * to Lens rendering.
     *
     * @example
     * ```ts
     * const cameraKit = await bootstrapCameraKit(config)
     * const session = await cameraKit.createSession()
     *
     * const lens = await cameraKit.lensRepository.loadLens(lensId, groupId)
     * session.applyLens(lens)
     * ```
     *
     * @param options
     */
    @log
    async createSession({
        liveRenderTarget,
        renderWhileTabHidden,
    }: CreateSessionOptions = {}): Promise<CameraKitSession> {
        // Any error happened during lens rendering can be processed by subscribing to sessionErrors
        const exceptionHandler = (error: LensCoreError) => {
            if (error.name === "LensCoreAbortError") {
                logger.error(
                    lensAbortError(
                        "Unrecoverable error occurred during lens execution. " +
                            "The CameraKitSession will be destroyed.",
                        error
                    )
                );
            } else {
                logger.error(
                    lensExecutionError(
                        "Error occurred during lens execution. " +
                            "The lens cannot be rendered and will be removed from the CameraKitSession.",
                        error
                    )
                );
            }
        };

        /**
         * If/when we add support for multiple concurrent sessions, we'll need to create a copy of the LensCore WASM
         * module. If we move managing web workers into JS, spawing a new worker thread with its own copy of LensCore
         * probably becomes a lot more straightforward.
         *
         * Currently chromium has a bug preventing rendering while tab is hidden when LensCore is in worker mode.
         * In order to process tab while it is hidden, the current stopgap is to pass in renderWhileTabHidden as true,
         * which will initiate session in non worker mode, and set the RenderLoopMode to `SetTimeout`.
         */
        await this.lensCore.initialize({
            canvas: liveRenderTarget,
            shouldUseWorker: !renderWhileTabHidden && this.container.get(configurationToken).shouldUseWorker,
            exceptionHandler,
        });

        await this.lensCore.setRenderLoopMode({
            mode: renderWhileTabHidden
                ? this.lensCore.RenderLoopMode.SetTimeout
                : this.lensCore.RenderLoopMode.RequestAnimationFrame,
        });

        // Each session gets its own DI Container – some Services provided by this Container may be shared with the
        // root CameraKit Container, but others may be scoped to the session by passing their token to `copy()`.
        const sessionContainer = this.container
            // Right now this is a no-op. If/when we add support for multiple concurrent sessions, we may end up
            // scoping LensCore to the session.
            .copy()

            .provides(sessionStateFactory)
            .provides(lensStateFactory)
            .provides(lensKeyboardFactory)
            .provides(cameraKitSessionFactory)

            .run(registerLensAssetsProvider)
            .run(registerLensClientInterfaceHandler)
            .run(setPreloadedConfiguration)

            // We'll run a PartialContainer containing reporters for session-scoped metrics. Running this container
            // allows each metric reporter to initialize itself (e.g. by adding event listeners to detect when certain
            // actions occur).
            .run(reportSessionScopedMetrics)

            // UriHandlers may have dependencies on session-scoped services (e.g. LensState, LensKeyboard), so they'll
            // be registered with LensCore here.
            .run(registerUriHandlers);

        const session = sessionContainer.get(cameraKitSessionFactory.token);
        this.sessions.push(session);
        return session;
    }

    /**
     * Destroys all sessions and frees all resources.
     */
    @log
    async destroy() {
        resetLogger();
        this.pageVisibility.destroy();
        await Promise.all(this.sessions.map((session) => session.destroy()));
        this.sessions = [];
    }
}

/** @internal */
export const cameraKitFactory = Injectable(
    "CameraKit",
    [
        lensRepositoryFactory.token,
        metricsEventTargetFactory.token,
        lensCoreFactory.token,
        pageVisibilityFactory.token,
        CONTAINER,
    ] as const,
    (
        lensRepository: LensRepository,
        metrics: MetricsEventTarget,
        lensCore: LensCore,
        pageVisibility: PageVisibility,
        container: Container<RootServices>
    ) => new CameraKit(lensRepository, lensCore, pageVisibility, container, metrics)
);
