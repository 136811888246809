import { combineLatestWith, filter, from, map, merge, mergeMap, of, raceWith, switchMap, take, takeUntil } from "rxjs";
import { forActions, inStates, isState } from "@snap/state-management";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { MetricsEventTarget, metricsEventTargetFactory } from "../metricsEventTarget";
import { CameraKitSession, cameraKitSessionFactory } from "../../session/CameraKitSession";
import { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import { getTimeMs } from "../../common/time";
import {
    operationalMetricReporterFactory,
    OperationalMetricsReporter,
} from "../operational/operationalMetricsReporter";
import { CameraKitConfiguration, configurationToken } from "../../configuration";
import { lensStateFactory, LensState } from "../../session/lensState";
import { SessionState, sessionStateFactory } from "../../session/sessionState";
import { Histogram } from "../operational/Histogram";
import { IndexedDBPersistence } from "../../persistence/IndexedDBPersistence";
import { ExpiringPersistence } from "../../persistence/ExpiringPersistence";
import { dayFormatter, monthFormatter } from "../../common/date";

// We ignore short-duration lens views.
//
// The value is documented here:
// https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY/edit#heading=h.q5liip76r9lt
const viewTimeThresholdSec = 0.1;

async function isFirstTimeWithinPeriods(lensId: string, persistence: ExpiringPersistence<Date>) {
    let isLensFirstWithinDay = false;
    let isLensFirstWithinMonth = false;

    try {
        const lensLastViewDate = await persistence.retrieve(lensId);
        const currentDate = new Date();

        if (!lensLastViewDate) {
            isLensFirstWithinDay = true;
            isLensFirstWithinMonth = true;
        } else {
            isLensFirstWithinDay = dayFormatter.format(lensLastViewDate) !== dayFormatter.format(currentDate);
            isLensFirstWithinMonth = monthFormatter.format(lensLastViewDate) !== monthFormatter.format(currentDate);
        }

        await persistence.store(lensId, currentDate);
    } catch (error) {
        console.error(`Error handling persistence for lensId ${lensId}: ${error}`);
        isLensFirstWithinDay = false;
        isLensFirstWithinMonth = false;
    }

    return { isLensFirstWithinDay, isLensFirstWithinMonth };
}

/**
 * The LensView metric is emitted after a lens has been viewed (for longer than 100ms), when the lens is turned off.
 *
 * It contains information about rendering performance.
 *
 * Notes:
 *   - If the page is hidden (e.g. user switches to a different tab, or application, or closes the tab, or closes the
 *     browser, navigates to a new page, refreshes, etc.) this metric will be emitted at that time. This is to ensure
 *     we don't lose the metric if the page is closed.
 *   - If the page is hidden and then made visible again later (e.g. user switches to a different tab, then back), we
 *     will begin measuring a new LensView. That is, we will not capture the time when the page is hidden even if the
 *     lens is still rendering in the background.
 *
 * @category Lenses
 * @category Metrics
 */
// This type corresponds to the internal CameraKitLensSwipe event, described here:
// https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY#heading=h.q5liip76r9lt
export type LensView = MakeTaggedBusinessEvent<"lensView">;

/**
 * @internal
 */
export const reportLensView = Injectable(
    "reportLensView",
    [
        cameraKitSessionFactory.token,
        lensStateFactory.token,
        sessionStateFactory.token,
        metricsEventTargetFactory.token,
        operationalMetricReporterFactory.token,
        configurationToken,
    ] as const,
    async (
        session: CameraKitSession,
        lensState: LensState,
        sessionState: SessionState,
        metricsEventTarget: MetricsEventTarget,
        operationalMetricsReporter: OperationalMetricsReporter,
        configuration: CameraKitConfiguration
    ): Promise<void> => {
        // We need to do this await up front so that it won't interrupt reporting the metric when the session is
        // suspended -- suspension could happen because the tab is closing, in which case we cannot perform await a
        // Promise, because in the case of a tab close the browser will not schedule any work for future turns of the
        // event loop.
        const { cluster: performanceCluster, webglRendererInfo } = (await configuration.lensPerformance) ?? {
            cluster: 0,
            webglRendererInfo: "unknown",
        };

        const lensViewPersistence = new ExpiringPersistence<Date>(
            // 60 days expiration
            () => 60 * 24 * 60 * 60,
            new IndexedDBPersistence({ databaseName: "recentLensViews" })
        );

        merge(
            // Begin measuring LensCore apply time once the lens has finished downloading and we actually add the lens
            // to LensCore (LensWait measures the full download + LensCore apply time i.e. perceived UX latency).
            lensState.events.pipe(
                forActions("downloadComplete"),
                map(([a]) => a.data)
            ),

            // If the session is resumed (e.g. user returns to this tab while a lens is on), we count this as a new
            // LensView (and applyDelaySec will be 0).
            lensState.events.pipe(
                inStates("lensApplied"),
                switchMap(([, s]) =>
                    sessionState.events.pipe(
                        forActions("resume"),
                        takeUntil(lensState.events.pipe(forActions("removeLens"))),
                        map(() => s.data)
                    )
                )
            )
        )
            .pipe(
                map((lens): [number, string, string] => [getTimeMs(), lens.id, lens.groupId]),
                mergeMap(([applyLensStartTime, lensId, lensGroupId]) => {
                    const alreadyOn = isState(lensState.getState(), "lensApplied");

                    const applyDelay = alreadyOn
                        ? of(0)
                        : lensState.events.pipe(
                              forActions("resourcesLoaded"),
                              filter(([a]) => a.data.id === lensId),
                              // Applying a new lens may happen before removing the old one, so if we kept taking events
                              // we would get the lensResourcesLoaded for the next lens, too.
                              take(1),
                              map(() => (getTimeMs() - applyLensStartTime) / 1000)
                          );

                    const viewMetrics = (
                        alreadyOn
                            ? of([getTimeMs(), session.metrics.beginMeasurement()] as const)
                            : lensState.events.pipe(
                                  forActions("turnedOn"),
                                  filter(([a]) => a.data.id === lensId),
                                  map(() => [getTimeMs(), session.metrics.beginMeasurement()] as const)
                              )
                    ).pipe(
                        take(1),
                        mergeMap(([lensTurnedOnTime, metricsMeasurement]) =>
                            lensState.events.pipe(
                                forActions("turnedOff"),
                                // Applying a new lens may happen before removing the old one, so we'll get a
                                // lensTurnedOff for the prior lens (if one was applied), which we must filter out.
                                filter(([a]) => a.data.id === lensId),
                                // If the session is suspended, we'll count that as the lens turning off.
                                raceWith(sessionState.events.pipe(forActions("suspend"))),
                                map(() => {
                                    metricsMeasurement.end();
                                    return {
                                        viewTimeSec: (getTimeMs() - lensTurnedOnTime) / 1000,
                                        ...metricsMeasurement.measure(),
                                    };
                                })
                            )
                        )
                    );

                    return applyDelay.pipe(
                        combineLatestWith(viewMetrics, from(isFirstTimeWithinPeriods(lensId, lensViewPersistence))),
                        // This lens should always receive the lensTurnedOff action *before* the next lens is
                        // turned on. But just in case that assumption is violated, we'll clean up
                        // (and not report) if another lens turns on before our lens is turned off.
                        takeUntil(
                            lensState.events.pipe(
                                forActions("turnedOn"),
                                filter(([a]) => a.data.id !== lensId)
                            )
                        ),
                        take(1),
                        map(([applyDelaySec, viewMetrics, isFirstTimeResults]) => ({
                            applyDelaySec,
                            lensId,
                            lensGroupId,
                            ...viewMetrics,
                            ...isFirstTimeResults,
                        }))
                    );
                })
            )
            .subscribe({
                next: async ({
                    applyDelaySec,
                    lensId,
                    lensGroupId,
                    viewTimeSec,
                    avgFps,
                    lensFrameProcessingTimeMsAvg,
                    lensFrameProcessingTimeMsStd,
                    lensFrameProcessingTimeMsMedian,
                    lensFrameProcessingN,
                    isLensFirstWithinDay,
                    isLensFirstWithinMonth,
                }) => {
                    if (viewTimeSec < viewTimeThresholdSec) return;

                    const lensView: LensView = {
                        name: "lensView",
                        applyDelaySec,
                        avgFps,
                        lensId,
                        lensGroupId,
                        lensFrameProcessingTimeMsAvg,
                        lensFrameProcessingTimeMsStd,
                        // We don't support recording video, but applications may do this without our knowledge.
                        recordingTimeSec: 0,
                        viewTimeSec,
                        isLensFirstWithinDay,
                        isLensFirstWithinMonth,
                        performanceCluster,
                        webglRendererInfo,
                    };

                    metricsEventTarget.dispatchEvent(new TypedCustomEvent("lensView", lensView));
                    operationalMetricsReporter.report(Histogram.level("lens_view", viewTimeSec * 1000));

                    // The first few frames will typically take much longer to process (as they might involve requesting
                    // remote assets to be downloaded, or other high-latency initialization steps) -- so we'll skip
                    // reporting views with a very small number of frames.
                    if (lensFrameProcessingN >= 30) {
                        operationalMetricsReporter.report(
                            Histogram.level("lens_view_frame-processing-time", lensFrameProcessingTimeMsMedian, {
                                performance_cluster: performanceCluster.toString(),
                            })
                        );
                    }
                },
            });
    }
);
