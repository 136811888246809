import { CameraKitSource, CameraKitSourceOptions } from "./CameraKitSource";

/**
 * Create a {@link CameraKitSource} from an
 * [HTMLImageElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement).
 *
 * @param image Image element.
 * @param options
 * @param options.cameraType By default we set this to 'user', which is the camera type most Lenses expect.
 * @param options.fpsLimit By default we set no limit on FPS.
 *
 * @category Rendering
 */
export function createImageSource(image: HTMLImageElement, options: CameraKitSourceOptions = {}): CameraKitSource {
    return new CameraKitSource(
        {
            media: image,
        },
        {},
        options
    );
}
