import { ensureError } from "../common/errorHelpers";
import { cacheKeyNotFoundError } from "../namedErrors";
import { getLogger } from "../logger/logger";
import { Persistence, ValidKey } from "../persistence/Persistence";
import { OperationalMetricsReporter } from "../metrics/operational/operationalMetricsReporter";
import { ChainableHandler, RequestMetadata } from "./HandlerChainBuilder";

const logger = getLogger("responseCachingHandler");
const notFound = (key: ValidKey) => cacheKeyNotFoundError(`Response for key ${key} not found in cache.`);
const strategyFailed = (key: ValidKey, cause?: unknown) =>
    new Error(`Network request and cache lookup for key ${key} both failed.`, { cause });

export type CachingStrategy<T> = (
    key: ValidKey,
    cache: Persistence<T>,
    network: (metadata?: RequestMetadata) => Promise<T>
) => Promise<T>;

/**
 * Create a CachingStrategy that first makes a request to the network, falling back to cache if the network request
 * fails. If the network request fails and a prior response has not been cached, an error is returned to the caller.
 */
export const staleIfErrorStrategy =
    <T>(): CachingStrategy<T> =>
    async (key, cache, network) => {
        try {
            const response = await network();
            cache.store(key, response).catch((error) => {
                logger.warn(`staleIfErrorStrategy failed to store key ${key}.`, error);
            });
            return response;
        } catch (networkError) {
            try {
                const cachedResponse = await cache.retrieve(key);
                if (!cachedResponse) throw notFound(key);
                logger.debug(
                    `staleIfErrorStrategy successfully fell back to cache for key ${key} after network error.`,
                    networkError
                );
                return cachedResponse;
            } catch (cacheError) {
                const error = ensureError(cacheError);
                error.cause = networkError;
                throw strategyFailed(key, error);
            }
        }
    };

/**
 * If provided these options allow staleWhileRevalidateStrategy to report cache_miss metrics.
 */
export interface StaleWhileRevalidateOptions {
    requestType: string;
    reporter: OperationalMetricsReporter;
}
/**
 * Create a CachingStrategy that first does a cache lookup – if the response is found in cache, it is returned and the
 * entry is updated with a request to the network in the background. If no cached response is found, the network request
 * is made, the result cached and returned to the caller.
 */
export const staleWhileRevalidateStrategy =
    <T>(options?: StaleWhileRevalidateOptions): CachingStrategy<T> =>
    async (key, cache, network) => {
        try {
            const cachedResponse = await cache.retrieve(key);
            if (!cachedResponse) throw notFound(key);

            // By specifying isSideEffect: true, the handler chain allows the network handler to run to completion,
            // even though we return an immediate response from the cache. In the typical use-case, once a response has
            // resolved, any ongoing handlers are aborted because the handler chain knows their result will not be
            // used -- but here, the network handler is run as a side-effect to update the cache after the cached
            // response has been resolved.
            network({ isSideEffect: true })
                .then((response) => cache.store(key, response))
                .catch((error) => {
                    logger.warn(`staleWhileRevalidateStrategy failed to retrieve and store key ${key}.`, error);
                });
            return cachedResponse;
        } catch (cacheError) {
            options?.reporter.count("cache_miss", 1, new Map([["request_type", options.requestType]]));
            try {
                const response = await network();
                cache.store(key, response).catch((error) => {
                    logger.warn(`staleWhileRevalidateStrategy failed to store key ${key}.`, error);
                });
                logger.debug(
                    `staleWhileRevalidateStrategy successfully fell back to network for key ${key} after cache error.`,
                    cacheError
                );
                return response;
            } catch (networkError) {
                const error = ensureError(networkError);
                error.cause = cacheError;
                throw strategyFailed(key, error);
            }
        }
    };

/**
 * Create a Handler capable of caching responses using various caching strategies.
 *
 * More than one caching strategy can be provided, and they will be composed into a single strategy. For example, an
 * expiringStrategy could be composed with a staleIfErrorStrategy so that responses
 *
 * @param cache A Persistence instance capable of storing responses.
 * @param resolveKey This function is called once for each request, and must return a valid persistence key
 * corresponding uniquely to that request.
 * @param strategy A CachingStrategy used to determine when to retrieve from cache vs. request from the network.
 * @returns
 */
export const createResponseCachingHandler = <Req, Res, Meta extends RequestMetadata>(
    cache: Persistence<Res>,
    resolveKey: (request: Req, metadata?: Meta) => ValidKey,
    strategy: CachingStrategy<Res>
): ChainableHandler<Req, Res, Req, Res, Meta> => {
    return (next) => async (request, metadata) => {
        const network = (additionalMetadata: RequestMetadata = {}) => {
            const m = { ...metadata, ...additionalMetadata } as Meta;
            return next(request, m);
        };

        let key: ValidKey;
        try {
            key = resolveKey(request, metadata);
        } catch (error) {
            logger.warn("Cache lookup failed because the cache key could not be resolved.", error);
            return network();
        }
        return strategy(key, cache, network);
    };
};
