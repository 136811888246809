/**
 * Returns a stack trace for a given error, and also appends the stack trace of any nested error, if one exists.
 * @param error Error to stringify.
 * @returns Error stack trace.
 */
export function stringifyError(error: Error): string {
    const outer = error.stack ?? "";
    return error.cause ? `${outer}\nCaused by:\n\t${stringifyError(ensureError(error.cause))}` : outer;
}

/**
 * If given a value of type Error, return it – otherwise wrap the value in an Error.
 */
export function ensureError(error: unknown): Error {
    if (error instanceof Error) return error;

    try {
        return new Error(`Non-Error type exception thrown. Serialized error value: ${JSON.stringify(error)}`);
    } catch (_) {
        return new Error("Non-Error type exception thrown. Original error value could not be serialized.");
    }
}
