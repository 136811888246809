import { Injectable } from "../../dependency-injection/Injectable";
import { Lens } from "../Lens";
import {
    RequestStateEventTarget,
    dispatchRequestCompleted,
    dispatchRequestErrored,
    dispatchRequestStarted,
    requestStateEventTargetFactory,
} from "../../handlers/requestStateEmittingHandler";
import { AssetDownloadDimensions } from "../../metrics/reporters/reportLensAndAssetDownload";
import {
    LensAssetManifestItem,
    LensAssetManifestItem_RequestTiming,
    LensAssetManifestItem_Type,
} from "../../generated-proto/pb_schema/camera_kit/v3/lens";
import { AssetDescriptor, AssetType, LensCore, lensCoreFactory } from "../../lens-core-module";
import { assertUnreachable } from "../../common/assertions";
import { getLogger } from "../../logger/logger";
import { MetricsEventTarget, metricsEventTargetFactory } from "../../metrics/metricsEventTarget";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { deviceDependentAssetLoaderFactory } from "./deviceDependentAssetLoader";
import { remoteMediaAssetLoaderFactory } from "./remoteMediaAssetLoaderFactory";
import { staticAssetLoaderFactory } from "./staticAssetLoader";

const logger = getLogger("LensAssetRepository");

/**
 * Computes cache key for asset ID and loader type pair.
 * @param asset Asset ID and loader type pair.
 * @returns Cache key.
 */
function getCacheKey(asset: AssetDescriptor) {
    return `${asset.assetId}_${asset.assetType.value}`;
}

export function mapManfiestItemToAssetType(lensCore: LensCore, type: LensAssetManifestItem_Type): AssetType {
    switch (type) {
        case LensAssetManifestItem_Type.ASSET:
            return lensCore.AssetType.Static;
        case LensAssetManifestItem_Type.DEVICE_DEPENDENT_ASSET_UNSET:
        case LensAssetManifestItem_Type.UNRECOGNIZED:
            return lensCore.AssetType.DeviceDependent;
        default:
            return assertUnreachable(type);
    }
}

export interface Asset {
    assetId: string;
    assetBuffer: ArrayBuffer;
    assetType: AssetType;
    assetChecksum: string | undefined;
}

export type AssetResponse =
    | ArrayBuffer
    | {
          data: ArrayBuffer;
          checksum?: string;
      };

/**
 * An AssetLoader is used to retrieve assets. A separate loader may be defined to retrieve different asset types.
 *
 * @category Lenses
 */
export type AssetLoader = (
    asset: AssetDescriptor,
    lens?: Lens,
    assetManifest?: LensAssetManifestItem[]
) => Promise<AssetResponse> | AssetResponse;

/**
 * Registers a remote asset provider function with a given instance of LensCore, and uses a provided mapping of asset
 * types to loading functions to acquire remote asset data and pass it to LensCore.
 *
 * *Note:* LensCoreModule.initialize must be called on the desired LensCoreModule instance **prior** to passing it
 * to the LensAssetProvider constructor. If this class is instantiated with a LensCoreModule that has not been
 * initialized, the registry of the asset provider function will fail silently and no remote assets will be loaded.
 */
export class LensAssetRepository {
    private readonly cachedAssetKeys = new Set<string>();

    constructor(
        private readonly lensCore: LensCore,
        private readonly assetLoaders: Map<AssetType, [keyof LensCore["AssetType"], AssetLoader]>,
        private readonly metrics: MetricsEventTarget,
        private readonly requestStateEventTarget: RequestStateEventTarget
    ) {}

    /**
     * Caches lens assets defined in asset manifest.
     *
     * @param assetManifest Lens asset manifest.
     * @param lens Lens to cache assets of.
     * @param assetTimings Optionally specifies what assets to cache. By default, on-demand assets are not cached.
     * @returns Promise rejects if any required assets could not be loaded – if this occurs, it's very likely the Lens
     * with this manifest will not function.
     */
    public async cacheAssets(
        assetManifest: LensAssetManifestItem[],
        lens: Lens,
        assetTimings: LensAssetManifestItem_RequestTiming[] = [LensAssetManifestItem_RequestTiming.REQUIRED]
    ): Promise<void> {
        const assetTimingsToPreload = new Set([
            // That is a bad naming, but PRELOAD_UNSET actually means
            // that an asset has to be preloaded
            LensAssetManifestItem_RequestTiming.PRELOAD_UNSET,
            ...assetTimings,
        ]);
        const assetDescriptors = assetManifest
            .filter((asset) => {
                return assetTimingsToPreload.has(asset.requestTiming);
            })
            .map(({ id, type }) => ({
                assetId: id,
                assetType: mapManfiestItemToAssetType(this.lensCore, type),
            }));

        if (assetDescriptors.length) {
            // When preloading, we *do* want load failures to reject Promise.all (assets listed in the manifest
            // are known to be hard requirements of the lens).
            return this.cacheAssetsByDescriptor(assetDescriptors, lens, assetManifest);
        }
    }

    /**
     * Calls the correct asset loader to fetch the asset's data,
     * depending on the requested asset's type and provides that to LensCore.
     */
    public async loadAsset(
        assetDescriptor: AssetDescriptor,
        lens: Lens | undefined,
        assetManifest: LensAssetManifestItem[] | undefined
    ): Promise<void> {
        const { assetId, assetType } = assetDescriptor;
        const [assetTypeName, assetLoader] = this.assetLoaders.get(assetType) ?? [];
        const safeAssetTypeName = assetTypeName ?? "unknown";
        const dimensions: AssetDownloadDimensions = {
            requestType: "asset",
            assetId: assetId,
            assetType: safeAssetTypeName,
            lensId: lens?.id ?? "unknown",
        };

        const { requestId } = dispatchRequestStarted(this.requestStateEventTarget, { dimensions });

        try {
            if (!assetLoader) {
                throw new Error(`Cannot get asset ${assetId}. Asset type ${safeAssetTypeName} is not supported.`);
            }

            const assetResponse = await assetLoader(assetDescriptor, lens, assetManifest);
            const assetBuffer = "data" in assetResponse ? assetResponse.data : assetResponse;
            const assetChecksum = "checksum" in assetResponse ? assetResponse.checksum : undefined;

            if (assetBuffer.byteLength === 0) {
                throw new Error(`Got empty response for asset ${assetId} from ${safeAssetTypeName} loader.`);
            }

            dispatchRequestCompleted(this.requestStateEventTarget, {
                requestId,
                dimensions,
                status: 200,
                sizeByte: assetBuffer.byteLength,
            });

            this.lensCore.provideRemoteAssetsResponse({
                assetId,
                assetBuffer,
                assetType,
                assetChecksum,
                onFailure: (lensCoreError) => {
                    if (/validation failed/.test(lensCoreError.message)) {
                        this.metrics.dispatchEvent(
                            new TypedCustomEvent("assetValidationFailed", {
                                name: "assetValidationFailed",
                                assetId,
                            })
                        );
                    }
                    logger.warn(`Failed to provide lens asset ${assetId}.`, lensCoreError);
                },
            });
        } catch (error) {
            const wrappedError = new Error(`Failed to load lens asset ${assetId}.`, { cause: error });
            dispatchRequestErrored(this.requestStateEventTarget, { requestId, dimensions, error: wrappedError });
            throw wrappedError;
        }
    }

    /**
     * Downloads and caches assets if applicable. Does nothing for assets that are already in cache.
     * @param assetDescriptors Asset ID and type pairs.
     * @param lens Lens to load assets for.
     * @param assetManifest Lens asset manifest.
     */
    private async cacheAssetsByDescriptor(
        assetDescriptors: AssetDescriptor[],
        lens: Lens,
        assetManifest: LensAssetManifestItem[] | undefined
    ): Promise<void> {
        await Promise.all(
            assetDescriptors
                .filter((assetDescriptors) => !this.cachedAssetKeys.has(getCacheKey(assetDescriptors)))
                .map(async (assetDescriptor) => {
                    try {
                        // NOTE: we allow concurrent cache request to download the same asset more than once,
                        // because that is better than skipping second request when the firs one fails.
                        // In future we could improve concurretn logic with observables as part of
                        // https://jira.sc-corp.net/browse/CAMKIT-3931
                        await this.loadAsset(assetDescriptor, lens, assetManifest);
                        this.cachedAssetKeys.add(getCacheKey(assetDescriptor));
                    } catch (error) {
                        const { assetId, assetType } = assetDescriptor;
                        const [assetTypeName] = this.assetLoaders.get(assetType) ?? [];
                        logger.warn(
                            `Failed to cache asset ${assetId} of type ${assetTypeName ?? assetType.value}.`,
                            error
                        );
                    }
                })
        );
    }
}

/**
 * @internal
 */
export const lensAssetRepositoryFactory = Injectable(
    "lensAssetRepository",
    [
        lensCoreFactory.token,
        deviceDependentAssetLoaderFactory.token,
        remoteMediaAssetLoaderFactory.token,
        staticAssetLoaderFactory.token,
        metricsEventTargetFactory.token,
        requestStateEventTargetFactory.token,
    ] as const,
    (
        lensCore: LensCore,
        deviceDependentAssetLoader: AssetLoader,
        remoteMediaAssetLoader: AssetLoader,
        staticAssetLoader: AssetLoader,
        metrics: MetricsEventTarget,
        requestStateEventTarget: RequestStateEventTarget
    ) =>
        new LensAssetRepository(
            lensCore,
            new Map([
                [lensCore.AssetType.DeviceDependent, ["DeviceDependent", deviceDependentAssetLoader]],
                [lensCore.AssetType.RemoteMediaByUrl, ["RemoteMediaByUrl", remoteMediaAssetLoader]],
                // URL type is deprecated and was introduced before RemoteMediaByURL
                // however, there are some lenses still using it so we need to support it
                [lensCore.AssetType.URL, ["URL", remoteMediaAssetLoader]],
                [lensCore.AssetType.Static, ["Static", staticAssetLoader]],
            ]),
            metrics,
            requestStateEventTarget
        )
);
