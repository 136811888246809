import { dayFormatter, monthFormatter } from "../../common/date";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { DailySessionBucket } from "../../generated-proto/blizzard/cameraKitEvents";
import { IndexedDBPersistence } from "../../persistence/IndexedDBPersistence";
import { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import { MetricsEventTarget, metricsEventTargetFactory } from "../metricsEventTarget";

interface UserSessionInfo {
    mostRecentSessionStartDate: Date;
    dailySessionBucket: DailySessionBucket;
}

/**
 * The Session metric reports each user session.
 */
export type Session = MakeTaggedBusinessEvent<"session">;

/**
 * @internal
 */
export const reportUserSession = Injectable(
    "reportUserSession",
    [metricsEventTargetFactory.token] as const,
    async (metricsEventTarget: MetricsEventTarget): Promise<void> => {
        const userSessionKey = "userSessionInfo";
        const db = new IndexedDBPersistence<UserSessionInfo>({ databaseName: "SessionHistory" });
        // We standardize all user dates to PST as per our documentation:
        // https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY/
        const date = new Date();
        const formattedDate = dayFormatter.format(date);
        const formattedDateParts = dayFormatter.formatToParts(date);
        const { day, month, year } = formattedDateParts.reduce(
            (acc: Record<string, number>, { type, value }) => ({
                ...acc,
                [type]: parseInt(value),
            }),
            {}
        );

        const userSessionInfo = await db.retrieve(userSessionKey);
        const mostRecentSessionStartDate = userSessionInfo?.mostRecentSessionStartDate;
        const formattedMostRecentSessionStartDate = mostRecentSessionStartDate
            ? dayFormatter.format(mostRecentSessionStartDate)
            : null;

        const dailySessionBucketMap = new Map<number, DailySessionBucket>([
            [1, DailySessionBucket.ONE_SESSION],
            [2, DailySessionBucket.TWO_SESSION],
            [3, DailySessionBucket.THREE_SESSION],
            [4, DailySessionBucket.FOUR_SESSION],
            [5, DailySessionBucket.FIVE_SESSION],
            [6, DailySessionBucket.SIX_SESSION],
            [7, DailySessionBucket.SEVEN_SESSION],
            [8, DailySessionBucket.EIGHT_SESSION],
            [9, DailySessionBucket.NINE_SESSION],
        ]);
        let dailySessionBucket = userSessionInfo?.dailySessionBucket ?? DailySessionBucket.NO_SESSION_BUCKET;
        let isFirstWithinMonth = false;
        if (formattedMostRecentSessionStartDate === formattedDate) {
            dailySessionBucket =
                dailySessionBucketMap.get(dailySessionBucket + 1) ?? DailySessionBucket.TEN_OR_MORE_SESSION;
            await db.store(userSessionKey, {
                mostRecentSessionStartDate: date,
                dailySessionBucket,
            });
        } else {
            isFirstWithinMonth =
                !mostRecentSessionStartDate ||
                monthFormatter.format(mostRecentSessionStartDate) !== monthFormatter.format(date);
            await db.store(userSessionKey, {
                mostRecentSessionStartDate: date,
                dailySessionBucket: (dailySessionBucket = DailySessionBucket.ONE_SESSION),
            });
        }
        const session: Session = {
            name: "session",
            dailySessionBucket,
            isFirstWithinMonth,
            month,
            day,
            year,
        };
        metricsEventTarget.dispatchEvent(new TypedCustomEvent("session", session));
    }
);
