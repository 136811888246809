import { isState, forActions } from "@snap/state-management";
import { Injectable } from "../dependency-injection/Injectable";
import { TypedCustomEvent } from "../events/TypedCustomEvent";
import { TypedEventListener, TypedEventListenerOptions, TypedEventTarget } from "../events/TypedEventTarget";
import { UriHandler } from "../extensions/UriHandlers";
import { Lens } from "../lens/Lens";
import { LensState, lensStateFactory } from "./lensState";

/**
 * Events emitted by {@link Keyboard}.
 */
export type KeyboardEvents = TypedCustomEvent<
    "active",
    {
        element: HTMLTextAreaElement;
        active: boolean;
        lens?: Lens;
    }
>;

/**
 * Keyboard is an API enabling lenses to consume and render user-generated text.
 *
 * Applications that wish to use lenses that expect user-generated text will need to use this API to integrate text
 * input into their user experience.
 *
 * There are two ways to do this:
 * 1. Add the provided DOM element (an HTMLTextAreaElement) to the page. When the user updates this element with text,
 * that text will be sent to the currently active lens.
 * 2. Use the {@link sendInputToLens} method to send text strings to the currently active lens directly.
 *
 * Lenses will also signal to the application when text input is expected -- applications should add an event listener
 * and ensure the user is able to input text when the `active` event is received.
 *
 * @example
 * ```ts
 * cameraKitSession.keyboard.addEventListener('active', ({ detail }) => {
 *   const { element, active } = detail
 *   if (active) document.body.appendChild(element)
 *   else element.remove()
 * })
 * ```
 *
 * @category Lenses
 */
export type Keyboard = {
    addEventListener: (
        type: "active",
        callback: TypedEventListener<KeyboardEvents>,
        options?: TypedEventListenerOptions
    ) => void;
    removeEventListener: (type: "active", callback: TypedEventListener<KeyboardEvents>) => void;

    /**
     * Get an HTMLTextAreaElement that communicates text to the active Lens.
     */
    getElement: () => HTMLTextAreaElement;

    /**
     * Send text to the active Lens. Also updates the provided HTMLTextAreaElement.
     *
     * @param text String to render. This can include escape sequences, such as the newline character ( \n ) for
     * multi-line input.
     */
    sendInputToLens: (text: string) => void;

    /**
     * Clears the provided HTMLTextAreaElement, and emits the "active" event with `active == false`, allowing the
     * application to e.g. remove relevant text input elements from the DOM.
     */
    dismiss: () => void;
};

/** @internal */
export class LensKeyboard {
    public readonly uriHandler: UriHandler;
    private readonly events: TypedEventTarget<KeyboardEvents>;
    private readonly element: HTMLTextAreaElement;
    private active: boolean;
    private handleReply: (text: string) => void;

    constructor(private readonly lensState: LensState) {
        this.active = false;
        this.element = document.createElement("textarea");
        this.element.addEventListener("keypress", (event: KeyboardEvent) => {
            if (event.code === "Enter" && !event.shiftKey) {
                event.preventDefault();
                this.handleReply(this.element.value);
            }
        });
        this.events = new TypedEventTarget<KeyboardEvents>();
        this.handleReply = () => {};
        this.uriHandler = {
            uri: "app://textInput/requestKeyboard",
            handleRequest: (_request, reply) => {
                this.element.autofocus = true;
                this.handleReply = (text: string) => {
                    const opt = {
                        text: text,
                        start: text.length,
                        end: text.length,
                        done: true,
                        shouldNotify: true,
                    };
                    const output = new TextEncoder().encode(JSON.stringify(opt));
                    reply({
                        code: 200,
                        description: "",
                        contentType: "application/json",
                        data: output,
                    });
                };
                this.active = true;
                this.updateStatus();
                this.element.focus();
            },
        };
        lensState.events.pipe(forActions("turnedOff")).subscribe(() => {
            this.dismiss();
        });
    }

    dismiss(): void {
        if (this.active) {
            this.active = false;
            this.element.value = "";
            this.updateStatus();
        }
    }

    getElement(): HTMLTextAreaElement {
        return this.element;
    }

    sendInputToLens(text: string): void {
        this.element.value = text;
        this.handleReply(text);
    }

    addEventListener(
        type: "active",
        callback: TypedEventListener<KeyboardEvents>,
        options?: TypedEventListenerOptions
    ): void {
        this.events.addEventListener(type, callback, options);
    }

    removeEventListener(type: "active", callback: TypedEventListener<KeyboardEvents>): void {
        this.events.removeEventListener(type, callback);
    }

    toPublicInterface(): Keyboard {
        return {
            addEventListener: this.addEventListener.bind(this),
            removeEventListener: this.removeEventListener.bind(this),
            getElement: this.getElement.bind(this),
            sendInputToLens: this.sendInputToLens.bind(this),
            dismiss: this.dismiss.bind(this),
        };
    }

    private updateStatus(): void {
        const state = this.lensState.getState();
        // If lens keyboard status is changing, we know a lens must be applied.
        if (isState(state, "noLensApplied")) return;
        this.events.dispatchEvent(
            new TypedCustomEvent("active", {
                element: this.element,
                active: this.active,
                // If the keyboard is up, it has been triggered by an active lens.
                lens: state.data,
            })
        );
    }
}

/**
 * @internal
 */
export const lensKeyboardFactory = Injectable(
    "lensKeyboard",
    [lensStateFactory.token] as const,
    (lensState: LensState) => new LensKeyboard(lensState)
);
