import { BehaviorSubject, map, mergeMap, NEVER, Observable, of, raceWith, startWith, Subject, take, tap, withLatestFrom } from 'rxjs';
/**
 * A StateMachine takes a finite set of both Action and State types, represeting the allowed states of the machine and
 * the possible actions which may (or may not) cause a transition between states.
 *
 * The following algorithm is used to update state. For those who have used Redux, this will sound familiar:
 *
 * 1. The user of the state machine dispatches one of the allowed Actions.
 * 2. The [action, current state] pair is passed to each of the "reducers" configured for the state machine. These
 *    reducers must (synchronously) produce a new state, or do nothing.
 * 3. The state machine's internal state is updated to the new state (or remains in the old state if no reducer produced
 *    a new state).
 * 4. Notify all configured "effects" with the [action, new state] pair – effects are notified even if the state did not
 *    change. Effects may produce their own actions. This will feel familiar to users of redux-observable.
 *
 * We can visualize this with the following diagram:
 *
 * ```txt
 * dipatch Action
 *    |
 *    |
 * reducers run on [Action, Starting State] and return New State
 *    |
 *    |
 * the state machine's internal state is updated to New State
 *    |
 *    |
 * effects run on [Action, New State]
 * ```
 */
/** @internal */
export class StateMachine {
    actions;
    states;
    /**
     * After an Action is dispatched, passed to the StateMachine's reducer to produce a new state (or stay in the same
     * state), a pair of [Action, State] is emitted on this Observable.
     *
     * This can be used to observe every action dispatched to the StateMachine, along with the state that it produced.
     *
     * The most common use of the `events` Observable is to implement side-effects. It's very common for side-effects
     * to dispatch actions back to the StateMachine.
     *
     * ```ts
     * stateMachine.events.pipe(
     *   inStates('idle'),
     *   forActions('makeRequest'),
     *   switchMap(([{data: request}]) => fromFetch(request)),
     *   tap(response => stateMachine.dispatch('requestComplete', response)),
     * ).subscribe()
     * ```
     */
    events;
    actionsSubject;
    eventsSubject;
    state;
    /**
     * Create a StateMachine with a defined set of Actions and States.
     *
     * The StateMachine begins in a given initial state with transitions to new states defined by a "reducer" function.
     *
     * Reducers are OperatorFunctions which map an Observable of `[Action, State]` pairs into an Observable of a new
     * state. They can be constructed using RxJS's `pipe` function. For example:
     *
     * ```ts
     * new StateMachine(actions, states, initialState, pipe(
     *   inStates('someState'),
     *   forActions('someAction'),
     *   map(([a, s]) => computeNewState(a, s)),
     * ))
     * ```
     *
     * Often it will be useful to break the reducer into separate behaviors to handle different actions:
     *
     * ```ts
     * new StateMachine(actions, states, initialState, state => {
     *   return merge(
     *     state.pipe(inStates('someState'), forAction('someAction'), map(computeNextState)),
     *     state.pipe(inStates('otherState'), forAction('otherAction'), map(computeOtherNextState)),
     *     // ...
     *   )
     * })
     * ```
     *
     * Reducers must return an output Observable<State> which, when the input Observable<[Action, State]> emits, either:
     *
     * - synchronously emits a single new state.
     * - emits nothing.
     *
     * To enforce this, reducer Observables race with a sync Observable containing the current state. If the reducer
     * Observable does not emit a new state synchronously, the current state is used (i.e. the state does not change).
     *
     * @param initialState
     * @param reducer
     * @returns
     */
    constructor(actions, states, initialState, reducer) {
        this.actions = actions;
        this.states = states;
        this.actionsSubject = new Subject();
        this.eventsSubject = new Subject();
        this.events = this.eventsSubject.asObservable();
        this.state = new BehaviorSubject(initialState);
        this.actionsSubject
            .pipe(withLatestFrom(this.state), mergeMap(([a, s]) => 
        // `NEVER` + `startWith` is needed for `raceWith` to work properly. If we instead used the more
        // typical `of([a, s])` we would encounter the following problem:
        //
        // `of` emits its value and completes synchronously (i.e. both in the same "frame," if you think in
        // marble diagrams). Assuming `reducer` does nothing to modify the completion behavior of its source
        // Observable, the Observable returned by `reducer` will also complete immediately. If we were to
        // pass that Observable to `raceWith` – whose output mirrors whichever input Observable is first to
        // emit, error, *or complete* – it would always win, even if it never emitted anything (because it
        // would already be complete).
        //
        // So instead we need an Observable that does not complete. We achieve this by using `NEVER` and
        // then starting it with `[a, s]`. Note that it's important to then use `take(1)` after `raceWith`
        // so that we don't leak Observables which never complete.
        NEVER.pipe(startWith([a, s]), reducer, raceWith(of(s)), tap((newState) => {
            if (newState !== s)
                this.state.next(newState);
        }), map((newState) => [a, newState]), take(1))))
            .subscribe(this.eventsSubject);
    }
    dispatch(actionOrName, data) {
        const action = typeof actionOrName === 'string' ? { name: actionOrName, data } : actionOrName;
        this.actionsSubject.next(action);
    }
    /**
     * Returns the current state.
     */
    getState() {
        return this.state.getValue();
    }
}
/**
 * Transform a source Observable of Actions into an Observable of [Action, State] pairs by:
 * 1. Dispatching the source Actions to the given StateMachine.
 * 3. Return an Observable of that StateMachine's events.
 *
 * This allows callers to dispatch to a StateMachine when the output Observable is subscribed, and then be notified when
 * the StateMachine has produced a new state (which could be the same as the old state).
 *
 * The output Observable will remain subscribed to the StateMachine's events, emitting all subsequent `[Action, State]`
 * pairs. For that reason, it's important to ensure the output Observable is properly unsubscribed to avoid leaks. This
 * is commonly done using the `take`, `takeUntil`, `firstValueFrom`, etc. operators.
 *
 * @param stateMachine The source Observable's Actions will be dispatched to this StateMachine
 * @returns Operator function which transforms an Observable<Action> into an Observable<[Action, State]> by subscribing
 * to the given StateMachine's events and then dispatching source actions to it when they arrive.
 */
export function dispatch(stateMachine) {
    return (source) => new Observable(subscriber => {
        const subscription = stateMachine.events.subscribe(subscriber);
        subscription.add(source.subscribe({
            next: (action) => stateMachine.dispatch(action),
            error: (error) => subscriber.error(error),
            // We purposely do not forward the `complete` notification. We want the subscriber to remain subscribed to
            // the stateMachine.events observable even if the source Observable<Actions> completes.
        }));
        return subscription;
    });
}
