import { Logger } from "./logger";

/**
 * Creates a class member decorator that logs errors thrown during the member invocation.
 * If the member returns a Promise, promise rejections are handled.
 * Any caught errors are re-thrown.
 * @param logger The logger to report errors to.
 * @return A decorator function.
 */
export function errorLoggingDecorator(logger: Logger) {
    const handleError = (error: unknown) => logger.error(error);
    return function (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) {
        const methodName = descriptor.value ? "value" : "get";
        const originalMethod = descriptor[methodName];
        descriptor[methodName] = function (...args: any[]) {
            try {
                // "this" refers to original scope this function is called in
                const result = originalMethod.apply(this, args);
                if (result instanceof Promise) {
                    // in case of a promise we don't return our promise chain
                    // that logs an error because we want callers to handle the error as well
                    result.catch(handleError);
                }
                return result;
            } catch (error) {
                handleError(error);
                throw error;
            }
        };
        return descriptor;
    };
}
