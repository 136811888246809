import { Injectable } from "../../dependency-injection/Injectable";
import { getPlatformCapabilities } from "../../platform/platformCapabilities";
import { Count } from "../operational/Count";
import {
    OperationalMetricsReporter,
    operationalMetricReporterFactory,
} from "../operational/operationalMetricsReporter";

/**
 * Report the number of total page loads that have support for various capabilities. By dividing by the total number of
 * data points recorded, we can calculate the percent of page loads with support.
 *
 * @internal
 */
export const reportPlatformCapabilities = Injectable(
    "reportPlatformCapabilities",
    [operationalMetricReporterFactory.token] as const,
    async (operationalMetricsReporter: OperationalMetricsReporter) => {
        const { webgl, wasm, webxr } = await getPlatformCapabilities();
        operationalMetricsReporter.report(Count.count("platform_webgl", webgl.supported ? 1 : 0));
        operationalMetricsReporter.report(Count.count("platform_wasm", wasm.supported ? 1 : 0));
        operationalMetricsReporter.report(Count.count("platform_webxr", webxr.supported ? 1 : 0));
    }
);
