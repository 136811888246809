import { Injectable } from "../../dependency-injection/Injectable";
import { FetchHandler } from "../../handlers/defaultFetchHandler";
import { HandlerChainBuilder } from "../../handlers/HandlerChainBuilder";
import { createBatchingHandler } from "../../handlers/batchingHandler";
import {
    OperationalMetric,
    OperationalMetricsBundle,
} from "../../generated-proto/pb_schema/camera_kit/v3/operational_metrics";
import { SetOperationalMetricsRequest } from "../../generated-proto/pb_schema/camera_kit/v3/service";
import { createMappingHandler } from "../../handlers/mappingHandler";
import { PageVisibility, pageVisibilityFactory } from "../../common/pageVisibility";
import { metricsHandlerFactory } from "../metricsHandler";
import { CameraKitConfiguration, configurationToken } from "../../configuration";
import { Metric } from "./Metric";

type MetricsHandler = (metric: OperationalMetric) => Promise<void>;
type CountMetricValue = Extract<OperationalMetric["metric"], { $case: "count" }>;

// CameraKit's prod metrics endpoint.
// See: https://github.sc-corp.net/Snapchat/pb_schema/blob/c390b9c/proto/camera_kit/v3/service.proto#L126

const DIMENSION_DELIMITER = ".";

// These values are (currently) arbitrarily selected.
// TODO: Once we have gathered a sufficient quantity of metrics data, we should tune these numbers to ensure we're
// operating with the right cost vs. alarming SLA vs. IDB storage size tradeoffs.
const METRIC_BATCH_MAX_SIZE = 100;
const METRIC_BATCH_MAX_AGE_MS = 5000;

/**
 * Use this class to report operational metrics – these are metrics that describe aspects of the SDK's performance,
 * which may be used to assess and investigate operational issues.
 */
/** @internal */
export class OperationalMetricsReporter {
    constructor(private readonly metricsHandler: MetricsHandler) {}

    /**
     * Record a count.
     *
     * @param name
     * @param count
     * @param dimensions An optional Map containing dimensions which describe the metric.
     * For example: `new Map([['status', '200']])`
     * @returns Promise which resolves when the metric has been handled.
     */
    count(name: string, count: number, dimensions?: Map<string, string>): Promise<void> {
        return this.record(name, { $case: "count", count }, dimensions);
    }

    /**
     * Record a duration in milliseconds.
     *
     * @param name
     * @param latencyMillis
     * @param dimensions An optional Map containing dimensions which describe the metric.
     * For example: `new Map([['status', '200']])`
     * @returns Promise which resolves when the metric has been handled.
     */
    timer(name: string, latencyMillis: number, dimensions?: Map<string, string>): Promise<void> {
        return this.record(name, { $case: "latencyMillis", latencyMillis }, dimensions);
    }

    /**
     * Record a histogram.
     *
     * @param name
     * @param histogram
     * @param dimensions An optional Map containing dimensions which describe the metric.
     * For example: `new Map([['status', '200']])`
     * @returns Promise which resolves when the metric has been handled.
     */
    histogram(name: string, histogram: number, dimensions?: Map<string, string>): Promise<void> {
        return this.record(name, { $case: "histogram", histogram }, dimensions);
    }

    /**
     * TODO: This entire class in no longer necessary, since the new Timer/Count/Histogram classes offer a cleaner API
     * for recording metrics. Once we migrate all operational metrics to use those new APIs, this class can be removed
     * and call sites will just call the metrics handler directly.
     *
     * @param metric Any concrete Metric (e.g. Count, Timer, Histogram)
     * @returns
     */
    async report(metric: Metric): Promise<void> {
        await Promise.all(metric.toOperationalMetric().map((metric) => this.metricsHandler(metric)));
    }

    private record(
        name: string,
        metric: Required<OperationalMetric>["metric"],
        dimensions?: Map<string, string | number>
    ): Promise<void> {
        // The naming convention (metricName.dimensionName.dimensionValue.dimensionName.dimensionValue...) is mentioned
        // the Graphene docs here https://wiki.sc-corp.net/display/METRICS/Graphene
        // TODO: find explicit documentation of the API, if it exists.
        const serializedDimensions = dimensions
            ? `.${Array.from(dimensions.entries())
                  .map((d) => d.join(DIMENSION_DELIMITER))
                  .join(DIMENSION_DELIMITER)}`
            : "";

        return this.metricsHandler({
            name: `${name}${serializedDimensions}`,
            timestamp: new Date(),
            metric,
        });
    }
}

/**
 * @internal
 */
export const operationalMetricReporterFactory = Injectable(
    "operationalMetricsReporter",
    [metricsHandlerFactory.token, pageVisibilityFactory.token, configurationToken] as const,
    (metricsHandler: FetchHandler, pageVisibility: PageVisibility, configuration: CameraKitConfiguration) => {
        const handler = new HandlerChainBuilder(metricsHandler)
            .map(
                createMappingHandler((metrics: OperationalMetricsBundle) => {
                    const request: SetOperationalMetricsRequest = { metrics };
                    return new Request(
                        // eslint-disable-next-line max-len
                        `https://${configuration.apiHostname}/com.snap.camerakit.v3.Metrics/metrics/operational_metrics`,
                        {
                            method: "POST",
                            body: JSON.stringify(SetOperationalMetricsRequest.toJSON(request)),
                            credentials: "include",

                            // When this is true it makes fetch behave like `Navigator.sendBeacon` – that is, the
                            // request will still be made even if the page terminates.
                            // https://developer.mozilla.org/en-US/docs/Web/API/fetch
                            keepalive: pageVisibility.isDuringVisibilityTransition("hidden"),
                        }
                    );
                }, pageVisibility)
            )
            .map(
                createBatchingHandler({
                    // The batching logic here is very simple – it could be improved by e.g.
                    // computing statistics to reduce overall data sent, etc. Right now this is
                    // premature optimization, but could become a good idea in the future.
                    batchReduce: (previousBundle: OperationalMetricsBundle | undefined, metric: OperationalMetric) => {
                        let metrics = [...(previousBundle?.metrics ?? [])];

                        // For "count" metrics, it's straightforward to merge them into
                        // a single metric with the same name.
                        const existingCountIndex =
                            metric.metric?.$case === "count"
                                ? metrics.findIndex((m) => metric.name === m.name && m.metric?.$case === "count")
                                : -1;
                        if (existingCountIndex >= 0) {
                            // Safety: Given the condition above, we can be sure that both the existing and new metrics
                            // are of the "count" type.
                            const existingValue = metrics[existingCountIndex].metric as CountMetricValue;
                            const newValue = metric.metric as CountMetricValue;
                            metrics.splice(existingCountIndex, 1, {
                                ...metric,
                                metric: {
                                    $case: "count",
                                    count: existingValue.count + newValue.count,
                                },
                            });
                        } else {
                            metrics.push(metric);
                        }

                        return { metrics };
                    },
                    isBatchComplete: (bundle) => bundle.metrics.length >= METRIC_BATCH_MAX_SIZE,
                    maxBatchAge: METRIC_BATCH_MAX_AGE_MS,
                    pageVisibility,
                })
            ).handler;

        return new OperationalMetricsReporter(handler);
    }
);
