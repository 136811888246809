import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "snapchat.lenses.snappable";
export interface EncryptionData {
    key: Uint8Array;
    ivStore: {
        [key: string]: Uint8Array;
    };
    isKeyServerEncrypted: boolean;
    isKeyE2eEncrypted: boolean;
    encryptionScheme: EncryptionData_EncryptionScheme;
}
export enum EncryptionData_EncryptionScheme {
    NOT_APPLICABLE = "NOT_APPLICABLE",
    SPOOKEY = "SPOOKEY",
    REGISTRY = "REGISTRY",
    FIDELIUS = "FIDELIUS",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function encryptionData_EncryptionSchemeFromJSON(object: any): EncryptionData_EncryptionScheme {
    switch (object) {
        case 0:
        case "NOT_APPLICABLE":
            return EncryptionData_EncryptionScheme.NOT_APPLICABLE;
        case 1:
        case "SPOOKEY":
            return EncryptionData_EncryptionScheme.SPOOKEY;
        case 2:
        case "REGISTRY":
            return EncryptionData_EncryptionScheme.REGISTRY;
        case 3:
        case "FIDELIUS":
            return EncryptionData_EncryptionScheme.FIDELIUS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return EncryptionData_EncryptionScheme.UNRECOGNIZED;
    }
}
export function encryptionData_EncryptionSchemeToNumber(object: EncryptionData_EncryptionScheme): number {
    switch (object) {
        case EncryptionData_EncryptionScheme.NOT_APPLICABLE:
            return 0;
        case EncryptionData_EncryptionScheme.SPOOKEY:
            return 1;
        case EncryptionData_EncryptionScheme.REGISTRY:
            return 2;
        case EncryptionData_EncryptionScheme.FIDELIUS:
            return 3;
        default:
            return 0;
    }
}
export interface EncryptionData_IvStoreEntry {
    key: string;
    value: Uint8Array;
}
export interface PlayerData {
    store: {
        [key: string]: string;
    };
    serializedStore: Uint8Array;
}
export interface PlayerData_StoreEntry {
    key: string;
    value: string;
}
export interface SessionData {
    playerData: {
        [key: string]: PlayerData;
    };
}
export interface SessionData_PlayerDataEntry {
    key: string;
    value: PlayerData | undefined;
}
export interface SnappableMedia {
    sessionId: string;
    playerId: string;
    url: string;
    key: string;
    createdTime: number;
}
export interface Snap3DData {
    allowZippedMedia: boolean;
}
export interface UsesCameraRoll {
    usesCameraRoll: boolean;
}
export interface SnappableMessage {
    snappable: Snappable | undefined;
    media: SnappableMedia[];
    effectId: string;
    userIds: string[];
    focusPointX: number;
    focusPointY: number;
    focusPointWidth: number;
    replyType: SnappableMessage_ReplyType;
    snap3dData: Snap3DData | undefined;
    usesCameraRollData: UsesCameraRoll | undefined;
    assetBatchId: string;
}
export enum SnappableMessage_ReplyType {
    NOT_APPLICABLE = "NOT_APPLICABLE",
    CONTEXT = "CONTEXT",
    INTERSTITIAL = "INTERSTITIAL",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function snappableMessage_ReplyTypeFromJSON(object: any): SnappableMessage_ReplyType {
    switch (object) {
        case 0:
        case "NOT_APPLICABLE":
            return SnappableMessage_ReplyType.NOT_APPLICABLE;
        case 1:
        case "CONTEXT":
            return SnappableMessage_ReplyType.CONTEXT;
        case 2:
        case "INTERSTITIAL":
            return SnappableMessage_ReplyType.INTERSTITIAL;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SnappableMessage_ReplyType.UNRECOGNIZED;
    }
}
export function snappableMessage_ReplyTypeToNumber(object: SnappableMessage_ReplyType): number {
    switch (object) {
        case SnappableMessage_ReplyType.NOT_APPLICABLE:
            return 0;
        case SnappableMessage_ReplyType.CONTEXT:
            return 1;
        case SnappableMessage_ReplyType.INTERSTITIAL:
            return 2;
        default:
            return 0;
    }
}
export interface Snappable {
    id: string;
    sessionId: string;
    playerId: string;
    sessionData: SessionData | undefined;
    createdTime: number;
    expireTime: number;
    version: number;
    encryptionData: EncryptionData | undefined;
    sessionDepth: number;
}
export interface PutSnappableRequest {
    snappable: Snappable | undefined;
    media: SnappableMedia[];
}
export interface PutSnappableResponse {
    id: string;
}
export interface GetSnappableRequest {
    id: string;
}
export interface GetSnappableResponse {
    data: Snappable | undefined;
    media: SnappableMedia[];
}
export interface SnappableEncryptedKey {
    encryptedKey: Uint8Array;
    encryptionKeyId: string;
    encryptionKeyIv: Uint8Array;
}
function createBaseEncryptionData(): EncryptionData {
    return {
        key: new Uint8Array(),
        ivStore: {},
        isKeyServerEncrypted: false,
        isKeyE2eEncrypted: false,
        encryptionScheme: EncryptionData_EncryptionScheme.NOT_APPLICABLE,
    };
}
export const EncryptionData = {
    encode(message: EncryptionData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key.length !== 0) {
            writer.uint32(10).bytes(message.key);
        }
        Object.entries(message.ivStore).forEach(([key, value]) => {
            EncryptionData_IvStoreEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
        });
        if (message.isKeyServerEncrypted === true) {
            writer.uint32(24).bool(message.isKeyServerEncrypted);
        }
        if (message.isKeyE2eEncrypted === true) {
            writer.uint32(32).bool(message.isKeyE2eEncrypted);
        }
        if (message.encryptionScheme !== EncryptionData_EncryptionScheme.NOT_APPLICABLE) {
            writer.uint32(40).int32(encryptionData_EncryptionSchemeToNumber(message.encryptionScheme));
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): EncryptionData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEncryptionData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.bytes();
                    break;
                case 2:
                    const entry2 = EncryptionData_IvStoreEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.ivStore[entry2.key] = entry2.value;
                    }
                    break;
                case 3:
                    message.isKeyServerEncrypted = reader.bool();
                    break;
                case 4:
                    message.isKeyE2eEncrypted = reader.bool();
                    break;
                case 5:
                    message.encryptionScheme = encryptionData_EncryptionSchemeFromJSON(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<EncryptionData>, I>>(object: I): EncryptionData {
        const message = createBaseEncryptionData();
        message.key = object.key ?? new Uint8Array();
        message.ivStore = Object.entries(object.ivStore ?? {}).reduce<{
            [key: string]: Uint8Array;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});
        message.isKeyServerEncrypted = object.isKeyServerEncrypted ?? false;
        message.isKeyE2eEncrypted = object.isKeyE2eEncrypted ?? false;
        message.encryptionScheme = object.encryptionScheme ?? EncryptionData_EncryptionScheme.NOT_APPLICABLE;
        return message;
    },
};
function createBaseEncryptionData_IvStoreEntry(): EncryptionData_IvStoreEntry {
    return { key: "", value: new Uint8Array() };
}
export const EncryptionData_IvStoreEntry = {
    encode(message: EncryptionData_IvStoreEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value.length !== 0) {
            writer.uint32(18).bytes(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): EncryptionData_IvStoreEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEncryptionData_IvStoreEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<EncryptionData_IvStoreEntry>, I>>(object: I): EncryptionData_IvStoreEntry {
        const message = createBaseEncryptionData_IvStoreEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? new Uint8Array();
        return message;
    },
};
function createBasePlayerData(): PlayerData {
    return { store: {}, serializedStore: new Uint8Array() };
}
export const PlayerData = {
    encode(message: PlayerData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        Object.entries(message.store).forEach(([key, value]) => {
            PlayerData_StoreEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
        });
        if (message.serializedStore.length !== 0) {
            writer.uint32(18).bytes(message.serializedStore);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PlayerData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = PlayerData_StoreEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.store[entry1.key] = entry1.value;
                    }
                    break;
                case 2:
                    message.serializedStore = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PlayerData>, I>>(object: I): PlayerData {
        const message = createBasePlayerData();
        message.store = Object.entries(object.store ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.serializedStore = object.serializedStore ?? new Uint8Array();
        return message;
    },
};
function createBasePlayerData_StoreEntry(): PlayerData_StoreEntry {
    return { key: "", value: "" };
}
export const PlayerData_StoreEntry = {
    encode(message: PlayerData_StoreEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PlayerData_StoreEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePlayerData_StoreEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PlayerData_StoreEntry>, I>>(object: I): PlayerData_StoreEntry {
        const message = createBasePlayerData_StoreEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseSessionData(): SessionData {
    return { playerData: {} };
}
export const SessionData = {
    encode(message: SessionData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        Object.entries(message.playerData).forEach(([key, value]) => {
            SessionData_PlayerDataEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
        });
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SessionData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSessionData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = SessionData_PlayerDataEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.playerData[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SessionData>, I>>(object: I): SessionData {
        const message = createBaseSessionData();
        message.playerData = Object.entries(object.playerData ?? {}).reduce<{
            [key: string]: PlayerData;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = PlayerData.fromPartial(value);
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseSessionData_PlayerDataEntry(): SessionData_PlayerDataEntry {
    return { key: "", value: undefined };
}
export const SessionData_PlayerDataEntry = {
    encode(message: SessionData_PlayerDataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            PlayerData.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SessionData_PlayerDataEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSessionData_PlayerDataEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = PlayerData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SessionData_PlayerDataEntry>, I>>(object: I): SessionData_PlayerDataEntry {
        const message = createBaseSessionData_PlayerDataEntry();
        message.key = object.key ?? "";
        message.value =
            object.value !== undefined && object.value !== null ? PlayerData.fromPartial(object.value) : undefined;
        return message;
    },
};
function createBaseSnappableMedia(): SnappableMedia {
    return { sessionId: "", playerId: "", url: "", key: "", createdTime: 0 };
}
export const SnappableMedia = {
    encode(message: SnappableMedia, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sessionId !== "") {
            writer.uint32(10).string(message.sessionId);
        }
        if (message.playerId !== "") {
            writer.uint32(18).string(message.playerId);
        }
        if (message.url !== "") {
            writer.uint32(26).string(message.url);
        }
        if (message.key !== "") {
            writer.uint32(34).string(message.key);
        }
        if (message.createdTime !== 0) {
            writer.uint32(40).int64(message.createdTime);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SnappableMedia {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSnappableMedia();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionId = reader.string();
                    break;
                case 2:
                    message.playerId = reader.string();
                    break;
                case 3:
                    message.url = reader.string();
                    break;
                case 4:
                    message.key = reader.string();
                    break;
                case 5:
                    message.createdTime = longToNumber(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SnappableMedia>, I>>(object: I): SnappableMedia {
        const message = createBaseSnappableMedia();
        message.sessionId = object.sessionId ?? "";
        message.playerId = object.playerId ?? "";
        message.url = object.url ?? "";
        message.key = object.key ?? "";
        message.createdTime = object.createdTime ?? 0;
        return message;
    },
};
function createBaseSnap3DData(): Snap3DData {
    return { allowZippedMedia: false };
}
export const Snap3DData = {
    encode(message: Snap3DData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.allowZippedMedia === true) {
            writer.uint32(8).bool(message.allowZippedMedia);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Snap3DData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSnap3DData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.allowZippedMedia = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Snap3DData>, I>>(object: I): Snap3DData {
        const message = createBaseSnap3DData();
        message.allowZippedMedia = object.allowZippedMedia ?? false;
        return message;
    },
};
function createBaseUsesCameraRoll(): UsesCameraRoll {
    return { usesCameraRoll: false };
}
export const UsesCameraRoll = {
    encode(message: UsesCameraRoll, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.usesCameraRoll === true) {
            writer.uint32(8).bool(message.usesCameraRoll);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): UsesCameraRoll {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUsesCameraRoll();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.usesCameraRoll = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<UsesCameraRoll>, I>>(object: I): UsesCameraRoll {
        const message = createBaseUsesCameraRoll();
        message.usesCameraRoll = object.usesCameraRoll ?? false;
        return message;
    },
};
function createBaseSnappableMessage(): SnappableMessage {
    return {
        snappable: undefined,
        media: [],
        effectId: "",
        userIds: [],
        focusPointX: 0,
        focusPointY: 0,
        focusPointWidth: 0,
        replyType: SnappableMessage_ReplyType.NOT_APPLICABLE,
        snap3dData: undefined,
        usesCameraRollData: undefined,
        assetBatchId: "",
    };
}
export const SnappableMessage = {
    encode(message: SnappableMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.snappable !== undefined) {
            Snappable.encode(message.snappable, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.media) {
            SnappableMedia.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        if (message.effectId !== "") {
            writer.uint32(26).string(message.effectId);
        }
        for (const v of message.userIds) {
            writer.uint32(34).string(v!);
        }
        if (message.focusPointX !== 0) {
            writer.uint32(45).float(message.focusPointX);
        }
        if (message.focusPointY !== 0) {
            writer.uint32(53).float(message.focusPointY);
        }
        if (message.focusPointWidth !== 0) {
            writer.uint32(61).float(message.focusPointWidth);
        }
        if (message.replyType !== SnappableMessage_ReplyType.NOT_APPLICABLE) {
            writer.uint32(64).int32(snappableMessage_ReplyTypeToNumber(message.replyType));
        }
        if (message.snap3dData !== undefined) {
            Snap3DData.encode(message.snap3dData, writer.uint32(74).fork()).ldelim();
        }
        if (message.usesCameraRollData !== undefined) {
            UsesCameraRoll.encode(message.usesCameraRollData, writer.uint32(82).fork()).ldelim();
        }
        if (message.assetBatchId !== "") {
            writer.uint32(4002).string(message.assetBatchId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SnappableMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSnappableMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.snappable = Snappable.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.media.push(SnappableMedia.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.effectId = reader.string();
                    break;
                case 4:
                    message.userIds.push(reader.string());
                    break;
                case 5:
                    message.focusPointX = reader.float();
                    break;
                case 6:
                    message.focusPointY = reader.float();
                    break;
                case 7:
                    message.focusPointWidth = reader.float();
                    break;
                case 8:
                    message.replyType = snappableMessage_ReplyTypeFromJSON(reader.int32());
                    break;
                case 9:
                    message.snap3dData = Snap3DData.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.usesCameraRollData = UsesCameraRoll.decode(reader, reader.uint32());
                    break;
                case 500:
                    message.assetBatchId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SnappableMessage>, I>>(object: I): SnappableMessage {
        const message = createBaseSnappableMessage();
        message.snappable =
            object.snappable !== undefined && object.snappable !== null ? Snappable.fromPartial(object.snappable) : undefined;
        message.media = object.media?.map((e) => SnappableMedia.fromPartial(e)) || [];
        message.effectId = object.effectId ?? "";
        message.userIds = object.userIds?.map((e) => e) || [];
        message.focusPointX = object.focusPointX ?? 0;
        message.focusPointY = object.focusPointY ?? 0;
        message.focusPointWidth = object.focusPointWidth ?? 0;
        message.replyType = object.replyType ?? SnappableMessage_ReplyType.NOT_APPLICABLE;
        message.snap3dData =
            object.snap3dData !== undefined && object.snap3dData !== null
                ? Snap3DData.fromPartial(object.snap3dData)
                : undefined;
        message.usesCameraRollData =
            object.usesCameraRollData !== undefined && object.usesCameraRollData !== null
                ? UsesCameraRoll.fromPartial(object.usesCameraRollData)
                : undefined;
        message.assetBatchId = object.assetBatchId ?? "";
        return message;
    },
};
function createBaseSnappable(): Snappable {
    return {
        id: "",
        sessionId: "",
        playerId: "",
        sessionData: undefined,
        createdTime: 0,
        expireTime: 0,
        version: 0,
        encryptionData: undefined,
        sessionDepth: 0,
    };
}
export const Snappable = {
    encode(message: Snappable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.sessionId !== "") {
            writer.uint32(18).string(message.sessionId);
        }
        if (message.playerId !== "") {
            writer.uint32(26).string(message.playerId);
        }
        if (message.sessionData !== undefined) {
            SessionData.encode(message.sessionData, writer.uint32(34).fork()).ldelim();
        }
        if (message.createdTime !== 0) {
            writer.uint32(40).int64(message.createdTime);
        }
        if (message.expireTime !== 0) {
            writer.uint32(48).int64(message.expireTime);
        }
        if (message.version !== 0) {
            writer.uint32(56).int32(message.version);
        }
        if (message.encryptionData !== undefined) {
            EncryptionData.encode(message.encryptionData, writer.uint32(66).fork()).ldelim();
        }
        if (message.sessionDepth !== 0) {
            writer.uint32(72).int32(message.sessionDepth);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Snappable {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSnappable();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.sessionId = reader.string();
                    break;
                case 3:
                    message.playerId = reader.string();
                    break;
                case 4:
                    message.sessionData = SessionData.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.createdTime = longToNumber(reader.int64() as Long);
                    break;
                case 6:
                    message.expireTime = longToNumber(reader.int64() as Long);
                    break;
                case 7:
                    message.version = reader.int32();
                    break;
                case 8:
                    message.encryptionData = EncryptionData.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.sessionDepth = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Snappable>, I>>(object: I): Snappable {
        const message = createBaseSnappable();
        message.id = object.id ?? "";
        message.sessionId = object.sessionId ?? "";
        message.playerId = object.playerId ?? "";
        message.sessionData =
            object.sessionData !== undefined && object.sessionData !== null
                ? SessionData.fromPartial(object.sessionData)
                : undefined;
        message.createdTime = object.createdTime ?? 0;
        message.expireTime = object.expireTime ?? 0;
        message.version = object.version ?? 0;
        message.encryptionData =
            object.encryptionData !== undefined && object.encryptionData !== null
                ? EncryptionData.fromPartial(object.encryptionData)
                : undefined;
        message.sessionDepth = object.sessionDepth ?? 0;
        return message;
    },
};
function createBasePutSnappableRequest(): PutSnappableRequest {
    return { snappable: undefined, media: [] };
}
export const PutSnappableRequest = {
    encode(message: PutSnappableRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.snappable !== undefined) {
            Snappable.encode(message.snappable, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.media) {
            SnappableMedia.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PutSnappableRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePutSnappableRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.snappable = Snappable.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.media.push(SnappableMedia.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PutSnappableRequest>, I>>(object: I): PutSnappableRequest {
        const message = createBasePutSnappableRequest();
        message.snappable =
            object.snappable !== undefined && object.snappable !== null ? Snappable.fromPartial(object.snappable) : undefined;
        message.media = object.media?.map((e) => SnappableMedia.fromPartial(e)) || [];
        return message;
    },
};
function createBasePutSnappableResponse(): PutSnappableResponse {
    return { id: "" };
}
export const PutSnappableResponse = {
    encode(message: PutSnappableResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PutSnappableResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePutSnappableResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PutSnappableResponse>, I>>(object: I): PutSnappableResponse {
        const message = createBasePutSnappableResponse();
        message.id = object.id ?? "";
        return message;
    },
};
function createBaseGetSnappableRequest(): GetSnappableRequest {
    return { id: "" };
}
export const GetSnappableRequest = {
    encode(message: GetSnappableRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetSnappableRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetSnappableRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetSnappableRequest>, I>>(object: I): GetSnappableRequest {
        const message = createBaseGetSnappableRequest();
        message.id = object.id ?? "";
        return message;
    },
};
function createBaseGetSnappableResponse(): GetSnappableResponse {
    return { data: undefined, media: [] };
}
export const GetSnappableResponse = {
    encode(message: GetSnappableResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.data !== undefined) {
            Snappable.encode(message.data, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.media) {
            SnappableMedia.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetSnappableResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetSnappableResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.data = Snappable.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.media.push(SnappableMedia.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetSnappableResponse>, I>>(object: I): GetSnappableResponse {
        const message = createBaseGetSnappableResponse();
        message.data = object.data !== undefined && object.data !== null ? Snappable.fromPartial(object.data) : undefined;
        message.media = object.media?.map((e) => SnappableMedia.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSnappableEncryptedKey(): SnappableEncryptedKey {
    return { encryptedKey: new Uint8Array(), encryptionKeyId: "", encryptionKeyIv: new Uint8Array() };
}
export const SnappableEncryptedKey = {
    encode(message: SnappableEncryptedKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.encryptedKey.length !== 0) {
            writer.uint32(10).bytes(message.encryptedKey);
        }
        if (message.encryptionKeyId !== "") {
            writer.uint32(18).string(message.encryptionKeyId);
        }
        if (message.encryptionKeyIv.length !== 0) {
            writer.uint32(26).bytes(message.encryptionKeyIv);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SnappableEncryptedKey {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSnappableEncryptedKey();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.encryptedKey = reader.bytes();
                    break;
                case 2:
                    message.encryptionKeyId = reader.string();
                    break;
                case 3:
                    message.encryptionKeyIv = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SnappableEncryptedKey>, I>>(object: I): SnappableEncryptedKey {
        const message = createBaseSnappableEncryptedKey();
        message.encryptedKey = object.encryptedKey ?? new Uint8Array();
        message.encryptionKeyId = object.encryptionKeyId ?? "";
        message.encryptionKeyIv = object.encryptionKeyIv ?? new Uint8Array();
        return message;
    },
};
declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
