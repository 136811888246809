import { TupleOf } from "../common/types";

/**
 * Use this class to supply the CameraKitSession::setSourceTransform with the proper data
 * needed to apply transformations
 *
 * @category Rendering
 */
export class Transform2D {
    static readonly MirrorX = new Transform2D([-1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 1.0, 0.0, 1.0]);
    static readonly MirrorY = new Transform2D([1.0, 0.0, 0.0, 0.0, -1.0, 0.0, 0.0, 1.0, 1.0]);
    static readonly Identity = new Transform2D([1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0]);

    /**
     * @param matrix A 3x3 matrix in column-major order, representing a 2D transform to be applied to the source media
     */
    constructor(readonly matrix: TupleOf<number, 9>) {}
}
