import { ensureError } from "../common/errorHelpers";
import { Transform2D } from "../transforms";
import { CameraKitSource, CameraKitSourceOptions } from "./CameraKitSource";

const defaultOptions: MediaStreamSourceOptions = {
    transform: Transform2D.Identity,
    disableSourceAudio: false,
};

function closeWorklet(worklet: AudioWorkletNode | undefined) {
    if (!worklet) return;
    worklet.port.close();
    worklet.port.onmessage = null;
    worklet.disconnect();
}

async function closeAudioContext(audioContext: AudioContext | undefined) {
    if (!audioContext || audioContext.state === "closed") return;
    return audioContext.close();
}

/** @category Rendering */
export interface MediaStreamSourceOptions {
    transform: Transform2D;
    disableSourceAudio: boolean; // defaults to false
}

/**
 * Create a {@link CameraKitSource} from a user's media device -- this calls
 * [MediaDevices.getUserMedia](https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia) to get a
 * MediaStream and then calls {@link createMediaStreamSource}.
 *
 * @param constraints Specify contraints used to get a MediaStream from a media device. By default we simply request
 * a video stream.
 * @param options
 * @param options.transform By default we horizontally mirror the video stream. The most common use-case is to obtain a
 * stream from a front-facing web cam, which requires mirroring to be viewed naturally.
 * @param options.cameraType By default we set this to 'front' to indicate a camera pointed at the user (e.g. a webcam).
 * @param options.fpsLimit By default we set no limit on FPS – if the source device has a known FPS setting this limit
 * may prevent CameraKit from using more compute resources than strictly necessary.
 * @returns A Promise, resolving to {@link CameraKitSource}
 *
 * @category Rendering
 *
 * @deprecated The helper will be removed in one of the future releases.
 * Consumer apps are responsible for acquiring a media stream,
 * which can then be supplied to {@link createMediaStreamSource}.
 */
export async function createUserMediaSource(
    constraints: MediaStreamConstraints = { video: true },
    options: CameraKitSourceOptions<MediaStreamSourceOptions> = {}
): Promise<CameraKitSource> {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    return createMediaStreamSource(stream, { transform: Transform2D.MirrorX, cameraType: "front", ...options });
}

/**
 * Create a {@link CameraKitSource} from any
 * [MediaStream](https://developer.mozilla.org/en-US/docs/Web/API/MediaStream).
 *
 * @param stream Any MediaStream, such as obtained via `canvas.captureStream()` or `mediaDevices.getUserMedia()`.
 * @param options
 * @param options.transform We apply no transformation by default.
 * @param options.disableSourceAudio By default we pass audio to lens. Settings this to true will disable sending audio
 * to the lens.
 * @param options.cameraType By default we set this to 'user', which is the camera type most Lenses expect.
 * @param options.fpsLimit By default we set no limit on FPS – if the MediaStream has a known FPS setting this limit
 * may prevent CameraKit from using more compute resources than strictly necessary.
 *
 * @category Rendering
 */
export function createMediaStreamSource(
    stream: MediaStream,
    options: CameraKitSourceOptions<MediaStreamSourceOptions> = {}
): CameraKitSource {
    const { facingMode } =
        stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0].getSettings() : { facingMode: undefined };

    const detectedCameraType = facingMode === "user" || facingMode === "environment" ? facingMode : undefined;

    const optionsWithDefaults = {
        ...defaultOptions,
        ...options,
        cameraType: options.cameraType ?? detectedCameraType,
    };

    const enableSourceAudio: boolean = stream.getAudioTracks().length > 0 && !optionsWithDefaults.disableSourceAudio;

    const simulateStereoAudio = true;
    const sampleRate: number = 44100;

    let audioContext: AudioContext | undefined = undefined;
    let audioSource: MediaStreamAudioSourceNode | undefined = undefined;
    let worklet: AudioWorkletNode | undefined = undefined;
    let microphoneRecorderUrl: string;

    if (enableSourceAudio) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_AudioWorklet
        const microphoneRecorderWorklet = `
        class MicrophoneWorkletProcessor extends AudioWorkletProcessor {
            process(inputs, outputs, parameters) {
                this.port.postMessage({
                    eventType: 'data',
                    buffer: inputs
                });
                return true;
            }
        }
        registerProcessor('microphone-worklet', MicrophoneWorkletProcessor);`;
        const microphoneRecorderBlob = new Blob([microphoneRecorderWorklet], {
            type: "application/javascript",
        });
        microphoneRecorderUrl = URL.createObjectURL(microphoneRecorderBlob);
    }

    return new CameraKitSource(
        { media: stream },
        {
            onAttach: async (source, lensCore, reportError) => {
                await source.setTransform(optionsWithDefaults.transform);

                if (enableSourceAudio) {
                    // Audio paramters set has to be called before lens is applied
                    await lensCore.setAudioParameters({
                        parameters: {
                            numChannels: simulateStereoAudio ? 2 : 1,
                            sampleRate,
                        },
                    });

                    try {
                        // There is a possibility of the onAttach method being called twice in a row due to a bug.
                        // To ensure there are not leaks, it is better to close any existing connections.
                        closeWorklet(worklet);
                        audioSource?.disconnect();
                        await closeAudioContext(audioContext);
                    } catch (error) {
                        // We still want to continue if anything above failed
                        reportError(ensureError(error));
                    }

                    audioContext = new AudioContext();
                    audioSource = audioContext.createMediaStreamSource(stream);
                    const scopedAudioSource = audioSource;
                    audioContext.audioWorklet
                        .addModule(microphoneRecorderUrl)
                        .then(() => {
                            if (audioContext) {
                                worklet = new AudioWorkletNode(audioContext, "microphone-worklet");
                                scopedAudioSource.connect(worklet);
                                worklet.connect(audioContext.destination);
                                // NOTE: We subscribe to messages here, and they will continue to arrive
                                // even after audioContext.close() is called. To disconnect the audio worklets
                                // created here, we need to track two variables - worklet and audioSource.
                                // By calling disconnect() on them, we can properly
                                // disconnect the audio worklets.
                                worklet.port.onmessage = (e) => {
                                    if (e.data.eventType === "data") {
                                        // developer.mozilla.org/en-US/docs/Web/API/AudioWorkletProcessor/process
                                        // inputs[n][m] is the list of samples in the n-th input at the m-th channel.
                                        const leftSamples = e.data.buffer[0][0] as Float32Array;

                                        // Firefox might have leftSamples undefined:
                                        // https://jira.sc-corp.net/browse/CAMKIT-5189
                                        if (!leftSamples) return;

                                        let inputBuffers = [leftSamples];
                                        if (simulateStereoAudio) {
                                            const rightSamples =
                                                e.data.buffer[0].length > 1 ? e.data.buffer[0][1] : leftSamples.slice();
                                            inputBuffers.push(rightSamples);
                                        }

                                        lensCore.processAudioSampleBuffer({ input: inputBuffers }).catch(reportError);
                                    }
                                };
                            }
                        })
                        .catch((error: Error) => {
                            reportError(error);
                        });
                }
            },
            onDetach: async (reportError) => {
                if (worklet) {
                    closeWorklet(worklet);
                    worklet = undefined;
                }
                if (audioSource) {
                    audioSource.disconnect();
                    audioSource = undefined;
                }
                if (audioContext) {
                    await closeAudioContext(audioContext).catch(reportError);
                    audioContext = undefined;
                }
            },
        },
        optionsWithDefaults
    );
}
