import { isString } from "../common/typeguards";
import { getConfigurationOverrides } from "../configurationOverrides";
import { ChainableHandler, HandlerChainBuilder } from "./HandlerChainBuilder";

/**
 * Addes cookies to auth requests to custom LensCore binaries
 * when there are debugging overrides.
 *
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createDebugHandler = <Res>(): ChainableHandler<
    RequestInfo,
    Res,
    RequestInfo,
    Res,
    RequestInit | undefined
> => {
    const noCustomWasmEndpoint = !getConfigurationOverrides()?.wasmEndpointOverride;

    if (noCustomWasmEndpoint) {
        return (next) => next;
    }
    return (next) => (input, init) => {
        const url = isString(input) ? input : input?.url ?? "";
        // if requests are made to internal LensCore binaries site
        // we have to include cookies for auth purposes
        // as per https://wiki.sc-corp.net/x/KsnRCg
        if (url.startsWith("https://lens-core-wasm.sc-corp.net/")) {
            return next(input, {
                ...init,
                credentials: "include",
            });
        }
        return next(input, init);
    };
};
