import { ChainableHandler, RequestMetadata } from "./HandlerChainBuilder";

type ArrayBufferResponse = [ArrayBuffer, Response];
type ChainableArrayBufferHandler<Req, Meta extends RequestMetadata> = ChainableHandler<
    Req,
    ArrayBufferResponse,
    Req,
    Response,
    Meta
>;

/**
 * Parse a Fetch Response body into an ArrayBuffer.
 *
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createArrayBufferParsingHandler =
    <Req, Meta extends RequestMetadata>(): ChainableArrayBufferHandler<Req, Meta> =>
    (next) =>
    async (req, metadata) => {
        const response = await next(req, metadata);
        let buffer: ArrayBuffer;
        try {
            buffer = await response.arrayBuffer();
        } catch (_) {
            buffer = new ArrayBuffer(0);
        }
        return [buffer, response];
    };
