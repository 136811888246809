import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "google.protobuf";
export interface DoubleValue {
    value: number;
}
export interface FloatValue {
    value: number;
}
export interface Int64Value {
    value: number;
}
export interface UInt64Value {
    value: number;
}
export interface Int32Value {
    value: number;
}
export interface UInt32Value {
    value: number;
}
export interface BoolValue {
    value: boolean;
}
export interface StringValue {
    value: string;
}
export interface BytesValue {
    value: Uint8Array;
}
function createBaseDoubleValue(): DoubleValue {
    return { value: 0 };
}
export const DoubleValue = {
    encode(message: DoubleValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value !== 0) {
            writer.uint32(9).double(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): DoubleValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDoubleValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<DoubleValue>, I>>(object: I): DoubleValue {
        const message = createBaseDoubleValue();
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseFloatValue(): FloatValue {
    return { value: 0 };
}
export const FloatValue = {
    encode(message: FloatValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value !== 0) {
            writer.uint32(13).float(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): FloatValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFloatValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<FloatValue>, I>>(object: I): FloatValue {
        const message = createBaseFloatValue();
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseInt64Value(): Int64Value {
    return { value: 0 };
}
export const Int64Value = {
    encode(message: Int64Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value !== 0) {
            writer.uint32(8).int64(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Int64Value {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInt64Value();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = longToNumber(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Int64Value>, I>>(object: I): Int64Value {
        const message = createBaseInt64Value();
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseUInt64Value(): UInt64Value {
    return { value: 0 };
}
export const UInt64Value = {
    encode(message: UInt64Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value !== 0) {
            writer.uint32(8).uint64(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): UInt64Value {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUInt64Value();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = longToNumber(reader.uint64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<UInt64Value>, I>>(object: I): UInt64Value {
        const message = createBaseUInt64Value();
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseInt32Value(): Int32Value {
    return { value: 0 };
}
export const Int32Value = {
    encode(message: Int32Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value !== 0) {
            writer.uint32(8).int32(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Int32Value {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseInt32Value();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Int32Value>, I>>(object: I): Int32Value {
        const message = createBaseInt32Value();
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseUInt32Value(): UInt32Value {
    return { value: 0 };
}
export const UInt32Value = {
    encode(message: UInt32Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value !== 0) {
            writer.uint32(8).uint32(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): UInt32Value {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUInt32Value();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<UInt32Value>, I>>(object: I): UInt32Value {
        const message = createBaseUInt32Value();
        message.value = object.value ?? 0;
        return message;
    },
};
function createBaseBoolValue(): BoolValue {
    return { value: false };
}
export const BoolValue = {
    encode(message: BoolValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value === true) {
            writer.uint32(8).bool(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BoolValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBoolValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BoolValue>, I>>(object: I): BoolValue {
        const message = createBaseBoolValue();
        message.value = object.value ?? false;
        return message;
    },
};
function createBaseStringValue(): StringValue {
    return { value: "" };
}
export const StringValue = {
    encode(message: StringValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value !== "") {
            writer.uint32(10).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): StringValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStringValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<StringValue>, I>>(object: I): StringValue {
        const message = createBaseStringValue();
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseBytesValue(): BytesValue {
    return { value: new Uint8Array() };
}
export const BytesValue = {
    encode(message: BytesValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.value.length !== 0) {
            writer.uint32(10).bytes(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BytesValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBytesValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BytesValue>, I>>(object: I): BytesValue {
        const message = createBaseBytesValue();
        message.value = object.value ?? new Uint8Array();
        return message;
    },
};
declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
