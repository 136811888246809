import Long from "long";
import _m0 from "protobufjs/minimal";
import { Namespace } from "./namespace";
import { BenchmarkResult } from "./benchmark";
import { Ruid } from "../../common/ruid";
import { BoolValue } from "../../google/protobuf/wrappers";
export const protobufPackage = "snapchat.cdp.cof";
export enum ConfigTargetingTriggerEventType {
    UNKNOWN_EVENT_TYPE = 0,
    COLD_START = 1,
    WARM_START = 2,
    FOREGROUND_TRIGGER = 3,
    BACKGROUND_TRIGGER = 4,
    UNRECOGNIZED = -1
}
export enum ConfigTargetingAppState {
    UNKNOWN_APP_STATE = 0,
    FOREGROUND = 1,
    BACKGROUND = 2,
    UNRECOGNIZED = -1
}
export enum ConfigTargetingInstrumentation {
    DEFAULT_INSTRUMENTATION = 0,
    USER_AUTHENTICATION = 1,
    UNRECOGNIZED = -1
}
export interface ConfigTargetingRequest {
    configResultsEtag: string;
    namespaces: Namespace[];
    ghostId: string;
    countryCode: string;
    screenWidth: number;
    screenHeight: number;
    connectivity: Connectivity | undefined;
    maxVideoWidthPx: number;
    maxVideoHeightPx: number;
    benchmarkResults: BenchmarkResult[];
    deltaSync: boolean;
    userAgent: string;
    triggerEventType: ConfigTargetingTriggerEventType;
    appState: ConfigTargetingAppState;
    locale: string;
    deviceId: string;
    userId: string;
    clientIp: string;
    isUnAuthorized: boolean;
    appLocale: string;
    instrumentation: ConfigTargetingInstrumentation;
    lastSuccessfulSync: number;
    isLogout: boolean;
    packageInstaller: string;
    syncTriggerBlizzardSessionId: string;
    syncExecutionBlizzardSessionId: string;
    cofSyncTriggerDelayFromStartupMs: number;
    cofSyncExecutionDelayFromStartupMs: number;
    syncTriggerTime: number;
    decoderEncoderAvailability: DecoderEncoderAvailablity | undefined;
    snapkitAppId: string;
    lenscoreVersion: number;
    ruid: Ruid | undefined;
    configNames: string[];
    includeTestUserTreatments: boolean;
    disableExposureLogging: boolean;
    lensClusterOrig4: number;
    clientId: string;
}
export interface Connectivity {
    networkType: Connectivity_NetworkType;
    isMetered: boolean | undefined;
    isRoaming: boolean | undefined;
    carrier: string;
}
export enum Connectivity_NetworkType {
    UNKNOWN_NETWORK_TYPE = 0,
    CELLULAR = 1,
    WIFI = 2,
    UNRECOGNIZED = -1
}
export interface DecoderEncoderAvailablity {
    isSnapVp9DecoderAvailable: boolean;
    isSnapAv1DecoderAvailable: boolean;
}
function createBaseConfigTargetingRequest(): ConfigTargetingRequest {
    return {
        configResultsEtag: "",
        namespaces: [],
        ghostId: "",
        countryCode: "",
        screenWidth: 0,
        screenHeight: 0,
        connectivity: undefined,
        maxVideoWidthPx: 0,
        maxVideoHeightPx: 0,
        benchmarkResults: [],
        deltaSync: false,
        userAgent: "",
        triggerEventType: 0,
        appState: 0,
        locale: "",
        deviceId: "",
        userId: "",
        clientIp: "",
        isUnAuthorized: false,
        appLocale: "",
        instrumentation: 0,
        lastSuccessfulSync: 0,
        isLogout: false,
        packageInstaller: "",
        syncTriggerBlizzardSessionId: "",
        syncExecutionBlizzardSessionId: "",
        cofSyncTriggerDelayFromStartupMs: 0,
        cofSyncExecutionDelayFromStartupMs: 0,
        syncTriggerTime: 0,
        decoderEncoderAvailability: undefined,
        snapkitAppId: "",
        lenscoreVersion: 0,
        ruid: undefined,
        configNames: [],
        includeTestUserTreatments: false,
        disableExposureLogging: false,
        lensClusterOrig4: 0,
        clientId: "",
    };
}
export const ConfigTargetingRequest = {
    encode(message: ConfigTargetingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.configResultsEtag !== "") {
            writer.uint32(10).string(message.configResultsEtag);
        }
        writer.uint32(18).fork();
        for (const v of message.namespaces) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.ghostId !== "") {
            writer.uint32(26).string(message.ghostId);
        }
        if (message.countryCode !== "") {
            writer.uint32(34).string(message.countryCode);
        }
        if (message.screenWidth !== 0) {
            writer.uint32(40).int32(message.screenWidth);
        }
        if (message.screenHeight !== 0) {
            writer.uint32(48).int32(message.screenHeight);
        }
        if (message.connectivity !== undefined) {
            Connectivity.encode(message.connectivity, writer.uint32(58).fork()).ldelim();
        }
        if (message.maxVideoWidthPx !== 0) {
            writer.uint32(64).int32(message.maxVideoWidthPx);
        }
        if (message.maxVideoHeightPx !== 0) {
            writer.uint32(72).int32(message.maxVideoHeightPx);
        }
        for (const v of message.benchmarkResults) {
            BenchmarkResult.encode(v!, writer.uint32(82).fork()).ldelim();
        }
        if (message.deltaSync === true) {
            writer.uint32(88).bool(message.deltaSync);
        }
        if (message.userAgent !== "") {
            writer.uint32(98).string(message.userAgent);
        }
        if (message.triggerEventType !== 0) {
            writer.uint32(104).int32(message.triggerEventType);
        }
        if (message.appState !== 0) {
            writer.uint32(112).int32(message.appState);
        }
        if (message.locale !== "") {
            writer.uint32(122).string(message.locale);
        }
        if (message.deviceId !== "") {
            writer.uint32(130).string(message.deviceId);
        }
        if (message.userId !== "") {
            writer.uint32(138).string(message.userId);
        }
        if (message.clientIp !== "") {
            writer.uint32(146).string(message.clientIp);
        }
        if (message.isUnAuthorized === true) {
            writer.uint32(152).bool(message.isUnAuthorized);
        }
        if (message.appLocale !== "") {
            writer.uint32(162).string(message.appLocale);
        }
        if (message.instrumentation !== 0) {
            writer.uint32(168).int32(message.instrumentation);
        }
        if (message.lastSuccessfulSync !== 0) {
            writer.uint32(176).int64(message.lastSuccessfulSync);
        }
        if (message.isLogout === true) {
            writer.uint32(184).bool(message.isLogout);
        }
        if (message.packageInstaller !== "") {
            writer.uint32(194).string(message.packageInstaller);
        }
        if (message.syncTriggerBlizzardSessionId !== "") {
            writer.uint32(202).string(message.syncTriggerBlizzardSessionId);
        }
        if (message.syncExecutionBlizzardSessionId !== "") {
            writer.uint32(210).string(message.syncExecutionBlizzardSessionId);
        }
        if (message.cofSyncTriggerDelayFromStartupMs !== 0) {
            writer.uint32(216).int32(message.cofSyncTriggerDelayFromStartupMs);
        }
        if (message.cofSyncExecutionDelayFromStartupMs !== 0) {
            writer.uint32(224).int32(message.cofSyncExecutionDelayFromStartupMs);
        }
        if (message.syncTriggerTime !== 0) {
            writer.uint32(232).int64(message.syncTriggerTime);
        }
        if (message.decoderEncoderAvailability !== undefined) {
            DecoderEncoderAvailablity.encode(message.decoderEncoderAvailability, writer.uint32(242).fork()).ldelim();
        }
        if (message.snapkitAppId !== "") {
            writer.uint32(250).string(message.snapkitAppId);
        }
        if (message.lenscoreVersion !== 0) {
            writer.uint32(256).int32(message.lenscoreVersion);
        }
        if (message.ruid !== undefined) {
            Ruid.encode(message.ruid, writer.uint32(266).fork()).ldelim();
        }
        for (const v of message.configNames) {
            writer.uint32(274).string(v!);
        }
        if (message.includeTestUserTreatments === true) {
            writer.uint32(288).bool(message.includeTestUserTreatments);
        }
        if (message.disableExposureLogging === true) {
            writer.uint32(296).bool(message.disableExposureLogging);
        }
        if (message.lensClusterOrig4 !== 0) {
            writer.uint32(304).int32(message.lensClusterOrig4);
        }
        if (message.clientId !== "") {
            writer.uint32(314).string(message.clientId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigTargetingRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigTargetingRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configResultsEtag = reader.string();
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.namespaces.push(reader.int32() as any);
                        }
                    }
                    else {
                        message.namespaces.push(reader.int32() as any);
                    }
                    break;
                case 3:
                    message.ghostId = reader.string();
                    break;
                case 4:
                    message.countryCode = reader.string();
                    break;
                case 5:
                    message.screenWidth = reader.int32();
                    break;
                case 6:
                    message.screenHeight = reader.int32();
                    break;
                case 7:
                    message.connectivity = Connectivity.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.maxVideoWidthPx = reader.int32();
                    break;
                case 9:
                    message.maxVideoHeightPx = reader.int32();
                    break;
                case 10:
                    message.benchmarkResults.push(BenchmarkResult.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.deltaSync = reader.bool();
                    break;
                case 12:
                    message.userAgent = reader.string();
                    break;
                case 13:
                    message.triggerEventType = reader.int32() as any;
                    break;
                case 14:
                    message.appState = reader.int32() as any;
                    break;
                case 15:
                    message.locale = reader.string();
                    break;
                case 16:
                    message.deviceId = reader.string();
                    break;
                case 17:
                    message.userId = reader.string();
                    break;
                case 18:
                    message.clientIp = reader.string();
                    break;
                case 19:
                    message.isUnAuthorized = reader.bool();
                    break;
                case 20:
                    message.appLocale = reader.string();
                    break;
                case 21:
                    message.instrumentation = reader.int32() as any;
                    break;
                case 22:
                    message.lastSuccessfulSync = longToNumber(reader.int64() as Long);
                    break;
                case 23:
                    message.isLogout = reader.bool();
                    break;
                case 24:
                    message.packageInstaller = reader.string();
                    break;
                case 25:
                    message.syncTriggerBlizzardSessionId = reader.string();
                    break;
                case 26:
                    message.syncExecutionBlizzardSessionId = reader.string();
                    break;
                case 27:
                    message.cofSyncTriggerDelayFromStartupMs = reader.int32();
                    break;
                case 28:
                    message.cofSyncExecutionDelayFromStartupMs = reader.int32();
                    break;
                case 29:
                    message.syncTriggerTime = longToNumber(reader.int64() as Long);
                    break;
                case 30:
                    message.decoderEncoderAvailability = DecoderEncoderAvailablity.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.snapkitAppId = reader.string();
                    break;
                case 32:
                    message.lenscoreVersion = reader.int32();
                    break;
                case 33:
                    message.ruid = Ruid.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.configNames.push(reader.string());
                    break;
                case 36:
                    message.includeTestUserTreatments = reader.bool();
                    break;
                case 37:
                    message.disableExposureLogging = reader.bool();
                    break;
                case 38:
                    message.lensClusterOrig4 = reader.int32();
                    break;
                case 39:
                    message.clientId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigTargetingRequest>, I>>(object: I): ConfigTargetingRequest {
        const message = createBaseConfigTargetingRequest();
        message.configResultsEtag = object.configResultsEtag ?? "";
        message.namespaces = object.namespaces?.map((e) => e) || [];
        message.ghostId = object.ghostId ?? "";
        message.countryCode = object.countryCode ?? "";
        message.screenWidth = object.screenWidth ?? 0;
        message.screenHeight = object.screenHeight ?? 0;
        message.connectivity =
            object.connectivity !== undefined && object.connectivity !== null
                ? Connectivity.fromPartial(object.connectivity)
                : undefined;
        message.maxVideoWidthPx = object.maxVideoWidthPx ?? 0;
        message.maxVideoHeightPx = object.maxVideoHeightPx ?? 0;
        message.benchmarkResults = object.benchmarkResults?.map((e) => BenchmarkResult.fromPartial(e)) || [];
        message.deltaSync = object.deltaSync ?? false;
        message.userAgent = object.userAgent ?? "";
        message.triggerEventType = object.triggerEventType ?? 0;
        message.appState = object.appState ?? 0;
        message.locale = object.locale ?? "";
        message.deviceId = object.deviceId ?? "";
        message.userId = object.userId ?? "";
        message.clientIp = object.clientIp ?? "";
        message.isUnAuthorized = object.isUnAuthorized ?? false;
        message.appLocale = object.appLocale ?? "";
        message.instrumentation = object.instrumentation ?? 0;
        message.lastSuccessfulSync = object.lastSuccessfulSync ?? 0;
        message.isLogout = object.isLogout ?? false;
        message.packageInstaller = object.packageInstaller ?? "";
        message.syncTriggerBlizzardSessionId = object.syncTriggerBlizzardSessionId ?? "";
        message.syncExecutionBlizzardSessionId = object.syncExecutionBlizzardSessionId ?? "";
        message.cofSyncTriggerDelayFromStartupMs = object.cofSyncTriggerDelayFromStartupMs ?? 0;
        message.cofSyncExecutionDelayFromStartupMs = object.cofSyncExecutionDelayFromStartupMs ?? 0;
        message.syncTriggerTime = object.syncTriggerTime ?? 0;
        message.decoderEncoderAvailability =
            object.decoderEncoderAvailability !== undefined && object.decoderEncoderAvailability !== null
                ? DecoderEncoderAvailablity.fromPartial(object.decoderEncoderAvailability)
                : undefined;
        message.snapkitAppId = object.snapkitAppId ?? "";
        message.lenscoreVersion = object.lenscoreVersion ?? 0;
        message.ruid = object.ruid !== undefined && object.ruid !== null ? Ruid.fromPartial(object.ruid) : undefined;
        message.configNames = object.configNames?.map((e) => e) || [];
        message.includeTestUserTreatments = object.includeTestUserTreatments ?? false;
        message.disableExposureLogging = object.disableExposureLogging ?? false;
        message.lensClusterOrig4 = object.lensClusterOrig4 ?? 0;
        message.clientId = object.clientId ?? "";
        return message;
    },
};
function createBaseConnectivity(): Connectivity {
    return { networkType: 0, isMetered: undefined, isRoaming: undefined, carrier: "" };
}
export const Connectivity = {
    encode(message: Connectivity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.networkType !== 0) {
            writer.uint32(8).int32(message.networkType);
        }
        if (message.isMetered !== undefined) {
            BoolValue.encode({ value: message.isMetered! }, writer.uint32(18).fork()).ldelim();
        }
        if (message.isRoaming !== undefined) {
            BoolValue.encode({ value: message.isRoaming! }, writer.uint32(26).fork()).ldelim();
        }
        if (message.carrier !== "") {
            writer.uint32(34).string(message.carrier);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Connectivity {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConnectivity();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.networkType = reader.int32() as any;
                    break;
                case 2:
                    message.isMetered = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 3:
                    message.isRoaming = BoolValue.decode(reader, reader.uint32()).value;
                    break;
                case 4:
                    message.carrier = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Connectivity>, I>>(object: I): Connectivity {
        const message = createBaseConnectivity();
        message.networkType = object.networkType ?? 0;
        message.isMetered = object.isMetered ?? undefined;
        message.isRoaming = object.isRoaming ?? undefined;
        message.carrier = object.carrier ?? "";
        return message;
    },
};
function createBaseDecoderEncoderAvailablity(): DecoderEncoderAvailablity {
    return { isSnapVp9DecoderAvailable: false, isSnapAv1DecoderAvailable: false };
}
export const DecoderEncoderAvailablity = {
    encode(message: DecoderEncoderAvailablity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.isSnapVp9DecoderAvailable === true) {
            writer.uint32(8).bool(message.isSnapVp9DecoderAvailable);
        }
        if (message.isSnapAv1DecoderAvailable === true) {
            writer.uint32(16).bool(message.isSnapAv1DecoderAvailable);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): DecoderEncoderAvailablity {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDecoderEncoderAvailablity();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.isSnapVp9DecoderAvailable = reader.bool();
                    break;
                case 2:
                    message.isSnapAv1DecoderAvailable = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<DecoderEncoderAvailablity>, I>>(object: I): DecoderEncoderAvailablity {
        const message = createBaseDecoderEncoderAvailablity();
        message.isSnapVp9DecoderAvailable = object.isSnapVp9DecoderAvailable ?? false;
        message.isSnapAv1DecoderAvailable = object.isSnapAv1DecoderAvailable ?? false;
        return message;
    },
};
declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
