import environment from "./environment.json";
import lensCoreWasm from "./lensCoreWasmVersions.json";
import { lensRepositoryFactory } from "./lens/LensRepository";
import { Container } from "./dependency-injection/Container";
import { CameraKit, cameraKitFactory } from "./CameraKit";
import { lensCoreFactory } from "./lens-core-module/loader/lensCoreFactory";
import { Injectable } from "./dependency-injection/Injectable";
import { remoteMediaAssetLoaderFactory } from "./lens/assets/remoteMediaAssetLoaderFactory";
import { deviceDependentAssetLoaderFactory } from "./lens/assets/deviceDependentAssetLoader";
import { staticAssetLoaderFactory } from "./lens/assets/staticAssetLoader";
import { defaultFetchHandlerFactory } from "./handlers/defaultFetchHandler";
import { cameraKitServiceFetchHandlerFactory } from "./handlers/cameraKitServiceFetchHandlerFactory";
import { CameraKitBootstrapConfiguration, createCameraKitConfigurationFactory } from "./configuration";
import { PublicServices } from "./dependency-injection/RootServices";
import { PartialContainer } from "./dependency-injection/PartialContainer";
import { metricsHandlerFactory } from "./metrics/metricsHandler";
import { operationalMetricReporterFactory } from "./metrics/operational/operationalMetricsReporter";
import { uriHandlersFactory } from "./extensions/UriHandlers";
import { assert } from "./common/assertions";
import { isSafeString } from "./common/typeguards";
import { metricsEventTargetFactory } from "./metrics/metricsEventTarget";
import { reportGloballyScopedMetrics } from "./metrics/reporters/reporters";
import { getLogger } from "./logger/logger";
import { logEntriesFactory } from "./logger/logEntries";
import { assertPlatformSupported } from "./platform/assertPlatformSupported";
import { lensPersistenceStoreFactory } from "./lens/LensPersistenceStore";
import { remoteConfigurationFactory } from "./remote-configuration/remoteConfiguration";
import { lensAssetRepositoryFactory } from "./lens/assets/LensAssetRepository";
import { legalStateFactory } from "./legal/legalState";
import { legalPromptFactory } from "./legal/legalPrompt";
import { bootstrapError, ConfigurationError, configurationError, PlatformNotSupportedError } from "./namedErrors";
import { businessEventsReporterFactory } from "./metrics/businessEventsReporter";
import { reportGlobalException } from "./metrics/reporters/reportGlobalException";
import { registerLogEntriesSubscriber } from "./logger/registerLogEntriesSubscriber";
import { requestStateEventTargetFactory } from "./handlers/requestStateEmittingHandler";
import { pageVisibilityFactory } from "./common/pageVisibility";
import { cofHandlerFactory } from "./remote-configuration/cofHandler";
import { remoteApiServicesFactory } from "./extensions/RemoteApiServices";
import { lensesClientFactory } from "./clients/lensesClient";
import { gprcHandlerFactory } from "./clients/grpcHandler";
import { lensSourcesFactory } from "./lens/LensSource";
import { cameraKitLensSourceFactory } from "./lens/cameraKitLensSource";

const logger = getLogger("bootstrapCameraKit");

// The following errors are not wrapped with BootstrapError and bubble up as is.
const nonWrappableErrors: [ConfigurationError["name"], PlatformNotSupportedError["name"]] = [
    "ConfigurationError",
    "PlatformNotSupportedError",
];

/**
 * Returns true if given error has to be wrapped with BootstrapError.
 */
function shouldWrapError(error: unknown): boolean {
    if (error instanceof Error) {
        return !nonWrappableErrors.some((name) => error.name === name);
    }
    return true;
}

/**
 * For more advanced use-cases, this DI Container holds services for which a custom implementation may be provided by
 * the application.
 *
 * @category Bootstrapping and Configuration
 */
export type PublicContainer = Container<PublicServices>;

/**
 * Bootstrap CameraKit. This will download the WebAssembly code which powers CameraKit's rendering engine, and return
 * an instance of {@link CameraKit}.
 *
 * CameraKit must be provided with some configuration (the application's API token), and there are some additional
 * configurations which are optional.
 *
 * Descriptions of the available configurations can be found in the documentation for
 * {@link CameraKitBootstrapConfiguration}
 *
 * ---
 *
 * There is also a second, more advanced way to modify CameraKit to provide greater flexibility to support less common
 * use cases.
 *
 * This requires some knowledge of CameraKit's dependency injection system, and allows applications to provide their
 * own custom implementations of certain CameraKit components. This functionality will only be needed by applications
 * with very specific, more advanced requirements.
 *
 * @example
 * ```ts
 * // The most common way to bootstrap:
 * const cameraKit = await bootstrapCameraKit({ apiToken: myApiToken })
 *
 * // For special advanced use-cases, it is possible to provide custom implementations for certain CameraKit components.
 * const cameraKit = await bootstrapCameraKit(config, (container) => {
 *   return container.provides(myCustomRemoteMediaAssetLoaderFactory)
 * })
 * ```
 *
 * @param configuration Configure CameraKit with e.g. credentials, global resource endpoints, etc.
 * @param provide Optional function that can make modifications to CameraKit's root DI container.
 * @returns A {@link CameraKit} instance, which is the entry point to CameraKit's API.
 *
 * @throws
 *  - {@link ConfigurationError} when provided configuration object is invalid
 *  - {@link PlatformNotSupportedError} when current platform is not supported by CameraKit
 *  - {@link BootstrapError} when a failure occurs while initializing CameraKit and downloading the render engine
 * WebAssembly binary.
 *
 * @category Bootstrapping and Configuration
 */
export async function bootstrapCameraKit(
    configuration: CameraKitBootstrapConfiguration,
    provide?: (c: PublicContainer) => PublicContainer
): Promise<CameraKit> {
    console.info(
        `Camera Kit SDK: ${environment.PACKAGE_VERSION} (${lensCoreWasm.version}/${lensCoreWasm.buildNumber})`
    );

    try {
        const startTimeMs = performance.now();

        assert(isSafeString(configuration.apiToken), configurationError("Invalid or unsafe apiToken provided."));

        const configurationFactory = createCameraKitConfigurationFactory(configuration);

        // Public container holds services which applications can overwrite with their own implementations.
        const defaultPublicContainer = Container.provides(configurationFactory)
            .provides(pageVisibilityFactory)
            .provides(defaultFetchHandlerFactory)
            .provides(remoteMediaAssetLoaderFactory)
            .provides(lensSourcesFactory)
            .provides(remoteApiServicesFactory)
            .provides(uriHandlersFactory);

        const publicContainer = provide ? provide(defaultPublicContainer) : defaultPublicContainer;

        // Now that the client's provide() function has completed and the configuration override is ready,
        // we create another container to initialize the logger. This ensures that logging is available
        // as we continue bootstrapping. We don't initialize the logger as part of the defaultPublicContainer
        // because we don't want applications to provide their own logger implementations,
        // and we're not interested in errors thrown by their provide() function.
        // Below is the minimum required container to report errors to Blizzard.
        const telemetryContainer = Container.provides(publicContainer)
            .provides(cameraKitServiceFetchHandlerFactory)
            .provides(gprcHandlerFactory)
            .provides(logEntriesFactory)
            .run(registerLogEntriesSubscriber)
            .provides(lensesClientFactory)
            .provides(requestStateEventTargetFactory)
            .provides(metricsEventTargetFactory)
            .provides(metricsHandlerFactory)
            .provides(operationalMetricReporterFactory)
            .provides(reportGlobalException)
            .provides(cofHandlerFactory)
            .provides(remoteConfigurationFactory)
            .provides(legalPromptFactory)
            .provides(legalStateFactory)
            // We'll run a PartialContainer containing reporters for globally-scoped metrics. Running this container
            // allows each metric reporter to initialize itself (e.g. by adding event listeners to detect when certain
            // actions occur). This PartialContainer also includes the service which listens to locally-reported metrics
            // and sends them to our backend.
            .run(reportGloballyScopedMetrics)
            .run(businessEventsReporterFactory);

        // Run the exception logger so that it can subscribe to log events -- we can't use `Container.run()` because
        // reportGlobalException is also used as a dependency by other Services (and run does not provide Services,
        // it just runs them once).
        telemetryContainer.get(reportGlobalException.token);

        // At this point, logger is configured to report to console and Blizzard.

        await assertPlatformSupported();

        // LensCore is a foundational component which must be created asynchronously.
        // But it's annoying for every consumer of LensCore to have to wait on Promise<LensCore>
        // (which means they become async themselves). So we'll create a DI container which provides Promise<LensCore>,
        // wait for that promise once here, then create a new DI container that just contains LensCore.
        const lensCore = await telemetryContainer.provides(lensCoreFactory).get(lensCoreFactory.token);

        const container = telemetryContainer
            .provides(Injectable(lensCoreFactory.token, () => lensCore))
            .provides(cameraKitLensSourceFactory)
            .provides(lensPersistenceStoreFactory)
            .provides(deviceDependentAssetLoaderFactory)
            .provides(staticAssetLoaderFactory)
            .provides(lensAssetRepositoryFactory)
            .provides(lensRepositoryFactory)
            .provides(cameraKitFactory);

        const cameraKit = container.get(cameraKitFactory.token);

        const bootstrapTimeMs = performance.now() - startTimeMs;
        const reporter = container.get(operationalMetricReporterFactory.token);
        reporter.timer("bootstrap_time", bootstrapTimeMs);

        return cameraKit;
    } catch (error) {
        if (shouldWrapError(error)) {
            error = bootstrapError("Error occurred during Camera Kit bootstrapping.", error);
        }
        logger.error(error);
        throw error;
    }
}

/**
 * Extensions offer a way to provide custom implementations of certain parts of the CameraKit SDK.
 *
 * This enables more advanced use-cases, in which the default behavior of the SDK is substantially altered. For example,
 * replacing the default implementation that loads remote lens assets with a custom implementation that returns
 * different assets based on some business logic within the application.
 *
 * An extension is implemented as a [PartialContainer] – a collection of factory functions, each with its own
 * dependencies, which each provide some "Service." A Service can be of any type, and the CameraKit SDK defines its
 * own Services, some of which can be overridden by providing a custom implementation of the type via an extension.
 *
 * Here's an example of how extensions might be used:
 * ```ts
 * import { bootstrapCameraKit, createExtension, remoteMediaAssetLoaderFactory } from '@snap/camera-kit'
 *
 * const myCustomRemoteAssetLoader = Injectable(
 *   remoteMediaAssetLoaderFactory.token,
 *   [remoteMediaAssetLoaderFactory.token] as const,
 *   (defaultLoader: AssetLoader): AssetLoader => {
 *     return async (asset, lens) => {
 *       if (lens?.id === MY_SPECIAL_LENS) {
 *         return (await fetch('my/asset.glb')).arrayBuffer()
 *       }
 *       return defaultLoader(asset, lens)
 *     }
 *   },
 * )
 *
 * const myExtension = createExtension().provides(myCustomeRemoteAssetLoader)
 * const cameraKit = bootstrapCameraKit(config, container => container.provides(myExtension))
 * ```
 *
 * This also enables greater modularity – the person/team creating the extension can do so in their own package, which
 * could be shared by many applications that all require the same functionality.
 *
 * @returns A {@link PartialContainer} which can be used to create a collection of Services, and can later be provided
 * to CameraKit's DI container during {@link bootstrapCameraKit}.
 *
 * @category Bootstrapping and Configuration
 */
export function createExtension(): PartialContainer {
    return new PartialContainer({});
}
