import { getLogger } from "../logger/logger";
import { Persistence, ValidKey } from "./Persistence";

const logger = getLogger("ExpiringPersistence");

/**
 * Create a Persistence that will remove entries after they expire.
 *
 * An expiration function must be provided, which is called each time a value is stored. It must return the expiration
 * time for that value, given in seconds from now. For example, to expire a value 24 hours after it is stored, the
 * expiration function should return 86400 (the number of seconds in 24 hours).
 */
export class ExpiringPersistence<T> implements Persistence<T> {
    constructor(
        private readonly expiration: (value: T) => number,
        private readonly persistence: Persistence<[number, T]>
    ) {
        this.removeExpired().catch((error) => {
            logger.warn("Failed to cleanup expired entries on startup.", error);
        });
    }

    get size(): number {
        return this.persistence.size;
    }

    async retrieve(key: ValidKey): Promise<T | undefined> {
        const [expiry, value] = (await this.persistence.retrieve(key)) ?? [];
        if (value === undefined || expiry === undefined) return undefined;

        if (Date.now() > expiry) {
            await this.persistence.remove(key).catch((error) => {
                logger.warn(`Key ${key} is expired, but removing it from persistence failed.`, error);
            });
            return undefined;
        }
        return value;
    }

    async retrieveAll(): Promise<Array<[ValidKey, T]>> {
        const now = Date.now();
        return (await this.persistence.retrieveAll()).filter(([, [expiry]]) => expiry >= now).map(([, v]) => v);
    }

    remove(key: ValidKey): Promise<void> {
        return this.persistence.remove(key);
    }

    async removeAll(): Promise<T[]> {
        const results = await this.persistence.removeAll();
        return results.map(([, v]) => v);
    }

    async removeExpired(): Promise<void> {
        for (const [key, [expiry]] of await this.persistence.retrieveAll()) {
            if (Date.now() >= expiry) {
                await this.persistence
                    .remove(key)
                    .catch((error) => logger.warn(`Failed to remove expired key ${key}.`, error));
            }
        }
    }

    store(value: T): Promise<ValidKey>;
    store(key: ValidKey, value: T): Promise<ValidKey>;
    store(keyOrValue: T | ValidKey, maybeValue?: T): Promise<ValidKey> {
        const [key, value] =
            maybeValue === undefined ? [undefined, keyOrValue as T] : [keyOrValue as ValidKey, maybeValue];
        const expiry = Date.now() + this.expiration(value) * 1000;
        return key === undefined
            ? this.persistence.store([expiry, value])
            : this.persistence.store(key, [expiry, value]);
    }
}
