import { filter } from 'rxjs';
import { fromEntries } from './types';
/**
 * Define an Action by providing a name and (optionally) the type of data the Action carries. Returns a function which
 * creates actions of that type.
 *
 * @param name The action's name, should be unique among all actions used by a single state machine.
 * @returns The function returned is used to specify the type of data the Action carries – it must be called to return
 * the actual action creator, which is then strongly typed.
 */
export const defineAction = (name) => () => {
    const actionCreator = (data) => ({
        name,
        data,
    });
    Object.defineProperty(actionCreator, 'name', { value: name });
    // Safety: Object.defineProperty has added the `name` property, but the TS compiler doesn't know that.
    return actionCreator;
};
/**
 * Create an object from action creators, where each property is the name of the action.
 *
 * @param actions Pass one or more actions as arguments, using [[defineAction]] to create each action.
 * @returns
 */
export const defineActions = (...actions) => {
    const entries = actions.map((s) => [s.name, s]);
    // Safety: TS isn't able to infer that keys are the action names, so we have to cast – but we're just extracting the
    // name property and using it as the key (we're doing no other manipulation), so we know we'll end up with the
    // correct ActionCreatorMap.
    return fromEntries(entries);
};
/**
 * Filter an `Observable<[Action, State]>` to only emit when the action matches one of the action names given as
 * arguments.
 * ```ts
 * myStateMachine.pipe(
 *   forActions('myFirstAction', 'mySecondAction'),
 * ).subscribe(doSomethingOnEitherAction)
 * ```
 *
 * @param actions Pass one or more action names as arguments, used to filter an `Observable<[Action, State]>`
 * @returns
 */
export function forActions(...actions) {
    return filter((v) => actions.some(action => v[0].name === action));
}
/**
 * Determine if a given action is of a specific type, given by an action name. This narrows the type of the action.
 *
 * @param action An action with a broad type (e.g. a union of many actions)
 * @param name The name of a specific action.
 * @returns Narrows the type of the action argument to that action which has the given name.
 */
export function isAction(action, name) {
    return action.name === name;
}
