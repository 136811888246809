import {
    defineAction,
    defineActions,
    defineState,
    defineStates,
    dispatch,
    forActions,
    inStates,
    StateMachine,
} from "@snap/state-management";
import { catchError, forkJoin, from, map, merge, of, switchMap } from "rxjs";
import { Injectable } from "../dependency-injection/Injectable";
import {
    LegalDocument,
    LegalDocument_Type,
    LegalPrompt as LegalPromptProto,
} from "../generated-proto/pb_schema/camera_kit/v3/legal_prompt";
import { ConfigResult } from "../generated-proto/pb_schema/cdp/cof/config_result";
import { getLogger } from "../logger/logger";
import { ExpiringPersistence } from "../persistence/ExpiringPersistence";
import { IndexedDBPersistence } from "../persistence/IndexedDBPersistence";
import { RemoteConfiguration, remoteConfigurationFactory } from "../remote-configuration/remoteConfiguration";
import { GetInitializationConfigResponse } from "../generated-proto/pb_schema/camera_kit/v3/service";
import { LegalPromptFactory, legalPromptFactory } from "./legalPrompt";

const logger = getLogger("LegalState");

type SupportedDocumentType =
    | LegalDocument_Type.PRIVACY_POLICY
    | LegalDocument_Type.TERMS_OF_SERVICE
    | LegalDocument_Type.LEARN_MORE;

/**
 * We store a hash of the last accepted ToS content. This is how we determine if the user previously accepted the
 * relevant ToS.
 *
 * ToS acceptance is only valid for 12 hours. That is, if legal status is checked and the last acceptance occurred more
 * than 12 hours ago, the user must be prompted to accept again.
 */
const tosContentHashExpiry = 12 * 60 * 60;
const tosContentHashKey = "lastAcceptedTosContentHash";

const createLegalState = () => {
    const states = defineStates(defineState("unknown")(), defineState("accepted")(), defineState("rejected")());

    const actions = defineActions(
        defineAction("requestLegalPrompt")(),
        defineAction("accept")<string>(),
        defineAction("reject")<string>()
    );

    return new StateMachine(actions, states, states.unknown(), (actions) => {
        return merge(
            actions.pipe(
                inStates("unknown"),
                forActions("accept"),
                map(() => states.accepted())
            ),
            actions.pipe(
                inStates("unknown"),
                forActions("reject"),
                map(() => states.rejected())
            ),

            // We don't treat "rejected" as a terminal state -- if we get another request to display the legal prompt,
            // even though we're in the rejected state, we'll transition back to unknown and the prompt will be shown.
            //
            // Conversely, we do treat "accepted" as a terminal state -- we will not transition back to unknown or
            // show the legal prompt if we're already in accepted state, even if we get a request to display the prompt.
            actions.pipe(
                inStates("rejected"),
                forActions("requestLegalPrompt"),
                map(() => states.unknown())
            )
        );
    });
};

const defaultLegalDocumentDate = new Date("2021-09-30T00:00:00+00:00");
const defaultLegalPrompt = LegalPromptProto.fromPartial({
    documents: [
        LegalDocument.fromPartial({
            type: LegalDocument_Type.PRIVACY_POLICY,
            webUrl: "https://values.snap.com/privacy/privacy-policy",
            version: "1",
            timestamp: defaultLegalDocumentDate,
        }),
        LegalDocument.fromPartial({
            type: LegalDocument_Type.TERMS_OF_SERVICE,
            webUrl: "https://snap.com/terms",
            version: "1",
            timestamp: defaultLegalDocumentDate,
        }),
        LegalDocument.fromPartial({
            type: LegalDocument_Type.LEARN_MORE,
            webUrl: "https://support.snapchat.com/article/camera-information-use",
            version: "1",
            timestamp: defaultLegalDocumentDate,
        }),
    ],
    // By default, we adopt a fail-open approach, which means that if COF fails,
    // we do not display ToS for the following reasons:
    // 1. It provides better experince for big partners with ToS disabled
    // 2. It has minimal risk
    disabled: true,
});

const defaultInitConfig = GetInitializationConfigResponse.fromPartial({});

const hasAnyValue = (c: ConfigResult): c is ConfigResult & { value: { anyValue: { value: Uint8Array } } } => {
    return c.value?.anyValue?.value instanceof Uint8Array;
};

const getDocumentOrDefault =
    (documents: LegalDocument[]) =>
    <T extends SupportedDocumentType>(type: T): LegalDocument & { type: T } => {
        return (documents.find((d) => d.type === type) ??
            defaultLegalPrompt.documents.find((d) => d.type === type)!) as LegalDocument & { type: T };
    };

export type LegalState = ReturnType<typeof createLegalState>;

/**
 * We maintain the state of the user's acceptance of Snap's various legal documents. Other components may require that
 * the legal state be "accepted" before proceeding (e.g. to apply a lens).
 *
 * On page load, the legal state starts as "unknown." If a request is made to determine the legal state, the following
 * business logic is implemented:
 *
 * 1. Download remote COF config value containing the legal documents + a `disabled` field to optionally skip the legal
 * requirement.
 * 2. Check for a `lastAcceptedTosContentHash` stored in IndexedDB.
 * 3. Prompt the user to accept/reject the legal documents.
 *
 *                     ┌────────────────────────────────────────────────────────┐
 *                     │       COF config value includes "disabled: true"       │
 *                     └────────────────────────────────────────────────────────┘
 *                                                  │
 *                                                  │
 *                           ┌────────No────────────┴────────────Yes──────────────────┐
 *                           │                                                        │
 *                           ▼                                                        │
 *        ┌────────────────────────────────────┐                                      ▼
 *        │"lastAcceptedTosContentHash" exists │                               ┌────────────┐
 *        └────────────────────────────────────┘                               │  Skip ToS  │
 *                           │                                                 └────────────┘
 *                           │                                                        ▲
 *        ┌─────────────No───┴───────Yes────────────┐                                 │
 *        │                                         │                                 │
 *        │                                         │                                 │
 *        ▼                                         ▼                                 │
 * ┌────────────┐              ┌────────────────────────────────────────┐             │
 * │  Show ToS  │              │  Hash of ToS content from COF matches  │             │
 * └────────────┘              │      "lastAcceptedTosContentHash"      │             │
 *        ▲                    └────────────────────────────────────────┘             │
 *        │                                         │                                 │
 *        │                                         │                                 │
 *        └──────────────────No─────────────────────┴───────────────────Yes───────────┘
 *
 * @internal
 */
export const legalStateFactory = Injectable(
    "legalState",
    [remoteConfigurationFactory.token, legalPromptFactory.token] as const,
    (remoteConfig: RemoteConfiguration, legalPrompt: LegalPromptFactory): LegalState => {
        const persistance = new ExpiringPersistence<string>(
            () => tosContentHashExpiry,
            new IndexedDBPersistence({ databaseName: "Legal" })
        );
        const getLastAcceptedTosContentHash = () =>
            from(persistance.retrieve(tosContentHashKey).catch((error) => logger.warn(error)));

        const setLastAcceptedTosContentHash = (hash: string) =>
            persistance.store(tosContentHashKey, hash).catch((error) => logger.warn(error));

        const legalState = createLegalState();

        legalState.events
            .pipe(
                inStates("unknown"),
                forActions("requestLegalPrompt"),
                switchMap(() =>
                    forkJoin({
                        cofConfig: remoteConfig.get("CAMERA_KIT_LEGAL_PROMPT").pipe(
                            map((configResults) => {
                                const config = configResults.find(hasAnyValue);
                                if (!config) return defaultLegalPrompt;
                                return LegalPromptProto.decode(config.value.anyValue.value);
                            }),
                            catchError((error) => {
                                logger.error(error);
                                return of(defaultLegalPrompt);
                            })
                        ),
                        initConfig: remoteConfig.getInitializationConfig().pipe(
                            catchError((error) => {
                                logger.error(error);
                                return of(defaultInitConfig);
                            })
                        ),
                    })
                ),
                switchMap(({ cofConfig, initConfig }) => {
                    // NOTE: Currently, we check two sources to determine whether ToS is disabled or not:
                    // COF and initConfig. Legal document links are pulled only from COF (or defaults),
                    // because initConfig has not been implemented yet. In the future, we may choose
                    // to exclusively use initConfig, which could incorporate the COF call internally:
                    // https://jira.sc-corp.net/browse/CAMKIT-4791

                    if (initConfig.legalPrompt?.disabled) {
                        return of(legalState.actions.accept("disabled"));
                    }

                    if (cofConfig.disabled) {
                        return of(legalState.actions.accept("disabled"));
                    }

                    const documentOfType = getDocumentOrDefault(cofConfig.documents);
                    const prompt = legalPrompt(
                        documentOfType(LegalDocument_Type.PRIVACY_POLICY),
                        documentOfType(LegalDocument_Type.TERMS_OF_SERVICE),
                        documentOfType(LegalDocument_Type.LEARN_MORE),
                        initConfig.childrenProtectionActRestricted
                    );

                    return getLastAcceptedTosContentHash().pipe(
                        switchMap((lastAcceptedTosContentHash) => {
                            if (prompt.contentHash === lastAcceptedTosContentHash) return of(true);

                            // Delegate prompting the end-user to accept/reject the legal documents. This returns with
                            // a Promise<boolean> indicating accept/reject.
                            return prompt.show();
                        }),
                        map((didAccept) => {
                            if (!didAccept) return legalState.actions.reject(prompt.contentHash);
                            setLastAcceptedTosContentHash(prompt.contentHash);
                            return legalState.actions.accept(prompt.contentHash);
                        })
                    );
                }),
                dispatch(legalState)
            )
            .subscribe({
                error: logger.error,
            });

        return legalState;
    }
);
