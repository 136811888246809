import { EstimatedLensPerformance } from "./benchmark/estimateLensPerformanceCluster";
import { copyDefinedProperties } from "./common/copyDefinedProperties";
import { getConfigurationOverrides } from "./configurationOverrides";
import { Injectable } from "./dependency-injection/Injectable";
import { LogLevelName } from "./logger/logger";

/**
 * From T, pick the set of properties whose values are optional. Create a new type containing only those properties.
 */
type PickOptionals<T> = {
    [K in keyof T as T[K] extends Exclude<T[K], undefined> ? never : K]: T[K];
};

/**
 * Defaults are provided for runtime configuration and any optional bootstrap configuration properties which require
 * defaults.
 */
const defaultConfiguration: CameraKitRuntimeConfiguration & PickOptionals<CameraKitBootstrapConfiguration> = {
    // If the applications doesn't provide performance data (e.g. via estimateLensPerformance), we'll use 0 to indicate
    // no performance estimation occurred. This is indicative of typical performance-targeting logic, which often
    // defaults to the lowest-tier experience in the absense of performance cluster data.
    lensPerformance: { cluster: 0, benchmarks: [], webglRendererInfo: "unknown" },
    logger: "noop",
    logLevel: "info",
    shouldUseWorker: true,
    apiHostname: "camera-kit-api.snapar.com",
    userAgentFlavor: "release",
};

interface CameraKitRuntimeConfiguration {
    lensPerformance: EstimatedLensPerformance | Promise<EstimatedLensPerformance>;
    logger: "noop" | "console";
    logLevel: LogLevelName;
    shouldUseWorker: boolean;
    apiHostname: CameraKitApiHostname;
    userAgentFlavor: "release" | "debug";
}

export type CameraKitApiHostname = "camera-kit-api.snapar.com" | "api-kit.snapchat.com";

/**
 * Configuration which must be provided when calling {@link bootstrapCameraKit}. These values are used to create various
 * CameraKit components.
 *
 * @category Bootstrapping and Configuration
 */
export interface CameraKitBootstrapConfiguration {
    /**
     * Long-lived token granting your application access to CameraKit APIs. This is found in the SnapKit Dev Portal,
     * where it's called the API Token.
     */
    apiToken: string;

    /**
     * Determine where to print CameraKit log messages. By default no logs will be printed.
     *
     * CameraKit emits log messages to help diagnose and root cause issues that may occur during the development of a
     * host application. The printing of these can be controlled via the following
     * options:
     *  - `noop`: log messages are ignored.
     *  - `console`: log messages are printed to console.
     */
    logger?: "noop" | "console";

    /**
     * Log only if a logged entry level is greater than or equal to this level. Here is the order of levels:
     * error > warn > log = info > debug. Default value is "info".
     */
    logLevel?: LogLevelName;

    /**
     * Some lenses may decide to modify their behavior based on the performance of the current environment. If you are
     * using such lenses, providing an estimation of lens performance may lead to better user experience (especially on
     * low-performance devices).
     *
     * Running the {@link estimateLensPerformance} function will run benchmarks and estimate an appropriate lens
     * performance cluster (i.e. a performance rating) based on the current environment.
     *
     * Lower cluster = worse expected performance capability.
     *
     * @example
     * ```ts
     * import { bootstrapCameraKit, estimateLensPerformance } from '@snap/camera-kit`
     *
     * const cameraKit = await bootstrapCameraKit({
     *   apiToken: '...',
     *   lensPerformance: estimateLensPerformance(),
     * })
     * ```
     */
    lensPerformance?: EstimatedLensPerformance | Promise<EstimatedLensPerformance>;

    /**
     * In recommended production deployments, the WebAssembly assets required by CameraKit will be downloaded from an
     * optimized CDN. But sometimes (e.g. during development or within a CI pipeline), it may be necessary to download
     * these assets from somewhere else.
     *
     * This configuration option allows the application to specify URLs to be used for both the WebAssembly and JS glue
     * file that are used to run and interact with CameraKit's rendering engine.
     */
    lensCoreOverrideUrls?: { wasm: string; js: string };

    /**
     * In recommended production deployments, the WebAssembly assets required by CameraKit will be downloaded from an
     * optimized CDN. But sometimes during development or within a CI pipeline, it may be necessary to download these
     * assets from somewhere else. With a provided `wasmEndpointOverride`, asset URLs will be automatically generated
     * based on this root endpoint.
     */
    wasmEndpointOverride?: string;

    /**
     * Applications may optionally provide a unique identifier called analyticsId. This ID would enable Camera Kit to
     * improve data reporting and accuracy and to better support potential needs related to an application's lens and
     * user analytics.
     */
    analyticsId?: string;
}

/**
 * This type represents the result of merging user-supplied config with default config -- as such, it has no nullable
 * fields, making it a more convenient type for other components to use.
 *
 * @internal
 */
export type CameraKitConfiguration = CameraKitRuntimeConfiguration & CameraKitBootstrapConfiguration;

/** @internal */
export const configurationToken = "configuration";

/**
 * Returns true if given browser is iPhone, iPad or iPod.
 */
function isHandledAppleDevice() {
    // We use the same approach LC uses:
    // eslint-disable-next-line max-len
    // https://github.sc-corp.net/Snapchat/LensCore/blob/285ac47cad7fe5268f38d1bab82d51b7b19d6b48/Src/PlatformSpecific/WebAssembly/WebEnvironmentInfo.cpp#L81
    return (
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 2)
    );
}

/** @internal */
export const createCameraKitConfigurationFactory = (configuration: CameraKitBootstrapConfiguration) => {
    // always leave debug mode warning about overrides in console
    const overrides = getConfigurationOverrides();
    if (overrides) {
        console.warn("Configuration overrides applied", overrides);
    }
    return Injectable(configurationToken, (): CameraKitConfiguration => {
        // We'll ensure that we handle errors on any Promises passed as config values, otherwise we either must
        // handle them separately wherever they're used, or rejections would go unhandled.
        const safeConfig: CameraKitBootstrapConfiguration = {
            ...configuration,
            lensPerformance:
                configuration.lensPerformance instanceof Promise
                    ? // Safety: defaultConfiguration.lensPerformance is defined (it's hardcoded above).
                      configuration.lensPerformance.catch(() => defaultConfiguration.lensPerformance!)
                    : configuration.lensPerformance,
        };
        return {
            ...defaultConfiguration,
            // TODO: Safari 17 has an issue with offscreen canvas which results in stuttering effect on iOS.
            // Once Safari has that fixed, we should remove this check, see https://jira.sc-corp.net/browse/CAMKIT-5985
            shouldUseWorker: isHandledAppleDevice() ? false : defaultConfiguration.shouldUseWorker,
            ...copyDefinedProperties(safeConfig),
            ...copyDefinedProperties(overrides ?? {}),
        };
    });
};
