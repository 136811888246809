import { PageVisibility } from "../common/pageVisibility";
import { ChainableHandler, Handler, RequestMetadata } from "./HandlerChainBuilder";

interface MappableRequest<T> {
    map: () => T | Promise<T>;
    next: (request: T) => Promise<void>;
    reject: (reason: unknown) => void;
}

/**
 * Map from one request type to another, potentially asynchronously.
 *
 * **NOTE:** If `maxMapConcurrency` is set to some finite number, and more requests are handled than are allowed to
 * be concurrently mapped, the waiting requests will be placed into a unbounded buffer. If, for example, requests are
 * handled with high frequency, `maxMapConcurrency` is low, and the `map` function returns a long-running Promise, this
 * buffer could use a large amount of memory. Keep this in mind when using this handler.
 *
 * @param map Transform each request, may be sync or async.
 * @param pageVisibility Determines whether to flush buffered requests when the page becomes hidden.
 * `false` value indicates that page visibility handling is avoided, while
 * a {@link PageVisibility} instance is used to subscribe to page visibility change events.
 * @param maxMapConcurrency If the `map` function is async, it will be invoked at most this number of times
 * concurrently. Setting this to 1 could be useful if it's important for `map` to be called in serial.
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createMappingHandler = <Req, MappedReq, Res, Meta extends RequestMetadata>(
    map: (request: Req) => MappedReq | Promise<MappedReq>,
    pageVisibility: PageVisibility | false,
    maxMapConcurrency: number = Number.POSITIVE_INFINITY
): ChainableHandler<Req, Res, MappedReq, Res, Meta> => {
    const buffer: MappableRequest<MappedReq>[] = [];
    let mapConcurrency = 0;

    const processRequest = async (request: MappableRequest<MappedReq>) => {
        try {
            mapConcurrency++;
            const mapped = request.map();
            // We want to make sure that if the mapping operation is not async, we don't introduce asynchronicity here
            // (which unfortunately happens even if you `await` a non-Promise value). This is important so that e.g.
            // handlers which run when the page is terminated can send requests synchronously, since the browser may
            // not pick up any async handlers registered to run on the following event loop.
            if (mapped instanceof Promise) request.next(await mapped);
            else if (mapped) request.next(mapped);
        } catch (error) {
            request.reject(error);
        } finally {
            mapConcurrency--;
        }
        while (buffer.length > 0 && mapConcurrency < maxMapConcurrency) {
            // Safety: we just checked for `buffer.length > 0`, so the shifted value will never be undefined.
            processRequest(buffer.shift()!);
        }
    };

    // This may indicate that the page is being unloaded, in which case we may want to flush any buffered requests
    // regardless of our max concurrency – otherwise those requests will be lost when the page terminates.
    if (pageVisibility) {
        pageVisibility.onPageHidden(() => {
            while (buffer.length > 0) processRequest(buffer.shift()!);
        });
    }

    return (next: Handler<MappedReq, Res, Meta>) => (request: Req, metadata?: Meta) => {
        return new Promise<Res>((resolve, reject) => {
            const mappableRequest: MappableRequest<MappedReq> = {
                map: () => map(request),
                next: (mappedRequest) => next(mappedRequest, metadata).then(resolve).catch(reject),
                reject,
            };
            if (mapConcurrency < maxMapConcurrency) processRequest(mappableRequest);
            else buffer.push(mappableRequest);
        });
    };
};
