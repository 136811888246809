import { isValidKey, Persistence, ValidKey } from "./Persistence";

type IDBRequestPromise<T = any> = Promise<T> & { request: IDBRequest<T> };

interface EnhancedIDBTransaction {
    tx: IDBTransaction;
    store: IDBObjectStore;
    done: Promise<void>;
}

interface EnhancedIDBCursor<T> {
    cursor: (IDBCursor & { readonly value: T }) | null;
    continue: () => Promise<EnhancedIDBCursor<T>>;
}

/**
 * The IndexedDB API makes use of event callbacks that can be cumbersome to use. This method wraps an IDBRequest in a
 * Promise, making it easier to use.
 */
function wrapRequest(request: IDBOpenDBRequest): Promise<IDBDatabase> & { request: IDBOpenDBRequest };
function wrapRequest<T>(request: IDBRequest<T>): IDBRequestPromise<T>;
function wrapRequest(request: IDBRequest): IDBRequestPromise {
    const p = new Promise((resolve, reject) => {
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
    (p as IDBRequestPromise).request = request;
    return p as IDBRequestPromise;
}

/**
 * IndexedDB cursor requests are unique, in that their `onsuccess` handler may be called multiple times. To support
 * this, and allow for an easier-to-use Promise-based API, we wrap the cursor request to support usage like:
 * ```ts
 * let request = await wrapCursorRequest(store.openCursor())
 * while (request.cursor) {
 *   console.log(request.cursor.key, request.cursor.value)
 *   request = await request.continue()
 * }
 * ```
 */
function wrapCursorRequest<T>(request: IDBRequest<IDBCursorWithValue | null>): Promise<EnhancedIDBCursor<T>> {
    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            const cursor = request.result;
            if (!cursor) resolve({ cursor: null, continue: () => Promise.reject() });
            else
                resolve({
                    cursor,
                    continue: () => {
                        cursor.continue();
                        return wrapCursorRequest<T>(request);
                    },
                });
        };
        request.onerror = () => reject(request.error);
    });
}

/**
 * Specify a database name for this {@link IndexedDBPersistence} instance to use.
 * This will be prefixed by `Snap.CameraKit`.
 *
 * A databaseVersion and objectStore may also be specified. Keep in mind the following limitations:
 * - IndexedDBPersistence currently does nothing to migrate data between versions.
 * - If two different IndexedDBPersistence instances use the same databaseName, they must also use the same objectStore.
 * Otherwise a race condition will occur which prevents the creation of all but one objectStore per database.
 */
export interface IndexedDBPersistenceOptions {
    databaseName: string;
    databaseVersion?: number;
    objectStore?: string;
}

/**
 * A simple key/value persistence using an IndexedDB storage backend.
 *
 * See [Using IndexedDB](https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB) for an
 * introduction to how IndexedDB works, its APIs, and how to use it.
 *
 * Note: Currently there is no support for database upgrades. Each instance of this class uses a single IDBObjectStore
 * object set at instantiation time, and there are no hooks for performing migrations between versions. This may be
 * added in the future if such functionality is needed.
 */
export class IndexedDBPersistence<T> implements Persistence<T> {
    size: number;

    private db: Promise<IDBDatabase>;
    private readonly databaseName: string;
    private readonly databaseVersion?: number;
    private readonly objectStore: string;

    /**
     * Construct an {@link IndexedDBPersistence} instance corresponding to a given IndexedDB database version.
     *
     * Throws `ConstraintError` if the version number is invalid (e.g. NaN, or less than 1).
     */
    constructor(options: IndexedDBPersistenceOptions) {
        // We'll namespace our DB names to A) avoid collisions with host applications and B) be transparent about who
        // is using persistence.
        this.databaseName = `Snap.CameraKit.${options.databaseName}`;
        this.databaseVersion = options.databaseVersion;
        this.objectStore = options.objectStore ?? options.databaseName;

        // `open()` will throw if the version is invalid -- this is desirable vs. rejecting the `db` promise, since then
        // the error is only reported when callers try to perform some operation. Instead, we want the instantiating
        // code to see the error.
        this.db = this.openDatabase(indexedDB.open(this.databaseName, this.databaseVersion));
        this.size = 0;
    }

    retrieve(key: ValidKey): Promise<T | undefined> {
        return this.simpleTransaction("readonly", (store) => store.get(key));
    }

    async retrieveAll(): Promise<Array<[ValidKey, T]>> {
        const results: Array<[ValidKey, T]> = [];
        const { store, done } = await this.transaction("readonly");
        let request = await wrapCursorRequest<T>(store.openCursor());

        while (request.cursor) {
            results.push([request.cursor.primaryKey as ValidKey, request.cursor.value]);
            request = await request.continue();
        }

        await done;
        return results;
    }

    async remove(key: ValidKey): Promise<void> {
        await this.simpleTransaction("readwrite", (store) => store.delete(key));
        this.size--;
    }

    async removeAll(): Promise<T[]> {
        const results: T[] = [];
        const { store, done } = await this.transaction("readwrite");
        let request = await wrapCursorRequest<T>(store.openCursor());
        const deleteRequests: Promise<any>[] = [];
        while (request.cursor) {
            results.push(request.cursor.value);
            // If any of the deletes fail (e.g. if the user deletes the object store during the transaction), it will
            // fail the whole transaction. Since the primary expected cause of this failure mode is that the entire
            // object store no longer exists, it's unlikely this will result in unbounded DB growth. That said, callers
            // may want to attempt to retry the removal, or raise an alarm if the persistence size grows unexpectedly.
            deleteRequests.push(wrapRequest(store.delete(request.cursor.key)));
            request = await request.continue();
        }
        await Promise.all(deleteRequests.concat(done));
        this.size = 0;
        return results;
    }

    async store(value: T): Promise<ValidKey>;
    async store(key: ValidKey, value: T): Promise<ValidKey>;
    async store(keyOrValue: T | ValidKey, maybeValue?: T): Promise<ValidKey> {
        const [key, value] = maybeValue === undefined ? [undefined, keyOrValue] : [keyOrValue, maybeValue];

        // The key must be ValidKey | undefined.
        if (!isValidKey(key) && typeof key !== "undefined")
            throw new TypeError(`IndexedDBPersistence failed to ` + `store a value. Invalid key type: ${typeof key}`);

        const storedKey = await this.simpleTransaction("readwrite", (store) => store.put(value, key));
        this.size++;

        // Type safety: we already assert any given key is valid, and if the key is undefined IndexedDB will generate
        // a numeric key (https://w3c.github.io/IndexedDB/#key-generator).
        return storedKey as ValidKey;
    }

    private async openDatabase(request: IDBOpenDBRequest): Promise<IDBDatabase> {
        // The `open()` call will throw if databaseVersion is invalid (e.g. < 1).
        const dbPromise = wrapRequest(request);
        dbPromise.request.onupgradeneeded = () => {
            try {
                // The following DOMExceptions may be thrown by `createObjectStore()` – they should all be logically
                // impossible. We handle the one recoverable exception which could occur below.
                //
                // TransactionInactiveError: the database does not exist.
                // InvalidStateError: `createObjectStore` was called outside a `versionchange` transaction.
                // InvalidAccessError: `autoIncrement` is true and `keyPath` contains an empty string.
                dbPromise.request.result.createObjectStore(this.objectStore, { autoIncrement: true });
            } catch (error) {
                // ConstraintError is thrown if the object store already exists. Could happen if multiple tabs to the
                // same domain are opened and race to create the object store. In this case we can safely ignore the
                // error and continue.
                if (error instanceof DOMException && error.name === "ConstraintError") return;
                throw error;
            }
        };
        const db = await dbPromise;
        db.onclose = () => {
            // The 'close` event fires when the DB is unexpectedly closed (e.g. user clears application data). We'll
            // attempt to re-open it (which may fail, in which case no further attempts will be made, and all future
            // transactions will fail).
            this.db = this.openDatabase(indexedDB.open(this.databaseName, this.databaseVersion));
        };
        return db;
    }

    private async simpleTransaction<R>(
        mode: IDBTransactionMode,
        operation: (tx: IDBObjectStore) => IDBRequest<R>
    ): Promise<R> {
        const { store, done } = await this.transaction(mode);
        const [result] = await Promise.all([wrapRequest(operation(store)), done]);
        return result;
    }

    private async transaction(mode: IDBTransactionMode): Promise<EnhancedIDBTransaction> {
        const db = await this.db;
        // The following DOMExceptions may be thrown – they should all be logically impossible, or could be
        // triggered by the user deleting or modifying the database (e.g. via DevTools) at the right time. We won't
        // attempt to recover from them now, but may decide to do so in the future (if we see them in the wild).
        //
        // InvalidStateError: `close()` has previously been called on the IDBDatabase
        // NotFoundError: the object store does not exist.
        // TypeError: the `mode` parameter is invalid.
        // InvalidAccessError: the function was called with an empty list of object stores.
        const tx = db.transaction(this.objectStore, mode);

        // Similarly, the following DOMExceptions may be thrown by `objectStore()`:
        //
        // InvalidStateError: the transaction has already completed.
        // NotFoundError: the object store is not in the transaction's scope.
        const store = tx.objectStore(this.objectStore);
        const done = new Promise<void>((resolve, reject) => {
            tx.oncomplete = () => resolve();
            tx.onerror = () => reject(tx.error);
            tx.onabort = () => reject(new DOMException("The transaction was aborted", "AbortError"));
        });
        return { tx, store, done };
    }
}
