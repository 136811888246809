import { forActions } from "@snap/state-management";
import { map } from "rxjs";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { CameraKitLegalPromptResult } from "../../generated-proto/blizzard/cameraKitEvents";
import { LegalState, legalStateFactory } from "../../legal/legalState";
import { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import { MetricsEventTarget, metricsEventTargetFactory } from "../metricsEventTarget";
import {
    operationalMetricReporterFactory,
    OperationalMetricsReporter,
} from "../operational/operationalMetricsReporter";

/**
 * The LegalPrompt metric reports each time a BIPA legal prompt is shown.
 */
export type LegalPrompt = MakeTaggedBusinessEvent<"legalPrompt">;

/**
 * @internal
 */
export const reportLegalState = Injectable(
    "reportLegalState",
    [legalStateFactory.token, metricsEventTargetFactory.token, operationalMetricReporterFactory.token] as const,
    (
        legalState: LegalState,
        metricsEventTarget: MetricsEventTarget,
        operationalMetricsReporter: OperationalMetricsReporter
    ) => {
        legalState.events
            .pipe(
                forActions("accept", "reject"),
                map(([{ data, name }]) => ({
                    name: "legalPrompt" as const,
                    legalPromptId: data,
                    legalPromptResult:
                        name === "accept"
                            ? CameraKitLegalPromptResult.CAMERA_KIT_LEGAL_PROMPT_ACCEPTED
                            : CameraKitLegalPromptResult.CAMERA_KIT_LEGAL_PROMPT_DISMISSED,
                }))
            )
            .subscribe({
                next: (legalPromptEvent) => {
                    metricsEventTarget.dispatchEvent(new TypedCustomEvent("legalPrompt", legalPromptEvent));
                    operationalMetricsReporter.count(
                        "legal_prompt_interaction",
                        1,
                        new Map([
                            [
                                "accepted",
                                (
                                    legalPromptEvent.legalPromptResult ===
                                    CameraKitLegalPromptResult.CAMERA_KIT_LEGAL_PROMPT_ACCEPTED
                                ).toString(),
                            ],
                        ])
                    );
                },
            });
    }
);
