import { webGLError } from "../namedErrors";

const webGLEntityCreationError = (name: string) => webGLError(`Could not create ${name}.`);

export function createProgram(gl: WebGL2RenderingContext, vertexSource: string, fragmentSource: string): WebGLProgram {
    const vertexShader = createShader(gl, vertexSource, gl.VERTEX_SHADER);
    const fragmentShader = createShader(gl, fragmentSource, gl.FRAGMENT_SHADER);
    const program = gl.createProgram();
    if (!program) throw webGLEntityCreationError("WebGLProgram");
    gl.attachShader(program, vertexShader);
    gl.deleteShader(vertexShader);

    gl.attachShader(program, fragmentShader);
    gl.deleteShader(fragmentShader);

    gl.linkProgram(program);

    const success = gl.getProgramParameter(program, gl.LINK_STATUS);
    if (!success) {
        const message = gl.getProgramInfoLog(program);
        gl.deleteProgram(program);
        throw webGLError(`WebGLProgram linking failed with status: ${message}.`);
    }

    return program;
}

export function createShader(gl: WebGL2RenderingContext, source: string, type: number): WebGLShader {
    const shader = gl.createShader(type);
    if (!shader) throw webGLEntityCreationError(`WebGLShader (type ${type})`);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (!success) {
        const message = gl.getShaderInfoLog(shader);
        gl.deleteShader(shader);
        throw webGLError(`WebGLShader (type ${type}) compilation failed with status: ${message}.`);
    }
    return shader;
}

export function createTexture(gl: WebGL2RenderingContext, width: number, height: number): WebGLTexture {
    const texture = gl.createTexture();
    if (!texture) throw webGLEntityCreationError("WebGLTexture");
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    return texture;
}

export function createFramebuffer(gl: WebGL2RenderingContext, texture: WebGLTexture): WebGLFramebuffer {
    const framebuffer = gl.createFramebuffer();
    if (!framebuffer) throw webGLEntityCreationError("WebGLFramebuffer");
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
    return framebuffer;
}

export function setUniform1i(gl: WebGL2RenderingContext, program: WebGLProgram, name: string, data: number): void {
    gl.uniform1i(gl.getUniformLocation(program, name), data);
}

export function setUniform4f(
    gl: WebGL2RenderingContext,
    program: WebGLProgram,
    name: string,
    data: [number, number, number, number]
): void {
    gl.uniform4f(gl.getUniformLocation(program, name), ...data);
}

export function promiseSync(gl: WebGL2RenderingContext): Promise<void> {
    const sync = gl.fenceSync(gl.SYNC_GPU_COMMANDS_COMPLETE, 0);
    if (!sync) throw webGLEntityCreationError("WebGLSync");
    gl.flush();
    return new Promise((resolve, reject) => {
        const waitForSync = (): void => {
            const glEnum = gl.clientWaitSync(sync, 0, 0);
            switch (glEnum) {
                case gl.TIMEOUT_EXPIRED:
                    setTimeout(waitForSync);
                    return;
                case gl.WAIT_FAILED:
                    gl.deleteSync(sync);
                    return reject();
                case gl.ALREADY_SIGNALED:
                case gl.CONDITION_SATISFIED:
                    gl.deleteSync(sync);
                    return resolve();
            }
        };
        waitForSync();
    });
}
