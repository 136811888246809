import { filter } from 'rxjs';
import { defineAction, defineActions } from './actions';
/**
 * Define a State by providing a name and (optionally) the type of data the State carries. Returns a function which
 * creates states of that type.
 *
 * @param name The state's name, should be unique among all states used by a single state machine.
 * @returns The function returned is used to specify the type of data the State carries – it must be called to return
 * the actual state creator, which is then strongly typed.
 */
export const defineState = (name) => () => defineAction(name)();
/**
 * Create an object from state creators, where each property is the name of the state.
 *
 * @param states Pass one or more states as arguments, using [[defineState]] to create each state.
 * @returns
 */
export const defineStates = (...states) => defineActions(...states);
/**
 * Filter an `Observable<[Action, State]>` to only emit when the state matches one of the state names given as
 * arguments.
 * ```ts
 * myStateMachine.pipe(
 *   inStates('myFirstState', 'mySecondState'),
 * ).subscribe(doSomethingIfInEitherState)
 * ```
 *
 * @param states Pass one or more state names as arguments, used to filter an `Observable<[Action, State]>`
 * @returns An Observable which only emits when in one of the given states.
 */
export function inStates(...states) {
    return filter((v) => states.some(state => v[1].name === state));
}
/**
 * Determine if a given state is of a specific type, given by an state name. This narrows the type of the state.
 *
 * @param state A state with a broad type (e.g. a union of many states)
 * @param name The name of a specific state.
 * @returns Narrows the type of the state argument to that state which has the given name.
 */
export function isState(state, name) {
    return state.name === name;
}
