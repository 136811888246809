import { OperationalMetric } from "../../generated-proto/pb_schema/camera_kit/v3/operational_metrics";
import { Metric, MetricDimensions, serializeMetricDimensions } from "./Metric";

/** @internal */
export type HistogramMetric = OperationalMetric & {
    metric: Extract<OperationalMetric["metric"], { $case: "histogram" }>;
};

/** @internal */
export class Histogram extends Metric {
    static level(name: string, level: number, dimsensions: MetricDimensions = {}): Histogram {
        const histogram = new Histogram(name, dimsensions);
        histogram.add(level);
        return histogram;
    }

    private readonly levels: number[] = [];

    constructor(readonly name: string, dimensions: MetricDimensions = {}) {
        super(name, dimensions);
    }

    add(level: number): void {
        this.levels.push(level);
    }

    toOperationalMetric(): HistogramMetric[] {
        const timestamp = new Date();
        const name = `${this.name}${serializeMetricDimensions(this.dimensions)}`;
        return this.levels.map((level) => ({ name, timestamp, metric: { $case: "histogram", histogram: level } }));
    }
}
