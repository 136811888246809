import { benchmarkError } from "../namedErrors";
import { gflopsClusterCenters, benchmarkGflops } from "./benchmarkGflops";

const findNearest = (n: number, arr: number[]) => arr.reduce((a, b) => (Math.abs(a - n) <= Math.abs(b - n) ? a : b));

const webglContextAttributes: WebGLContextAttributes = {
    alpha: false,
    antialias: false,
    premultipliedAlpha: false,
    preserveDrawingBuffer: false,
    depth: false,
    stencil: false,
    failIfMajorPerformanceCaveat: false,
    powerPreference: "high-performance",
};

/** @category Bootstrapping and Configuration */
export type LensPerformanceCluster = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/** @internal */
export type BenchmarkResult = { name: string; value: number };

/**
 * Some lenses may decide to modify their behavior based on the performance of the current environment. If you are
 * using such lenses, providing an estimation of lens performance may lead to better user experience (especially on
 * low-performance devices).
 *
 * The cluster value will be an integer from 1-6 which classifies expected lens performance, where 6 is the highest-
 * performing cluster and 1 the lowest.
 *
 * All the raw benchmark results used to estimate the performance cluster are also included for reporting purposes.
 *
 * @category Bootstrapping and Configuration
 */
export interface EstimatedLensPerformance {
    cluster: LensPerformanceCluster;
    benchmarks: BenchmarkResult[];
    webglRendererInfo: string;
}

/**
 * Run benchmarks which attempt to predict expected lens performance, and assign a cluster (i.e. a performance rating)
 * which the CameraKit SDK can use to optimize certain lenses.
 *
 * @returns A rating from 1-6, indicating expected Lens performance.
 *
 * @category Bootstrapping and Configuration
 */
export async function estimateLensPerformance(): Promise<EstimatedLensPerformance> {
    // Prepare performance measurement.
    const canvas = document.createElement("canvas");
    const gl = canvas.getContext("webgl2", webglContextAttributes);
    if (!gl) throw benchmarkError("WebGL2 is required to compute performance, but it is not supported.");

    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    const webglRendererInfo = debugInfo ? (gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) as string) : "unknown";

    // Measure performance (just GFLOPS for now, but could add more benchmarks in the future).
    const gflops = await benchmarkGflops(gl);

    // Combine performance results and compute a performance rating. Right now we just run a single benchmark, so this
    // is pretty trivial.
    const nearestGflopsClusterCenter = findNearest(gflops.value, Array.from(gflopsClusterCenters.keys()));

    // This should always find a value, but just in case something strange happens inside `findNearest`, we'll default
    // to zero (instead of asserting .get() will always be defined).
    const gflopsCluster = gflopsClusterCenters.get(nearestGflopsClusterCenter) ?? 0;

    return {
        cluster: gflopsCluster,
        benchmarks: [gflops],
        webglRendererInfo,
    };
}
