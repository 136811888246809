interface FrameMetricsState {
    avgFps: number;
    averageProcessingTime: number;
    n: number;
    processingTimeBuckets: Uint32Array;
    procFrameCount: number;
    procFrameMean: number;
    procFrameD2: number;
}

const getDefaultFrameMetricsState = (): FrameMetricsState => ({
    avgFps: 0,
    averageProcessingTime: 0,
    n: 0,
    processingTimeBuckets: new Uint32Array(frameProcessingTimeMedianMax + 1),
    procFrameCount: 0,
    procFrameMean: 0,
    procFrameD2: 0,
});

// This duration is chosen to be larger than we expect frame processing to reasonably take on any device, but smaller
// than the duration of a manual rendering pause (e.g. a user clicking a pause button followed by a play button).
//
// This also defines the min avgFps that will be reported – if we see avgFps at `1 / frameDurationThresholdSec`
// consistently, it's safe to assume actual fps is probably even lower.
const frameDurationThreshold: number = 1;

// When computing the median frame processing time, in order to save space, we'll record a maximum frame processing
// time median of 200ms -- that's already unnusably slow, and we don't really care if the true median is greater than
// 200ms.
const frameProcessingTimeMedianMax = 200;

/**
 * @category Rendering
 * @category Metrics
 */
export interface ComputedFrameMetrics {
    avgFps: number;
    lensFrameProcessingTimeMsAvg: number;
    lensFrameProcessingTimeMsStd: number;
    lensFrameProcessingTimeMsMedian: number;
    lensFrameProcessingN: number;
}

/**
 * Represents an ongoing measurement of rendering metrics.
 *
 * An instance of this class is obtained by calling {@link LensPerformanceMetrics.beginMeasurement}. Then it may be
 * used to record rendering performance metrics, reset measurement, or end the measurement.
 *
 * @category Rendering
 * @category Metrics
 */
export class LensPerformanceMeasurement {
    private state: FrameMetricsState = { ...getDefaultFrameMetricsState() };
    private priorFrameCompletedTime?: number;

    constructor(private instances: Set<LensPerformanceMeasurement>) {
        this.instances.add(this);
    }

    /** @internal */
    update(processingTimeMs: number): void {
        this.computeRunningStats(processingTimeMs);
    }

    /**
     * Return a {@link ComputedFrameMetrics} object, containing lens performance metrics.
     *
     * This method may be called multiple times, each time reporting values computed since the time when this instance
     * was created.
     */
    measure(): ComputedFrameMetrics {
        // We count the number of frames in each per-millisecond bucket, stopping when we've counted half the frames --
        // that bucket contains the median.
        let median = 0;
        let count = 0;
        for (; median < this.state.processingTimeBuckets.length; median++) {
            count += this.state.processingTimeBuckets[median];
            if (count >= (this.state.n + 1) / 2) break;
        }
        return {
            avgFps: this.state.avgFps,
            lensFrameProcessingTimeMsAvg: this.state.procFrameMean,
            lensFrameProcessingTimeMsStd: Math.sqrt(this.state.procFrameD2 / this.state.procFrameCount),
            lensFrameProcessingTimeMsMedian: this.state.n > 0 ? median : 0,
            lensFrameProcessingN: this.state.n,
        };
    }

    /**
     * Reset the measured perforamance statistics (averages, std deviations). This is equivalent to using
     * {@link LensPerformanceMetrics.beginMeasurement} to create a new LensPerformanceMeasurement instance, but may be
     * more convenient.
     */
    reset(): void {
        this.state = { ...getDefaultFrameMetricsState() };
    }

    /**
     * Stop measuring performance statistics.
     *
     * This instance will not be garbage collected until this method is called. Therefore it is important to call this
     * method at the appropriate time to avoid leaking memory -- particularly if your application creates many
     * LensPerformanceMeasurement instances.
     */
    end(): void {
        this.instances.delete(this);
    }
    /**
     * In order to calculate the mean, variance, and standard deviation for the processing times
     *  we are using Welford's online algorithm.
     * https://en.wikipedia.org/wiki/Algorithms_for_calculating_variance#Welford's_online_algorithm
     *
     * @param processingTimeMs Processing time that is returned from registered callback
     */
    private computeRunningStats(processingTimeMs: number) {
        // calculate mean and delta squared for variance and standard deviation
        const delta = processingTimeMs - this.state.procFrameMean;
        this.state.procFrameCount += 1;
        this.state.procFrameMean += delta / this.state.procFrameCount;

        const delta2 = processingTimeMs - this.state.procFrameMean;
        this.state.procFrameD2 += delta * delta2;

        // Determine average fps
        if (this.priorFrameCompletedTime === undefined) {
            this.priorFrameCompletedTime = performance.now();
        } else {
            const frameDurationSec = (performance.now() - this.priorFrameCompletedTime) / 1000;
            if (frameDurationSec < frameDurationThreshold) {
                this.state.avgFps = (this.state.avgFps + 1 / frameDurationSec) / 2;
            }
            this.priorFrameCompletedTime = performance.now();
        }

        // To approximate the median, we put each processing time into a per-millisecond bucket, and then when we
        // compute a measurement, we can count how many frames fell into each bucket. We don't care about latencies
        // above 200ms, since that's already unusably slow (if we regress from 280ms to 320ms, we don't really care
        // since both are unnusable).
        this.state.n++;
        this.state.processingTimeBuckets[Math.min(Math.round(processingTimeMs), frameProcessingTimeMedianMax)]++;
    }
}
