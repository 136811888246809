import { getLogger } from "../logger/logger";
import { ChainableHandler } from "./HandlerChainBuilder";
import { ensureClonedRequest } from "./retryingHandler";

const logger = getLogger("noCorsRetryingFetchHandler");

const logRetry = (error: any) => {
    logger.warn(`NoCorsRetrying handler got failed response:`, error, `Retrying request with {mode: "no-cors"}.`);
};

/**
 * Some requests may require a no-cors pre-flight (which are allowed to follow redirects) before they can be
 * successful.
 *
 * An example is a federated identity authentication flow, like OpenID Connect or OAuth. In many such schemes,
 * an unauthenticated request will not include CORs headers. Instead, a redirect to an Identity Provider will be
 * returned. In order follow this redirect, the Fetch request must be made with "no-cors" mode.
 *
 * Once the "no-cors" request is made, redirects are followed resulting in authentication cookies being set on the
 * original domain. Then, the original request can be retried and the server will authenticate the request and set
 * proper CORs headers on the response.
 *
 * Here's an example request flow, in which the page already has cookies for IdentityProvider.com (if that wasn't
 * the case, IdentityProvider.com would prompt the user for credentials and the rest of the flow would be the same):
 *
 * ```
 * WebPage a.com        MyServer b.com    IdentityProvider c.com
 *    |                         |                 |
 * Original request,            |                 |
 * unauthenticated:             |                 |
 *    |------------------------>|                 |
 *    |<--302: c.com, no CORs---|                 |
 *    |                         |                 |
 *    |                         |                 |
 * No CORs headers in           |                 |
 * response. Retry in           |                 |
 * "no-cors" mode:              |                 |
 *    |-----"no-cors" mode----->|                 |
 *    |<--302: c.com, no CORs---|                 |
 *    |                         |                 |
 *    |                         |                 |
 *    |------------------IdP cookies------------->|
 *    |<----------302: b.com?token=foo------------|
 *                              |                 |
 *    |                         |                 |
 *    |----b.com?token=foo----->|                 |
 *    |<-302: b.com, set cookie-|                 |
 *    |                         |                 |
 *    |                         |                 |
 * Retry original request,      |                 |
 * now authenticated:           |                 |
 *    |-----------cookie------->|                 |
 *    |<-----------200----------|                 |
 *                              |                 |
 * ```
 */
export const createNoCorsRetryingFetchHandler = <Res>(): ChainableHandler<
    RequestInfo,
    Res,
    RequestInfo,
    Res,
    RequestInit | undefined
> => {
    // If concurrent requests are made to the same domain, we only want to perform one "no-cors" request. We assume
    // requests to the same domain will set the same authentication cookies. To support this, we'll store any
    // in-flight "no-cors" retries and re-use them for concurrent requests.
    const noCorsRequests = new Map<string, Promise<Res>>();

    return (next) =>
        async (input, init = {}) => {
            // `host` includes domain:port, so works for local development. If the input is a relative path, we'll
            // use `location.origin` to resolve into a fully qualified URL (although of course we don't actually
            // anticipate any CORs issues in that case -- but this is cleaner than special-casing).
            let requestKey = typeof input === "string" ? input : input.url;
            try {
                requestKey = new URL(requestKey, location.origin).host;
            } catch (_) {
                /* no-op, use the full input URL as the requestKey */
            }

            try {
                // By always attempting the request first, we avoid needing to maintain any state about the validity
                // of the request (e.g. the expiration time for a credential). We just make the request, and if it
                // fails, this tells us we've made an invalid request. This does result in one additional request, but
                // it makes this much more flexible and avoids having to maintain state (which can be a source of bugs).
                return await next(ensureClonedRequest(input), init);
            } catch (error) {
                // If the request fails because it was aborted, we assume this was done intentionally and we can stop.
                if (error instanceof Error && error.name === "AbortError") throw error;

                // Otherwise we don't actually care what error occurred – we know this will be an error thrown by
                // `fetch` itself (rather than some error encountered on the server, which wouldn't cause `next` to
                // throw), and we'll just assume it's a CORs error. If it's not, we'll perform a "no-cors" retry anyway,
                // which will presumably also fail, and that failure will be returned to the caller.
                logRetry(error);
                const noCorsRequest =
                    noCorsRequests.get(requestKey) ?? next(ensureClonedRequest(input), { ...init, mode: "no-cors" });
                noCorsRequests.set(requestKey, noCorsRequest);
                await noCorsRequest;
                noCorsRequests.delete(requestKey);
                return next(ensureClonedRequest(input), init);
            }
        };
};
