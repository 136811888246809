import lensCoreWasm from "../../lensCoreWasmVersions.json";
import { loadScript } from "../../common";
import { CameraKitConfiguration, configurationToken } from "../../configuration";
import { Injectable } from "../../dependency-injection/Injectable";
import { defaultFetchHandlerFactory, FetchHandler } from "../../handlers/defaultFetchHandler";
import { InitialEmscriptenModule, LensCoreModule } from "../generated-types";
import { getLogger } from "../../logger/logger";
import { WasmFeatures, getPlatformCapabilities } from "../../platform/platformCapabilities";
import { getPlatformInfo } from "../../platform/platformInfo";
import { createLensCore } from "../lensCore";

const logger = getLogger("lensCoreFactory");

const wasmAssets = ["LensCoreWebAssembly.js", "LensCoreWebAssembly.wasm"];

const findMatch = (regex: RegExp, strings: string[]) => strings.find((s) => regex.test(s));

/**
 * Map various combinations of WebAssembly capabilities to the corresponding LensCore build flavours which make use
 * of them.
 */
const wasmCapabilitiesToLensCoreBuildFlavor = {
    [WasmFeatures.Default]: "release",
    [WasmFeatures.ExceptionHandling]: "rel-neh",
    [WasmFeatures.SIMD]: "release-simd",
    [WasmFeatures.ExceptionHandling | WasmFeatures.SIMD]: "rel-simd-neh",
};

/**
 * Returns a list of URLs for resources which will be fetched during {@link bootstrapCameraKit}.
 *
 * When CameraKit is used on a website, these URLs much be reachable in order for CameraKit to be successfully
 * bootstrapped.
 *
 * @param endpointOverride Optional endpoint override to load the assets from.
 * @returns An array of asset URLs.
 *
 * @category Bootstrapping and Configuration
 */
export async function getRequiredBootstrapURLs(endpointOverride?: string): Promise<string[]> {
    // If we have an endpoint override, remove trailing `/` so we can construct a valid URL.
    const endpoint = endpointOverride?.replace(/[\/]+$/, "");

    const { wasm } = await getPlatformCapabilities();
    if (!wasm.supported) throw wasm.error;

    const { lensCore } = getPlatformInfo();
    const flavor = wasmCapabilitiesToLensCoreBuildFlavor[wasm.wasmFeatures];
    if (!flavor)
        throw new Error(
            `Could not determine a LensCore build flavor corresponding to the bitstring ` +
                `${wasm.wasmFeatures.toString(2)}. CameraKit cannot be bootstrapped.`
        );
    const version = lensCore.version;
    const buildNumber = lensCore.buildNumber;
    return wasmAssets.map((asset) => {
        if (endpoint) return `${endpoint}/${asset}`;
        const { origin, pathname, search } = new URL(lensCore.baseUrl);
        return `${origin}${pathname}/${version}/${buildNumber}/${flavor}/${asset}${search}`;
    });
}

/**
 * This component is responsible for:
 *   1) Loading LensCore WebAssembly (WASM) assets
 *   2) Using the WASM assets to initialize the LensCore WASM module
 *
 * By default, WASM assets will be loaded from the Bolt CDN – but if `endpoint` is provided, assets will be loaded
 * using it as a base URL.
 *
 * @internal
 */
export const lensCoreFactory = Injectable(
    "lensCore",
    [defaultFetchHandlerFactory.token, configurationToken] as const,
    async (handler: FetchHandler, { lensCoreOverrideUrls, wasmEndpointOverride }: CameraKitConfiguration) => {
        let lensCoreJS: string;
        let lensCoreWASM: string;

        if (lensCoreOverrideUrls) {
            lensCoreJS = lensCoreOverrideUrls.js;
            lensCoreWASM = lensCoreOverrideUrls.wasm;
        } else {
            const endpointOverride = wasmEndpointOverride ?? undefined;
            const assetURLs = await getRequiredBootstrapURLs(endpointOverride);

            lensCoreJS = findMatch(/\.js/, assetURLs) ?? "";
            lensCoreWASM = findMatch(/\.wasm/, assetURLs) ?? "";

            if (!lensCoreJS || !lensCoreWASM) {
                throw new Error(
                    `Cannot fetch required LensCore assets. Either the JS or WASM filename is missing from ` +
                        `this list: ${assetURLs}.`
                );
            }

            // Fetching here and creating an Object URL lets LensCore optimized loading itself in a WebWorker,
            // otherwise the glue script would need to be downloaded again.
            const glueScript = await handler(lensCoreJS).then((r) => r.blob());
            lensCoreJS = URL.createObjectURL(glueScript);
        }

        const scriptElement = await loadScript(lensCoreJS);

        const lensCore = await new Promise<InitialEmscriptenModule & LensCoreModule>((resolve, reject) => {
            let initialModule: Partial<InitialEmscriptenModule>;
            // will trigger WASM initialization and data loading,
            // after completion it will be safe to call imported WASM functions
            // More about emscripten initialization:
            // eslint-disable-next-line max-len
            // https://emscripten.org/docs/getting_started/FAQ.html?highlight=modularize#how-can-i-tell-when-the-page-is-fully-loaded-and-it-is-safe-to-call-compiled-functions
            const moduleInit = globalThis.createLensesModule(
                (initialModule = {
                    // url will be used for loading glue JS during Worker inialization
                    mainScriptUrlOrBlob: lensCoreJS,
                    // will be triggered by Emscripten during the initialization
                    instantiateWasm: (importObject, receiveInstance) => {
                        WebAssembly.instantiateStreaming(handler(lensCoreWASM), importObject)
                            .then(function ({ instance, module }) {
                                receiveInstance(instance, module);
                                // compiled module will be reused in Worker
                                initialModule.compiledModule = module;
                                resolve(moduleInit);
                            })
                            .catch(reject);
                    },
                })
            );
        });

        // now when we have LensCore WASM in memory we can release the script element
        scriptElement.remove();

        // print warning if loaded version differs from hardcoded one
        if (lensCoreWasm.version != `${lensCore.getCoreVersion()}`) {
            logger.warn(
                `Loaded LensCore version (${lensCore.getCoreVersion()}) differs from expected one (${
                    lensCoreWasm.version
                })`
            );
        }

        return createLensCore(lensCore);
    }
);
