import { grpc } from "@improbable-eng/grpc-web";
import { Handler, RequestMetadata } from "../handlers/HandlerChainBuilder";
import { Injectable } from "../dependency-injection/Injectable";
import { CameraKitConfiguration, configurationToken } from "../configuration";
import { Err, Ok, Result } from "../common/result";
import { FetchHandler, cameraKitServiceFetchHandlerFactory } from "../handlers";

export interface GrpcRequest {
    serviceName: string;
    methodName: string;
    requestType: grpc.ProtobufMessageClass<grpc.ProtobufMessage>;
    responseType: grpc.ProtobufMessageClass<grpc.ProtobufMessage>;
}

export type GrpcHandler = Handler<
    GrpcRequest,
    Result<grpc.UnaryOutput<grpc.ProtobufMessage>, grpc.UnaryOutput<grpc.ProtobufMessage>>,
    RequestMetadata
>;

/**
 * An Injectable handler that can make requests to the CameraKit backend service via grpc-web. This handler can be
 * passed to {@link createTsProtoClient} to produce a well-typed service client.
 *
 * @internal
 */
export const gprcHandlerFactory = Injectable(
    "grpcHandler",
    [configurationToken, cameraKitServiceFetchHandlerFactory.token],
    (configuration: CameraKitConfiguration, fetchHandler: FetchHandler): GrpcHandler => {
        const host = `https://${configuration.apiHostname}`;

        // We define our own Transport so that we can use our custom `fetch` implementation. This is important for two
        // reasons:
        //   1. Our custom fetch includes features like retries that we want to use for these requests.
        //   2. Applications may override this fetch implementation (via our DI system) to support more advanced
        //      use-cases.
        const transport: grpc.TransportFactory = (options) => {
            let metadata: grpc.Metadata | undefined = undefined;
            const controller = AbortController ? new AbortController() : undefined;
            let cancelled = false;
            return {
                sendMessage(msgBytes) {
                    fetchHandler(options.url, {
                        headers: metadata?.toHeaders() ?? {},
                        method: "POST",
                        body: msgBytes,
                        signal: controller?.signal,
                    })
                        .then((response) => {
                            options.onHeaders(new grpc.Metadata(response.headers), response.status);
                            return response.arrayBuffer();
                        })
                        .then((body) => {
                            if (cancelled) return;
                            options.onChunk(new Uint8Array(body));
                            options.onEnd();
                        })
                        .catch((error) => {
                            if (cancelled) return;
                            cancelled = true;
                            options.onEnd(error);
                        });
                },

                start(m) {
                    metadata = m;
                },

                finishSend() {},

                cancel() {
                    if (cancelled) return;
                    cancelled = true;
                    controller?.abort();
                },
            };
        };

        return async (request) =>
            new Promise((resolve) => {
                grpc.unary(
                    {
                        methodName: request.methodName,
                        service: { serviceName: request.serviceName },
                        requestStream: false,
                        responseStream: false,
                        requestType: request.requestType,
                        responseType: request.responseType,
                    },
                    {
                        request: new request.requestType(),
                        host,
                        onEnd: (response) => {
                            if (response.status === grpc.Code.OK) {
                                resolve(Ok(response));
                            } else {
                                resolve(Err(response));
                            }
                        },
                        transport,
                    }
                );
            });
    }
);
