import { DefaultErrorHandler, LensCoreModule } from "./generated-types";

type ErrorName = `${string}Error`;
type NamedError<Name extends ErrorName> = Error & { name: Name };

export type LensCoreLensDeserializationError = NamedError<"LensCoreLensDeserializationError">;
export type LensCoreValidationError = NamedError<"LensCoreValidationError">;
export type LensCoreUncategorizedError = NamedError<"LensCoreUncategorizedError">;
export type LensCoreLensExecutionError = NamedError<"LensCoreLensExecutionError">;
export type LensCoreAbortError = NamedError<"LensCoreAbortError">;
export type LensCoreUninitializedError = NamedError<"LensCoreUninitializedError">;
export type LensCoreUnknownError = NamedError<"LensCoreUnknownError">;

export type LensCoreError =
    | LensCoreLensDeserializationError
    | LensCoreValidationError
    | LensCoreUncategorizedError
    | LensCoreLensExecutionError
    | LensCoreAbortError
    | LensCoreUninitializedError;

type NativeLensCoreError = keyof LensCoreModule["ErrorType"];

// Construct a map linking each LensCore error name to its value,
// designed to trigger a compile-time error if an error is added or removed in LensCore.
// eslint-disable-next-line max-len
// https://github.sc-corp.net/Snapchat/LensCore/blob/285ac47cad7fe5268f38d1bab82d51b7b19d6b48/Src/PlatformSpecific/WebAssembly/ErrorType.hpp#L4
const lensCoreErrorValue: Record<NativeLensCoreError, number> = {
    LensDeserialization: 0,
    Validation: 1,
    Uncategorized: 2,
    LensExecution: 3,
    Abort: 4,
    Uninitialized: 5,
};

// The purpose of lensCoreErrorValue above is to safeguard integrity.
// To achieve constant lookup times, we must swap the keys with their corresponding values.
const lensCoreErrorName = Object.fromEntries(
    Object.entries(lensCoreErrorValue).map((entry) => [entry[1], entry[0] as NativeLensCoreError] as const)
);

export function wrapLensCoreError(fn: (e: LensCoreError) => void) {
    const handler: DefaultErrorHandler = (cause) => {
        const error = new Error(cause.message.split("\n")[0], {
            cause:
                cause.otherExceptions || cause.cause?.metadata
                    ? {
                          otherExceptions: cause.otherExceptions,
                          metadata: cause.cause.metadata,
                      }
                    : undefined,
        });
        const name: (LensCoreError | LensCoreUnknownError)["name"] = `LensCore${
            cause.cause ? lensCoreErrorName[cause.cause.type.value] : "Unknown"
        }Error` as const;
        error.name = name;
        if (cause.stack) {
            // if cause has a stack, then we just replace the first line of it
            // which is actually a error message with our new one, which also contains new error name
            const [_, ...stackLines] = cause.stack.split("\n");
            if (error.stack) {
                stackLines.unshift(error.stack.split("\n")[0]);
            }
            error.stack = stackLines.join("\n");
        }
        // Safety: we set name above and therefore sure the type of error is correct
        fn(error as LensCoreError);
    };
    return handler;
}
