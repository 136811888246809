import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
export const protobufPackage = "com.snap.camerakit.v3";
export interface OperationalMetric {
    name: string;
    timestamp: Date | undefined;
    metric?: {
        $case: "count";
        count: number;
    } | {
        $case: "latencyMillis";
        latencyMillis: number;
    } | {
        $case: "histogram";
        histogram: number;
    };
}
export interface OperationalMetricsBundle {
    metrics: OperationalMetric[];
}
function createBaseOperationalMetric(): OperationalMetric {
    return { name: "", timestamp: undefined, metric: undefined };
}
export const OperationalMetric = {
    encode(message: OperationalMetric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(18).fork()).ldelim();
        }
        if (message.metric?.$case === "count") {
            writer.uint32(24).uint64(message.metric.count);
        }
        if (message.metric?.$case === "latencyMillis") {
            writer.uint32(32).uint64(message.metric.latencyMillis);
        }
        if (message.metric?.$case === "histogram") {
            writer.uint32(40).int64(message.metric.histogram);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): OperationalMetric {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOperationalMetric();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.metric = { $case: "count", count: longToNumber(reader.uint64() as Long) };
                    break;
                case 4:
                    message.metric = { $case: "latencyMillis", latencyMillis: longToNumber(reader.uint64() as Long) };
                    break;
                case 5:
                    message.metric = { $case: "histogram", histogram: longToNumber(reader.int64() as Long) };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): OperationalMetric {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
            metric: isSet(object.count)
                ? { $case: "count", count: Number(object.count) }
                : isSet(object.latencyMillis)
                    ? { $case: "latencyMillis", latencyMillis: Number(object.latencyMillis) }
                    : isSet(object.histogram)
                        ? { $case: "histogram", histogram: Number(object.histogram) }
                        : undefined,
        };
    },
    toJSON(message: OperationalMetric): unknown {
        const obj: any = {};
        message.name !== undefined && (obj.name = message.name);
        message.timestamp !== undefined && (obj.timestamp = message.timestamp.toISOString());
        message.metric?.$case === "count" && (obj.count = Math.round(message.metric?.count));
        message.metric?.$case === "latencyMillis" && (obj.latencyMillis = Math.round(message.metric?.latencyMillis));
        message.metric?.$case === "histogram" && (obj.histogram = Math.round(message.metric?.histogram));
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<OperationalMetric>, I>>(object: I): OperationalMetric {
        const message = createBaseOperationalMetric();
        message.name = object.name ?? "";
        message.timestamp = object.timestamp ?? undefined;
        if (object.metric?.$case === "count" && object.metric?.count !== undefined && object.metric?.count !== null) {
            message.metric = { $case: "count", count: object.metric.count };
        }
        if (object.metric?.$case === "latencyMillis" &&
            object.metric?.latencyMillis !== undefined &&
            object.metric?.latencyMillis !== null) {
            message.metric = { $case: "latencyMillis", latencyMillis: object.metric.latencyMillis };
        }
        if (object.metric?.$case === "histogram" &&
            object.metric?.histogram !== undefined &&
            object.metric?.histogram !== null) {
            message.metric = { $case: "histogram", histogram: object.metric.histogram };
        }
        return message;
    },
};
function createBaseOperationalMetricsBundle(): OperationalMetricsBundle {
    return { metrics: [] };
}
export const OperationalMetricsBundle = {
    encode(message: OperationalMetricsBundle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.metrics) {
            OperationalMetric.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): OperationalMetricsBundle {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOperationalMetricsBundle();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metrics.push(OperationalMetric.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): OperationalMetricsBundle {
        return {
            metrics: Array.isArray(object?.metrics) ? object.metrics.map((e: any) => OperationalMetric.fromJSON(e)) : [],
        };
    },
    toJSON(message: OperationalMetricsBundle): unknown {
        const obj: any = {};
        if (message.metrics) {
            obj.metrics = message.metrics.map((e) => (e ? OperationalMetric.toJSON(e) : undefined));
        }
        else {
            obj.metrics = [];
        }
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<OperationalMetricsBundle>, I>>(object: I): OperationalMetricsBundle {
        const message = createBaseOperationalMetricsBundle();
        message.metrics = object.metrics?.map((e) => OperationalMetric.fromPartial(e)) || [];
        return message;
    },
};
declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function toTimestamp(date: Date): Timestamp {
    const seconds = date.getTime() / 1000;
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t: Timestamp): Date {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
function fromJsonTimestamp(o: any): Date {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
